import { FormFieldProps } from './ContactUs.types';

export const formInitialValues = {
  name: '',
  email: '',
  subject: '',
  message: '',
};

export const formFields: Array<FormFieldProps> = [
  {
    name: 'name',
    type: 'text',
    label: 'pages.contactUs.fields.name',
    isRequired: true,
    placeholder: '',
  },
  {
    name: 'email',
    type: 'text',
    label: 'pages.contactUs.fields.email',
    isRequired: true,
    placeholder: '',
  },
  {
    name: 'subject',
    type: 'text',
    label: 'pages.contactUs.fields.subject',
    isRequired: true,
    placeholder: '',
  },
  {
    name: 'message',
    type: 'textArea',
    label: 'pages.contactUs.fields.message',
    isRequired: true,
    placeholder: 'pages.contactUs.placeholders.message',
  },
];
