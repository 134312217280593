import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CloseIcon from '@assets/icons/close.svg';
import Modal from '@components/Modal';
import Typography from '@components/Typography';
import UserCard from '@components/UserCard';
import Tabs from '@components/Tabs';
import Icon from '@components/Icon';
import useBreakpoint from '@hooks/useBreakpoint';
import theme from '@theme';
import * as Routes from '@constants/routes';
import { generateDisplayProviderLocations, getInitials } from '@utils/user';

import { ProviderCardProfileProps } from '@services/Provider/Provider/Provider.types';
import ProviderOnboardingService from '@services/Provider/ProviderOnbarding/ProviderOnboarding.service';
import {
  EndorsementsList,
  EndorsementsListItem,
  FriendsList,
  FriendsListInvite,
  FriendsListItem,
  UserListConfig,
  UserListProps,
  UserListVariant,
} from './UserList.types';
import * as Styles from './UserList.styles';
import {
  ParentSkeletonCard,
  ProviderSkeletonCard,
  renderEndorsementCard,
  renderFriendCard,
  renderNoEndorsements,
  renderNoFriends,
} from './UserList.utils';
import InfiniteList from './InfiniteList';
import {
  DESKTOP_WRAPPER,
  MOBILE_WRAPPER,
  ENDORSEMENTS_CONTENT_WRAPPER,
  WRAPPER_TEST_ID,
  FRIENDS_CONTENT_WRAPPER,
} from './UserList.constants';

const UserList = ({
  title,
  subtitle,
  isModalOpen,
  closeHandler,
  variant,
  size,
  hasActionCard = false,
  shouldRenderListOnly = false,
  ...props
}: UserListProps): ReactElement => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoint();
  const navigate = useNavigate();

  const handleProviderNavigate = (id: string) => {
    if (id) {
      ProviderOnboardingService.getProviderLink(id)
        .then(({ data }: Record<string, any>) => {
          navigate(`${Routes.PROVIDERS}/${data || id}`);
        })
        .catch(() => navigate(`${Routes.PROVIDERS}/${id}`));
    }
  };

  const renderFriends = ({ friends, isLoadingFriends, loadFriendsCallback, hasMoreFriends }: FriendsList) => (
    <Styles.ListWrapper data-component-locator={FRIENDS_CONTENT_WRAPPER}>
      <InfiniteList<FriendsListItem>
        isLoading={isLoadingFriends}
        items={friends}
        loadMoreCallback={loadFriendsCallback}
        hasMore={hasMoreFriends}
        size={size}
        skeletonElement={<ParentSkeletonCard />}
      >
        {({
          imageUrl,
          mutualFriends,
          firstName,
          lastName,
          clickHandler = undefined,
          profileId,
          role,
          shouldHideMutualFriends = false,
        }) => (
          <>
            {role === 'ROLE_PROVIDER' ? (
              <UserCard
                isSmall
                variant="provider"
                avatar={imageUrl}
                fullName={`${firstName} ${lastName}`}
                clickHandler={() => handleProviderNavigate(profileId)}
                providerLocations={[]}
                providerTypes={[]}
                locations=""
                endorsedBy={{ friends: 3, others: 3, total: 3, me: false, profiles: [] }}
              />
            ) : (
              <UserCard
                variant="parent"
                avatar={imageUrl}
                friendsCount={mutualFriends}
                fullName={`${firstName} ${lastName}`}
                parentInitials={getInitials(firstName, lastName)}
                shouldHideMutualFriends={shouldHideMutualFriends}
                clickHandler={clickHandler}
              />
            )}
          </>
        )}
      </InfiniteList>
    </Styles.ListWrapper>
  );

  const renderFriendInvites = ({ isLoadingInvites, invites, loadInvitesCallback, hasMoreInvites }: FriendsList) => (
    <Styles.ListWrapper>
      <InfiniteList<FriendsListInvite>
        isLoading={isLoadingInvites}
        items={invites}
        loadMoreCallback={loadInvitesCallback}
        hasMore={hasMoreInvites}
        size={size}
        skeletonElement={<ParentSkeletonCard />}
      >
        {({ imageUrl, mutualFriends, firstName, lastName, acceptHandler, declineHandler, visitHandler }) => (
          <>
            <UserCard
              variant="parentInvite"
              avatar={imageUrl}
              friendsCount={mutualFriends}
              fullName={`${firstName} ${lastName}`}
              parentInitials={getInitials(firstName, lastName)}
              acceptHandler={acceptHandler}
              declineHandler={declineHandler}
              visitHandler={visitHandler}
            />
          </>
        )}
      </InfiniteList>
    </Styles.ListWrapper>
  );

  const renderFriendsList = ({ friends, isLoadingFriends }: FriendsList) => {
    const hasNoFriends = !isLoadingFriends && friends.length === 0;

    return hasNoFriends ? renderNoFriends() : renderFriends(props as FriendsList);
  };

  const renderFriendContent = ({
    handleCopyLink,
    isLoadingInvites,
    invites,
    mutualFriends,
    friendsTotalCount,
    invitesTotalCount,
    hasInvitesTabOpen = false,
    hasTabs = false,
  }: FriendsList) => {
    const hasNoFriendRequests = !isLoadingInvites && invites.length === 0;
    const hasMutualFriends = typeof mutualFriends !== 'undefined';

    return (
      <>
        {hasActionCard && renderFriendCard({ handleCopyLink })}
        {hasTabs ? (
          <Styles.TabsWrapper>
            <Tabs
              activeTab={hasInvitesTabOpen ? 1 : 0}
              headers={[
                `${t('common.userList.tabs.friends')} ${friendsTotalCount}`,
                `${t('common.userList.tabs.requests')} ${invitesTotalCount}`,
              ]}
            >
              {renderFriendsList(props as FriendsList)}
              {hasNoFriendRequests ? renderNoFriends() : renderFriendInvites(props as FriendsList)}
            </Tabs>
          </Styles.TabsWrapper>
        ) : (
          <>
            <Styles.ContentFiller />
            {hasMutualFriends && (
              <Styles.InlineTitleWrapper>
                <Typography variant="h5" weight="bold" shouldTranslate>
                  common.userList.tabs.friends
                </Typography>
                <Typography variant="body3" shouldTranslate>
                  {friendsTotalCount}
                </Typography>
                {mutualFriends > 0 && (
                  <Styles.MutualFriendsWrapper>
                    <Typography variant="body3" color={theme.colors.greyDark3} shouldTranslate>
                      {t('common.userCard.mutualCount', { count: mutualFriends })}
                    </Typography>
                  </Styles.MutualFriendsWrapper>
                )}
              </Styles.InlineTitleWrapper>
            )}
            {subtitle && (
              <Styles.InlineTitleWrapper>
                <Typography variant="h5" weight="bold" shouldTranslate>
                  {subtitle}
                </Typography>
                <Typography variant="body3" shouldTranslate>
                  {friendsTotalCount}
                </Typography>
              </Styles.InlineTitleWrapper>
            )}
            {renderFriends(props as FriendsList)}
          </>
        )}
      </>
    );
  };

  const renderEndorsements = ({ items, isLoading, hasMore, loadEndorsements }: EndorsementsList) => (
    <InfiniteList<EndorsementsListItem>
      isLoading={isLoading}
      items={items}
      loadMoreCallback={loadEndorsements}
      hasMore={hasMore}
      size={size}
      skeletonElement={<ProviderSkeletonCard />}
    >
      {({ providerId, link, imageUrl, name, providerTypes, providerLocations, service_locations, endorsedBy }) => (
        <UserCard
          variant="provider"
          avatar={imageUrl}
          fullName={name}
          locations={generateDisplayProviderLocations(service_locations || providerLocations).toString()}
          providerLocations={service_locations || providerLocations}
          providerTypes={providerTypes}
          endorsedBy={endorsedBy}
          clickHandler={() => handleProviderNavigate(providerId)}
        />
      )}
    </InfiniteList>
  );

  const renderEndorsementsList = ({ items, isLoading }: EndorsementsList) => {
    const hasNoEndorsements = !isLoading && items.length === 0;

    return hasNoEndorsements ? renderNoEndorsements() : renderEndorsements(props as EndorsementsList);
  };

  const renderEndorsementContent = ({ items, handleEndorse, totalCount, isLoading }: EndorsementsList) => {
    const hasNoEndorsements = !isLoading && items.length === 0;

    return (
      <>
        {hasActionCard ? renderEndorsementCard({ handleEndorse }) : <Styles.ContentFiller />}
        <Styles.ListWrapper data-component-locator={ENDORSEMENTS_CONTENT_WRAPPER}>
          <Styles.InlineTitleWrapper>
            <Typography variant="h5" weight="bold">
              {t('common.userCard.endorsements')}
            </Typography>
            <Typography variant="h5">{totalCount}</Typography>
          </Styles.InlineTitleWrapper>
          {hasNoEndorsements ? renderNoEndorsements() : renderEndorsements(props as EndorsementsList)}
        </Styles.ListWrapper>
      </>
    );
  };

  const contentConfig: UserListConfig = {
    friends: () => renderFriendContent(props as FriendsList),
    endorsements: () => renderEndorsementContent(props as EndorsementsList),
  };

  const listConfig: UserListConfig = {
    friends: () => renderFriendsList(props as FriendsList),
    endorsements: () => renderEndorsementsList(props as EndorsementsList),
  };

  const userListFactory = (type: UserListVariant, config: UserListConfig) => config[type]();

  if (shouldRenderListOnly) {
    return <Styles.ListOnlyContainer>{userListFactory(variant, listConfig)}</Styles.ListOnlyContainer>;
  }

  return (
    <Modal
      isOpen={isModalOpen}
      closeHandler={closeHandler}
      background="white"
      noPadding={isMobile}
      hasClose={!isMobile}
    >
      <Styles.Container data-component-locator={WRAPPER_TEST_ID}>
        {isMobile ? (
          <>
            <Styles.Header>
              {title && (
                <Typography variant="h5" weight="bold" shouldTranslate>
                  {title}
                </Typography>
              )}
              <Styles.CloseIconWrapper onClick={closeHandler}>
                <Icon icon={CloseIcon} />
              </Styles.CloseIconWrapper>
            </Styles.Header>
            <Styles.ContentWrapper data-component-locator={MOBILE_WRAPPER}>
              {userListFactory(variant, contentConfig)}
            </Styles.ContentWrapper>
          </>
        ) : (
          <Styles.ContentWrapper data-component-locator={DESKTOP_WRAPPER}>
            {title && (
              <Styles.ModalTitleWrapper>
                <Typography variant="h2" weight="bold" shouldTranslate>
                  {title}
                </Typography>
              </Styles.ModalTitleWrapper>
            )}
            {userListFactory(variant, contentConfig)}
          </Styles.ContentWrapper>
        )}
      </Styles.Container>
    </Modal>
  );
};

export default UserList;
