import { styled } from '@mui/material/styles';
import AvatarGroup from '@mui/material/AvatarGroup';
import { Avatar as MuiAvatar } from '@mui/material';

import { AVATAR_SIZE, INITIALS_SIZE } from './AvatarGroup.constants';

export const AvatarGroupWrapper = styled(AvatarGroup)`
  width: fit-content;

  .MuiAvatar-colorDefault {
    display: none;
  }

  > div:nth-child(1) {
    z-index: 2;
  }

  > div:nth-child(2) {
    z-index: 1;
  }
`;

export const Avatar = styled(MuiAvatar)`
  display: block;
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;

  &.MuiAvatar-root {
    margin-left: -${({ theme }) => theme.spacing(6)};

    :last-child {
      margin-left: 0;
    }
  }
`;

export const Initials = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${INITIALS_SIZE}px;
  height: ${INITIALS_SIZE}px;
  border: 2px solid ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.green};
  margin-left: -${({ theme }) => theme.spacing(6)};

  :last-child {
    margin-left: 0;
  }
`;
