export type TypographyVariants =
  | 'initials'
  | 'inline'
  | 'display1'
  | 'display2'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'body4'
  | 'body5';

export const typography = {
  fontFamily: 'GT-America, sans-serif',
  initials: {
    fontSize: '4.75rem',
    lineHeight: '1.3',
  },
  display1: {
    fontSize: '3.25rem',
    lineHeight: '1.3',
  },
  display2: {
    fontSize: '3rem',
    lineHeight: '1.3',
  },
  h1: {
    fontSize: '2.75rem',
    lineHeight: '1.3',
  },
  h2: {
    fontSize: '2.25rem',
    lineHeight: '1.3',
  },
  h3: {
    fontSize: '1.75rem',
    lineHeight: '1.3',
  },
  h4: {
    fontSize: '1.5rem',
    lineHeight: '1.3',
  },
  h5: {
    fontSize: '1.125rem',
    lineHeight: '1.3',
  },
  h6: {
    fontSize: '1rem',
    lineHeight: '1.3',
  },
  body1: {
    fontSize: '1.25rem',
    lineHeight: '1.5',
  },
  body2: {
    fontSize: '1.125rem',
    lineHeight: '1.5',
  },
  body3: {
    fontSize: '1rem',
    lineHeight: '1.5',
  },
  body4: {
    fontSize: '0.875rem',
    lineHeight: '1.5',
  },
  body5: {
    fontSize: '0.75rem',
    lineHeight: '1.4',
  },
};
