import { createTheme, Theme } from '@mui/material/styles';

import GTAmericaFont from '@assets/fonts/GT-America-Regular.woff';
import GTAmericaFontBold from '@assets/fonts/GT-America-Bold.woff';
import GTAmericaFontItalic from '@assets/fonts/GT-America-Bold-Italic.ttf';

import { colors } from './colors';
import { shadows } from './shadows';
import { typography } from './typography';
import { breakpoints } from './breakpoints';
import { gradients } from './gradients';
import { shapes } from './shapes';

const muiTheme: Theme = createTheme({
  typography,
  breakpoints,
  spacing: (value: number) => value * 2,
  shape: {
    borderRadius: 2,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'GT-America';
          font-style: normal;
          font-weight: normal;
          src: local('GT-America'), url(${GTAmericaFont}) format('truetype');
        }
        
        @font-face {
          font-family: 'GT-America';
          font-style: normal;
          font-weight: bold;
          src: local('GT-America'), url(${GTAmericaFontBold}) format('truetype');
        }

        @font-face {
          font-family: 'GT-America';
          font-style: italic;
          font-weight: normal;
          src: local('GT-America'), url(${GTAmericaFontItalic}) format('truetype');
        }
      `,
    },
  },
  colors,
  customShadows: shadows,
  customGradients: gradients,
  customShapes: shapes,
});

export default muiTheme;
