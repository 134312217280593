import { css, styled } from '@mui/material/styles';
import TextareaAutosize from 'react-textarea-autosize';

import { InputContainerProps, ErrorProps, AssistiveTextProps, TextAreaAutoHeightProps } from './Input.types';

export const InputContainer = styled('div')<InputContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: ${({ $height }) => $height};
`;

export const Input = styled('input')<ErrorProps>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.body3.fontSize};
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  border: ${({ theme, $hasError }) => `1px solid ${$hasError ? theme.colors.red : theme.colors.greyDark3}`};
  padding: ${({ theme }) => theme.spacing(8)};

  :focus {
    outline: 0;
    box-shadow: ${({ theme, $hasError }) => `0 0 0 ${$hasError ? '2px' : '1px'} ${theme.colors.greyDark3}`};
  }
`;

export const TextArea = styled(TextareaAutosize)<ErrorProps & TextAreaAutoHeightProps>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.body3.fontSize};
  line-height: ${({ theme }) => theme.typography.body3.lineHeight};
  padding: ${({ theme }) => theme.spacing(6)};
  resize: none;
  overflow: hidden;
  //min-height: 200px;
  border: ${({ theme, $hasError }) => `1px solid ${$hasError ? theme.colors.red : theme.colors.greyDark3}`};
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};

  ${({ $textAreaHeight }) =>
    $textAreaHeight &&
    css`
      height: ${$textAreaHeight};
    `};

  :focus {
    outline: 0;
    box-shadow: ${({ theme, $hasError }) => `0 0 0 ${$hasError ? '2px' : '1px'} ${theme.colors.greyDark3}`};
  }
`;

export const Label = styled('label')`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  font-size: ${({ theme }) => theme.typography.body3.fontSize};
  line-height: ${({ theme }) => theme.typography.body3.lineHeight};
  color: ${({ theme }) => theme.colors.black};
  min-height: 24px;
`;

export const RequireAsterix = styled('span')`
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  color: ${({ theme }) => theme.colors.blue};
  line-height: 0;
`;

export const AssistiveText = styled('span')<AssistiveTextProps>`
  position: absolute;
  top: ${({ theme, $inputType }) => ($inputType === 'textArea' ? 'calc(100% + 5px)' : theme.spacing(42))};
  left: ${({ theme, $inputType }) => ($inputType === 'textArea' ? theme.spacing(8) : theme.spacing(8))};
  font-size: ${({ theme }) => theme.typography.body5.fontSize};
  line-height: ${({ theme }) => theme.typography.body5.lineHeight};
  color: ${({ theme, $hasError }) => ($hasError ? theme.colors.red : theme.colors.greyDark3)};
`;
