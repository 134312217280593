import { ReactElement, useRef } from 'react';
import { Transition } from 'react-transition-group';
import { Link, useNavigate } from 'react-router-dom';

import ArrowLeft from '@assets/icons/left-arrow.svg';
import Logo from '@assets/images/logo.svg';
import Icon from '@components/Icon';
import Typography from '@components/Typography';
import useOnClickOutside from '@hooks/useOnClickOutside';
import useHideScroll from '@hooks/useHideScroll';
import useBreakpoint from '@hooks/useBreakpoint';

import userService from '@services/User/User.service';
import * as Routes from '@constants/routes';
import useNotification from '@hooks/useNotification';
import { useTranslation } from 'react-i18next';
import { setStorageToken } from '@utils/storage';
import { ActionType, DrawerMenuProps } from './DrawerMenu.types';
import * as Styles from './DrawerMenu.styles';
import { getCurrentYear, menuConfig, socialsConfig, transitionStyles } from './DrawerMenu.utils';
import { DRAWER_DESKTOP_TRANSITION_DELAY, LOGO_TEST_ID, CLOSE_TEST_ID, WRAPPER_TEST_ID } from './DrawerMenu.constants';

const DrawerMenu = ({
  isOpen,
  hamburgerRef,
  handleMenuClose,
  handleLogoClick,
  handleContactUsFormOpen,
}: DrawerMenuProps): ReactElement => {
  const drawerMenuRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useBreakpoint();
  const { addNotification } = useNotification();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useOnClickOutside(drawerMenuRef, handleMenuClose, hamburgerRef);
  useHideScroll(isOpen && isMobile, [isOpen, isMobile]);

  const contactUsActionHandler = () => {
    handleContactUsFormOpen();
    handleMenuClose();
  };

  const handlePassedAction = (action: ActionType) => {
    if (action === 'contactUs') {
      return contactUsActionHandler;
    }

    return undefined;
  };

  const renderLink = (name: string, url?: string, isExternal?: boolean) => {
    return isExternal ? (
      <Styles.ExternalLink onClick={handleMenuClose} href={url} target="_blank" rel="nofollow noreferrer noopener">
        <Typography variant="body2" shouldTranslate>
          {name}
        </Typography>
      </Styles.ExternalLink>
    ) : (
      <Link onClick={handleMenuClose} to={url || ''}>
        <Typography variant="body2" shouldTranslate>
          {name}
        </Typography>
      </Link>
    );
  };

  return (
    <Transition in={isOpen} timeout={DRAWER_DESKTOP_TRANSITION_DELAY}>
      {(state) => (
        <Styles.Wrapper
          data-component-locator={WRAPPER_TEST_ID}
          style={{ ...transitionStyles[state] }}
          ref={drawerMenuRef}
        >
          <Styles.Header>
            <Styles.IconWrapper data-component-locator={CLOSE_TEST_ID} onClick={handleMenuClose}>
              <Icon icon={ArrowLeft} />
            </Styles.IconWrapper>
            <Styles.LogoWrapper data-component-locator={LOGO_TEST_ID} onClick={handleLogoClick}>
              <Logo />
            </Styles.LogoWrapper>
          </Styles.Header>
          <Styles.Content>
            {menuConfig.map(({ headline, menuItems }, index) => (
              <Styles.MenuSection key={`${headline}-${index.toString()}`}>
                <Styles.HeadlineWrapper>
                  <Typography variant="body4" weight="bold" shouldTranslate>
                    {headline}
                  </Typography>
                </Styles.HeadlineWrapper>
                <Styles.Menu>
                  {menuItems.map(({ id, name, action, url, isExternal }) => (
                    <Styles.MenuItem key={id} onClick={handlePassedAction(action)}>
                      {action ? (
                        <Typography variant="body2" shouldTranslate>
                          {name}
                        </Typography>
                      ) : (
                        renderLink(name, url, isExternal)
                      )}
                    </Styles.MenuItem>
                  ))}
                </Styles.Menu>
              </Styles.MenuSection>
            ))}
          </Styles.Content>
          <Styles.Footer>
            <Styles.MenuSection>
              <Styles.HeadlineWrapper>
                <Typography variant="body4" weight="bold" shouldTranslate>
                  {socialsConfig.headline}
                </Typography>
              </Styles.HeadlineWrapper>
              <Styles.SocialsMenu>
                {socialsConfig.menuItems.map(({ id, icon, name, url }) => (
                  <Styles.SocialsMenuItem key={id}>
                    <Icon icon={icon} />
                    <Styles.ExternalLink href={url} target="_blank" rel="nofollow noreferrer noopener">
                      <Typography variant="body2" shouldTranslate>
                        {name}
                      </Typography>
                    </Styles.ExternalLink>
                  </Styles.SocialsMenuItem>
                ))}
              </Styles.SocialsMenu>
            </Styles.MenuSection>
            <Styles.Copyright>
              <Typography variant="body5" shouldTranslate>
                common.footer.copyright
              </Typography>
              <Typography variant="body5">{getCurrentYear()}</Typography>
              <Typography variant="body5" shouldTranslate>
                common.appName
              </Typography>
            </Styles.Copyright>
          </Styles.Footer>
        </Styles.Wrapper>
      )}
    </Transition>
  );
};

export default DrawerMenu;
