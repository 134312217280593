import { FC, useContext, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';

import Backdrop from '@components/Backdrop';
import BackgroundAsset from '@components/BackgroundAsset';
import Header from '@components/Headers/Header';
import Menu from '@components/Menu';
import useBreakpoint from '@hooks/useBreakpoint';
import useOnClickOutside from '@hooks/useOnClickOutside';
import { LayoutContext } from '@context/Layout/Layout.context';

import * as Styles from './Layout.styles';

const Layout: FC = ({ children }) => {
  const { isMobile } = useBreakpoint();
  const { layout } = useContext(LayoutContext);
  const { background, assets, showHeader, title, isFluid, alignCenter } = layout;
  const menuRef = useRef<HTMLDivElement | null>(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuToggle = () => setIsMenuOpen((previousValue) => !previousValue);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerMenuOpen = () => setIsDrawerOpen(true);
  const handleDrawerMenuClose = () => setIsDrawerOpen(false);

  const [isContactUsFormOpen, setIsContactUsFormOpen] = useState(false);
  const handleContactUsFormToggle = () => setIsContactUsFormOpen((previousValue) => !previousValue);
  const handleContactUsFormClose = () => setIsContactUsFormOpen(false);

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const handleSearchOpen = () => setIsSearchOpen(true);
  const handleSearchClose = () => setIsSearchOpen(false);

  const [isFriendsListOpen, setIsFriendsListOpen] = useState(false);
  const handleFriendsListToggle = () => setIsFriendsListOpen((previousValue) => !previousValue);
  const handleFriendsListClose = () => setIsFriendsListOpen(false);

  const [isEndorsementsListOpen, setIsEndorsementsListOpen] = useState(false);
  const handleEndorsementsListToggle = () => setIsEndorsementsListOpen((previousValue) => !previousValue);
  const handleEndorsementsListClose = () => setIsEndorsementsListOpen(false);

  useOnClickOutside(menuRef, handleMenuToggle);

  const renderMenu = () => {
    if (isMobile) {
      return (
        <Backdrop isOpen={isMenuOpen}>
          <Styles.MenuContainer ref={menuRef}>
            <Menu
              handleMenuClose={handleMenuToggle}
              handleOpenMyFriendsList={handleFriendsListToggle}
              handleOpenMyEndorsementsList={handleEndorsementsListToggle}
            />
          </Styles.MenuContainer>
        </Backdrop>
      );
    }

    return (
      <Styles.MenuContainer ref={menuRef}>
        <Menu
          handleMenuClose={handleMenuToggle}
          handleOpenMyFriendsList={handleFriendsListToggle}
          handleOpenMyEndorsementsList={handleEndorsementsListToggle}
        />
      </Styles.MenuContainer>
    );
  };

  return (
    <>
      <Helmet>
        <title>{title ? `${title} | ` : ''}ParentUp</title>
      </Helmet>
      <Styles.Container background={background}>
        {showHeader && (
          <Styles.HeaderContainer>
            <Header
              isMenuOpen={isMenuOpen}
              isDrawerOpen={isDrawerOpen}
              isContactUsFormOpen={isContactUsFormOpen}
              isSearchOpen={isSearchOpen}
              isFriendsListOpen={isFriendsListOpen}
              isEndorsementsListOpen={isEndorsementsListOpen}
              handleMenuOpen={handleMenuToggle}
              handleDrawerMenuOpen={handleDrawerMenuOpen}
              handleDrawerClose={handleDrawerMenuClose}
              handleContactUsFormOpen={handleContactUsFormToggle}
              handleContactUsFormClose={handleContactUsFormClose}
              handleSearchOpen={handleSearchOpen}
              handleSearchClose={handleSearchClose}
              handleFriendsListOpen={handleFriendsListToggle}
              handleFriendsListClose={handleFriendsListClose}
              handleEndorsementsListClose={handleEndorsementsListClose}
            />
            {isMenuOpen && renderMenu()}
          </Styles.HeaderContainer>
        )}
        <Styles.AssetsContainer>
          {assets.map((asset) => (
            <BackgroundAsset key={asset.id} {...asset} />
          ))}
        </Styles.AssetsContainer>
        <Styles.ContentContainer $isFluid={isFluid} $alignCenter={alignCenter}>
          {children}
        </Styles.ContentContainer>
      </Styles.Container>
    </>
  );
};

export default Layout;
