import { authConfig } from '@services/services.utils';
import axios, { axiosInstanceNew } from '@utils/api';

import {
  RequestProvidersProps,
  AxiosProvidersProps,
  AxiosProviderProps,
  RequestProviderProps,
  AxiosProviderCardsProps,
  RequestProviderMessageProps,
  AxiosProviderCommonProps,
} from './Provider.types';

class ProviderAge {
  endpoint: string;

  constructor() {
    this.endpoint = 'providers';
  }

  getProvider(providerId?: string): Promise<AxiosProviderProps> {
    return axios.get(`${this.endpoint}/no-sens-data/${providerId}`, authConfig);
  }

  getProviderLink(providerId?: string): Promise<AxiosProviderProps> {
    return axios.get(`${this.endpoint}/no-sens-data/by-link/${providerId}`, authConfig);
  }

  getUserProvider(): Promise<AxiosProviderProps> {
    return axiosInstanceNew.get(`spro/${this.endpoint}/no-sens-data`, authConfig);
  }

  getUserProviderId(id?: string | null): Promise<AxiosProviderProps> {
    return axiosInstanceNew.get(`spro/data/no-sens-data/${this.endpoint}/${id}`, authConfig);
  }

  getCommonProvider(): Promise<AxiosProviderCommonProps> {
    return axiosInstanceNew.get(`spro/${this.endpoint}/common-info`, authConfig);
  }

  updateProvider(providerId: string | null, updateData: RequestProviderProps): Promise<void> {
    return axios.put(`${this.endpoint}/${providerId}`, updateData, authConfig);
  }

  createProvider(providerData: RequestProviderProps): Promise<{ data: string }> {
    return axios.post(`${this.endpoint}`, providerData, authConfig);
  }

  removeProvider(providerId: string): Promise<void> {
    return axios.delete(`${this.endpoint}/${providerId}`, authConfig);
  }

  getProviders({ page, size, sort, status, ...rest }: RequestProvidersProps): Promise<AxiosProvidersProps> {
    return axios.get(`${this.endpoint}`, {
      params: {
        page,
        size,
        sort: sort ? `created_at,status,desc` : undefined,
        status,
        ...rest,
      },
      ...authConfig,
    });
  }

  getProviderCards(): Promise<AxiosProviderCardsProps> {
    return axios.get(`${this.endpoint}/cards`, {
      params: {
        size: 20,
        sort: 'createdAt,desc',
      },
      ...authConfig,
    });
  }

  sendProviderMessage(providerId: string, data: RequestProviderMessageProps): Promise<void> {
    return axios.post(`${this.endpoint}/${providerId}/message`, data, authConfig);
  }
}

export default new ProviderAge();
