import { RefObject } from 'react';

interface UrlParamProps {
  name: string;
  param: string | null | undefined | object;
}

const getDisplayDateElements = (date: string, separator: '/' | '-') => {
  const dateParts = date.split(separator);
  const year = separator === '/' ? dateParts[2] : dateParts[0];
  const month = separator === '/' ? dateParts[0] : dateParts[1];
  const day = separator === '/' ? dateParts[1] : dateParts[2];

  const displayMonth = month.length === 1 ? `0${month}` : month;
  const displayDay = day.length === 1 ? `0${day}` : day;

  return {
    day: displayDay,
    month: displayMonth,
    year,
  };
};

export const copyText = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const getTrimValue = (value: string) => value.trim();

export const getEmailValue = (value: string) => getTrimValue(value).toLowerCase();

export const isArrayFilled = (array: Array<any>) => array && array.length > 0;

export const getServerDate = (date: string) => {
  const { day, month, year } = getDisplayDateElements(date, '/');

  return `${year}-${month}-${day}`;
};

export const getApplicationDate = (date: string) => {
  const { day, month, year } = getDisplayDateElements(date, '-');

  return `${month}/${day}/${year}`;
};

export const convertBase64ToBlob = (base64Image: string) => {
  const parts = base64Image.split(';base64,');
  const imageType = parts[0].split(':')[1];
  const decodedData = window.atob(parts[1]);
  const uInt8Array = new Uint8Array(decodedData.length);

  Array(decodedData.length)
    .fill('')
    .forEach((_, index) => {
      uInt8Array[index] = decodedData.charCodeAt(index);
    });

  return new Blob([uInt8Array], { type: imageType });
};

export const convertImageBlobToFormElement = (element: Blob) => {
  const formElement = new FormData();
  formElement.append('image', element);

  return formElement;
};

export const isBase64Image = (image: string | null | undefined) => {
  if (image) {
    return image.includes('base64');
  }

  return false;
};

export const checkImageFileType = (fileType: string) => {
  const availableTypes = ['image/png', 'image/jpg', 'image/jpeg'];
  return availableTypes.includes(fileType);
};

export const generateUrlWithParams = (url: string, params: Array<UrlParamProps>) => {
  if (!isArrayFilled(params)) {
    return url;
  }

  return params.reduce((acc, { name, param }, index) => {
    if (!name || !param || typeof param === 'object') {
      return acc;
    }

    const startIndicator = index === 0 || acc === url ? '?' : '';
    const joinIndicator = index !== 0 && acc !== url ? '&' : '';

    return `${acc}${startIndicator}${joinIndicator}${name}=${param}`;
  }, `${url}`);
};

export const getPhoneNumberFromMask = (mask: string) => {
  return mask.replace(/\D/g, '');
};

export const scrollReset = (element: RefObject<HTMLDivElement>) => {
  if (!element.current) {
    return;
  }

  element.current?.scrollTo(0, 0);
};
