import tests from './tests';
import common from './common';
import forms from './forms';
import pages from './pages';

export default {
  ...tests,
  ...common,
  ...forms,
  ...pages,
};
