import { FC, useEffect } from 'react';

import CloseIcon from '@assets/icons/close.svg';
import BackIcon from '@assets/icons/left-arrow.svg';
import useBreakpoint from '@hooks/useBreakpoint';
import UserProfilePicture from '@components/UserProfilePicture';
import Button from '@components/Buttons/Button';
import Icon from '@components/Icon';

import { ModalProps } from './Modal.types';
import * as Styles from './Modal.styles';
import { onEnterTransitionCallback, onExitedTransitionCallback } from './Modal.utils';

const Modal: FC<ModalProps> = ({
  title,
  isOpen = false,
  hasClose = false,
  closeHandler,
  hasBack = false,
  backHandler,
  profileImage,
  initials,
  buttonText = '',
  customRef,
  cancelText = 'common.cancel',
  buttonClickHandler,
  background,
  cancelButtonClickHandler,
  children,
  noPadding = false,
  withHeader = false,
  minHeight,
  maxWidth,
}) => {
  const { isMobile, isMobileOnly } = useBreakpoint();

  useEffect(() => {
    return () => {
      onExitedTransitionCallback(isMobile);
    };
  }, [isMobile]);

  return (
    <Styles.ModalWrapper
      open={isOpen}
      scroll="body"
      onBackdropClick={closeHandler}
      $customBackground={background}
      fullWidth={isMobile}
      $maxWidth={maxWidth}
      $minHeight={minHeight}
      ref={customRef}
      $noPadding={noPadding}
      TransitionProps={{
        onEnter: () => onEnterTransitionCallback(isMobile),
        onExited: () => onExitedTransitionCallback(isMobile),
      }}
      $withHeader={withHeader && isMobileOnly}
    >
      {hasClose && (
        <Styles.ModalClose onClick={closeHandler}>
          <Icon icon={CloseIcon} />
        </Styles.ModalClose>
      )}
      {hasBack && (
        <Styles.ModalBack onClick={backHandler}>
          <Icon icon={BackIcon} />
        </Styles.ModalBack>
      )}
      {(profileImage || initials) && (
        <Styles.ProfileImageWrapper>
          <UserProfilePicture profileImage={profileImage} initials={initials} hasEditMode={false} />
        </Styles.ProfileImageWrapper>
      )}
      {title && (
        <Styles.ModalTitle $hasProfileImage={Boolean(profileImage) || Boolean(initials)}>{title}</Styles.ModalTitle>
      )}
      {children && <Styles.ModalContent>{children}</Styles.ModalContent>}
      {buttonClickHandler && buttonText && (
        <Styles.ModalActions>
          <Button variant="filled" clickHandler={buttonClickHandler} size="big" shouldTranslate>
            {buttonText}
          </Button>
          <Button variant="textBox" clickHandler={cancelButtonClickHandler || closeHandler} size="big" shouldTranslate>
            {cancelText}
          </Button>
        </Styles.ModalActions>
      )}
    </Styles.ModalWrapper>
  );
};

export default Modal;
