export default {
  pages: {
    successMessages: {
      profileStatus: 'Thank you for submitting your profile',
      profileStatusText: ' It will be reviewed by our team and once approved - published on the platform',
      profileStatusButton: 'Go to profile',
    },
    home: {
      title: 'Save time navigating parenthood',
      loggedTitle: 'Welcome to ParentUp',
      description: 'Find trusted providers endorsed by your friends',
      loggedDescription:
        'You are now a part of the invite only ParentUp community. In order to benefit the most from the community we recommend you set up your profile.',
      buttons: {
        signUp: 'Sign Up',
        login: 'Log In',
        setUpProfile: 'Let’s set up your profile',
        dashboard: 'Go to dashboard',
      },
    },
    roleSwitcher: {
      title: 'What type of account would you like to setup?',
      description:
        'Please select the an option from below. If you want to add both accounts, you can do that from the menu once you sign up.',
      descriptionLinks: 'Whether you want to be',
      buttons: {
        parent: 'parent',
        provider: 'provider',
        cancel: 'Next',
      },
      switcher: {
        parent: 'Parent/Expecting',
        parentLast: 'Parent/Caretaker',
        provider: 'Provider',
        user: 'I am a ',
      },
    },
    signUp: {
      title: 'Create your account',
      description: 'Already a member?',
      buttons: {
        signUp: 'Sign Up',
        login: 'Log In',
        cancel: 'Cancel',
      },
      form: {
        fields: {
          firstName: 'First Name',
          lastName: 'Last Name',
          email: 'Email',
          password: 'Password',
          confirmPassword: 'Re-type Password',
          phoneNumber: 'Phone Number',
        },
        policyText: 'By signing up, I agree to the ParentUp',
        privacyPolicy: 'Privacy Policy',
        termsAndConditions: 'Terms & Conditions',
      },
      responses: {
        invalid: {
          register: 'Could not register account',
        },
      },
    },
    logIn: {
      title: 'Log In',
      description: 'Not a member?',
      signUpLink: 'Sign Up',
      buttons: {
        login: 'Log In',
        cancel: 'Cancel',
      },
      form: {
        fields: {
          email: 'Email',
          password: 'Password',
        },
      },
      responses: {
        invalid: {
          login: 'Could not login',
        },
      },
      forgotPasswordLink: 'Forgot your password?',
    },
    confirmAccount: {
      title: 'Enter the code we emailed to you',
      description: 'We emailed {{email}}. Please check spam folder. Once you have it please paste your 5 digit code.',
      emailReceive: 'Didn’t get an email?',
      emailSent: 'Email was sent to {{email}}',
      emailNotProvided: 'Email is not provided',
      buttons: {
        resendCode: 'Resend code',
      },
      responses: {
        invalid: {
          code: 'The code you entered is not correct',
          emailSent: 'Could not send email',
        },
      },
    },
    forgotPassword: {
      title: 'Forgot Password',
      description: 'You will receive an email on how to reset your password.',
      form: {
        buttons: {
          cancel: 'Cancel',
          send: 'Send',
        },
        fields: {
          email: 'Email',
        },
      },
      responses: {
        invalid: {
          sendEmail: 'Could not send reset password email',
        },
        valid: {
          sendEmail: 'Email was sent',
        },
      },
    },
    resetPassword: {
      title: 'Reset Password',
      successTitle: 'Password has been successfully reset!',
      description: 'Reset your password by entering a new password below.',
      successDescription: 'Return to the login page to sign in.',
      form: {
        buttons: {
          cancel: 'Cancel',
          submit: 'Submit',
          login: 'Log In',
        },
        fields: {
          password: 'Password',
          confirmPassword: 'Re-type Password',
        },
      },
      responses: {
        invalid: {
          resetPassword: 'Could not reset password',
        },
      },
    },
    addProvider: {
      header: {
        title: 'Provider Set Up',
        titleEdit: 'Edit provider profile',
        back: 'Back',
        skip: 'Skip',
      },
      responses: {
        invalid: {
          image: 'Could not upload profile image',
          profile: 'Could not update profile',
          becomeProvider: 'Could not become Provider ',
        },
      },
      steps: {
        step: 'Step',
        step1: {
          title: 'Basic info',
          description: 'Fill in the basic information to continue the registration',
          description1: 'Your email and phone number will not be visible on your profile',
          form: {
            fields: {
              name: 'Display name',
              minAge: 'Minimum age',
              maxAge: 'Maximum age',
              additionalServices: 'Specialities and Additional Services',
              providerType: 'Provider type (max 5)',
            },
          },
          responses: {
            invalid: {
              zipCode: 'Could not get neighborhoods',
            },
          },
        },
        step2: {
          title: 'Contact information',
          description: 'Fill in the contact information to continue the registration',
          form: {
            fields: {
              email: 'Email',
              country: 'Select country',
              phone: 'Phone Number',
              websiteAddress: 'Website',
            },
          },
          responses: {
            invalid: {
              search: 'Could not search provided value',
            },
          },
        },
        step3: {
          title: 'Location details',
          description: 'Fill in the contact information to continue the registration',
          form: {
            fields: {
              checkBoxes: 'Locations',
              address: 'Enter Address',
              country: 'Country',
              state: 'State',
              city: 'City',
              locality: 'Country/Postcode/Place/Neighborhood/Address',
              neighborhoods: 'Neighbourhood',
              search: 'Search location',
              locationType: 'Location type',
            },
            buttons: {
              add: 'Add Location',
              create: 'Create Location',
              remove: 'Remove Location',
            },
          },
          responses: {
            invalid: {
              search: 'Could not search provided value',
            },
          },
        },
        step4: {
          title: 'Other details',
          description: 'Fill in the contact information to continue the registration',
          form: {
            fields: {
              about: 'About ',
              education: 'Education',
              certifications: 'Certifications',
              year: 'Year started working',
              languages: 'Select Languages',
              affiliations: 'Affiliations',
            },
          },
          responses: {
            invalid: {
              search: 'Could not search provided value',
            },
          },
        },
        step5: {
          title: 'Add services',
          description: 'Fill in the contact information to continue the registration',
          form: {
            fields: {
              category: 'Category',
              location: 'Location',
              name: 'Service Name',
              minAge: 'Min Age',
              maxAge: 'Max Age',
              service: 'Enter service',
              description: 'Description',
              price: 'Price From ($)',
              priceDescription: 'Set the price for this service',
            },
            buttons: {
              add: 'Add service',
              remove: 'Remove Service',
            },
          },
          responses: {
            invalid: {
              search: 'Could not search provided value',
            },
          },
        },
        buttons: {
          next: 'Next',
          setupLater: 'I’ll setup profile later',
          editLater: 'I’ll edit profile later',
          setupBack: 'Back',
          done: 'Done',
        },
      },
    },
    addProfile: {
      header: {
        title: 'Profile Set Up',
        back: 'Back',
        skip: 'Skip',
      },
      responses: {
        invalid: {
          image: 'Could not upload profile image',
          profile: 'Could not update profile',
        },
      },
      steps: {
        step: 'Step',
        step1: {
          title: 'Find your neighborhoods',
          description:
            'Add your zipcode and we will help you find your neighborhoods. This will help you find Providers and Friends near you.',
          form: {
            fields: {
              zipCode: 'What’s your zipcode?',
            },
          },
          responses: {
            invalid: {
              zipCode: 'Could not get neighborhoods',
            },
          },
        },
        step2: {
          default: {
            title: 'Confirm your neighborhoods',
            description: 'My Neighborhoods (select up to {{max}})',
          },
          responses: {
            invalid: {
              search: 'Could not search provided value',
            },
          },
        },
        step3: {
          title: 'Add some detail',
          profile: {
            title: 'Profile Picture',
          },
          bio: {
            charactersRemaining: '{{characters}} characters remaining',
          },
          children: {
            title: 'Children',
            description: 'Only friends see your children’s information',
            hideChildren: 'Hide children’s info from friends',
          },
          form: {
            fields: {
              name: '{{child}} First Name',
              age: 'Birth Date / Due Date',
            },
            buttons: {
              add: 'Add another child',
              done: 'Done',
              remove: 'Remove Child',
            },
          },
        },
        buttons: {
          next: 'Next',
          setupLater: 'I’ll setup profile later',
        },
      },
    },
    profile: {
      kids: 'Kid(s)',
      noKids: 'Update your kid’s info',
      friends: 'Friends',
      endorsedProviders: 'Endorsed Providers',
      seeAll: {
        friends: 'See all Friends',
        endorsements: 'See all Endorsements',
      },
      myProfile: 'Parent Profile',
      myProfileProvider: 'Provider Profile',
      editProfile: 'Edit my profile',
      editProvider: 'Edit my profile',
      copyLink: 'Copy friend link',
      copyLinkProvider: 'Share profile link',
      responses: {
        invalid: {
          profile: 'Could not get profile',
        },
      },
      save: 'Save',
      cancel: 'Cancel',
      editYourProfile: 'Edit your profile',
      profile: 'Profile',
    },
    parent: {
      friendRequest: 'Send Friend Request',
      friendRequestPending: 'Friend Request pending',
      friendRespond: 'Respond to Request',
      friends: 'Friends',
      friendRemove: 'Do you want to remove {{name}} as a friend?',
      friendAdd: 'Do you want to add {{name}} as a friend?',
      requestInfo: 'Once {{name}} accepts your request you will be able to view their profile',
      requestPending: 'Your friend request is pending',
      parent: 'Parent',
      mutualFriends: '{{length}} mutual Friends',
      responses: {
        valid: {
          friendRequestSent: 'Friend Request Sent!',
          removeFriend: 'You are no longer friends',
          addFriend: 'You and {{name}} are friends now',
          declineFriend: 'You declined {{name}} as a friend',
        },
        invalid: {
          profile: 'Parent profile not found',
          getFriend: 'Could not get friends',
          getEndorsements: 'Could not get endorsements',
          getFriendEndorsements: 'Could not get friend endorsements',
          getMutualFriend: 'Could not get mutual friends',
          getOtherEndorsements: 'Could not get other endorsements',
          friendRequestSent: 'Could not send friend request',
          step1Sent: 'Could not send basic info request',
          step2Sent: 'Could not send contact info request',
          step3Sent: 'Could not send other details request',
          step4Sent: 'Could not send services request',
          removeFriend: 'Could not remove friend',
          addFriend: 'Could not add a friend',
          declineFriend: 'Could not decline a friend',
        },
      },
      buttons: {
        removeFriend: 'Yes, remove friend',
        cancel: 'Cancel',
        accept: 'Accept',
        decline: 'Decline',
      },
    },
    provider: {
      provider: 'Provider',
      endorse: 'Endorse Provider',
      loginToEndorse: 'Login to Endorse',
      overview: 'Overview',
      about: 'About',
      neighborhoodsCovered: 'Neighborhood(s) covered',
      languages: 'Languages',
      experience: 'Experience',
      certifications: 'Certifications',
      education: 'Education',
      categories: 'Categories',
      website: 'Website',
      email: 'Your email',
      phone: 'Phone Number',
      officeLocations: 'Office Locations',
      offerings: 'Offerings',
      details: 'Details',
      additionalServices: 'Additional Services',
      specialties: 'Specialties',
      affiliations: 'Affiliations',
      services: 'Services',
      service: 'Service',
      sendMessageMobile: 'Send a message',
      sendMessageDesktop: 'Send Provider a message',
      endorsedByYou: 'Endorsed by you',
      saved: 'Saved!',
      responses: {
        valid: {
          endorsed: 'Endorsed Provider',
        },
        invalid: {
          endorsed: 'Could not endorse Provider',
          undoEndorsed: 'Could not undo Provider endorsement',
        },
      },
      contact: {
        title: 'Send Message',
        description: 'Send an email directly to <strong>{{providerName}}</strong> from here.',
        placeholder:
          'Let the Provider know why you are reaching out, the age of your kid(s)/due date. If you have friends that endorsed them on ParentUp let them know that.',
        successMessage: 'Your message to {{providerName}} was sent!',
        invalidMessage: 'Could not send a message to {{providerName}}',
        closeButton: 'Close',
      },
    },
    dashboard: {
      endorsedByParents: 'Endorsed by Parents in your area',
      newProviders: 'Recently added Providers',
      newParents: 'New members',
      popularCategories: 'Popular Categories',
      friendRequests: 'Friend requests {{length}}',
      seeAll: 'See all',
      actionCards: {
        provider: {
          title: 'Share trusted Providers',
          description: 'Help grow our parent-approved network',
          button: 'Endorse a Provider',
        },
        friends: {
          title: 'Connect with Friends',
          description: 'Share your profile and add your Friends',
          button: 'Copy Profile Link',
        },
      },
      responses: {
        valid: {
          addFriend: 'You and {{name}} are friends now',
          declineFriend: 'You declined {{name}} as a friend',
        },
        invalid: {
          profile: 'Could not get profile data',
          friendList: 'Could not get friend list',
          categoryList: 'Could not get popular category list',
          addFriend: 'Could not add a friend',
          declineFriend: 'Could not decline a friend',
          newParents: 'Could not get new parents list',
          newProviders: 'Could not get providers list',
        },
      },
    },
    admin: {
      adminPanel: 'Admin Panel',
      emptyField: 'Field is empty',
      steps: {
        clickToSelect: 'Click to select a type',
        ageToProviderType: 'Click to add an age to current provider type',
        location: 'Location',
        addLocation: 'Add location',
        previousLocation: 'Previous location',
        nextLocation: 'Next location',
        removeLocation: 'Remove location',
        previous: 'Previous',
        next: 'Next',
        basicInfo: 'Basic Info',
        categories: 'Categories',
        types: 'Types',
        about: 'About',
        contact: 'Contact',
        offeringDescription: 'Offering Description',
        offeringDetails: 'Offering Details',
        yearStarted: 'Year started',
        locations: 'Locations',
        educations: 'Educations',
        specialitiesOfficeLocations: 'Specialities & OfficeLocations',
        affiliationsAdditionalServices: 'Affiliations & Additional services',
        languagesCertifications: 'Languages & Certifications',
      },
      tabs: {
        manageProvider: 'Manage Provider',
        manageConfirmations: 'Manage Confirmations',
        manageAges: 'Manage Ages',
        manageCategories: 'Manage Categories',
        manageProviderTypes: 'Manage Provider Types',
      },
      buttons: {
        add: 'Add',
        edit: 'Edit',
        approve: 'Approve',
        reject: 'Reject',
        remove: 'Remove',
        update: 'Update',
        showMore: 'Show more',
      },
      responses: {
        valid: {
          add: 'Successfully added',
          edit: 'Successfully edited',
          remove: 'Successfully removed',
        },
        invalid: {
          get: 'Could not get',
          add: 'Could not add',
          status: 'Could not change status',
          edit: 'Could not edit',
          remove: 'Could not remove',
          image: 'Could not add image',
          invalidApprove: 'Could not Approve',
          invalidAdd: 'Could not Add new Provider',
          invalidGoToUser: 'Could not Add to User',
          invalidReject: 'Could not Reject',
        },
      },
      forms: {
        notEmpty: 'Name and provider type must be not empty',
        errors: 'Check form errors list in the console',
        category: {
          fields: {
            categoryName: 'Category Name',
            isPopular: 'Is popular',
          },
        },
        age: {
          fields: {
            ageName: 'Age Name',
          },
        },
        type: {
          fields: {
            typeName: 'Type Name',
          },
        },
        fields: {
          name: 'Provider name',
          userEmail: 'Endorsing user email',
          isActive: 'Is active',
          about: 'About',
          email: 'Email',
          website: 'Website',
          phoneNumber: 'Phone number',
          offeringDescription: 'Offering description',
          offeringDetails: {
            takesInsurance: 'Takes insurance',
            doesClasses: 'Does classes',
            yearsOfExperience: 'Years of experience',
            yearStarted: 'Year started',
          },
          specialities: 'Specialities (separate by comma character)',
          officeLocations: 'Office locations (separate by comma character)',
          locationsName: 'Locations',
          locations: {
            inHome: 'In home',
            inPerson: 'In person',
            country: 'Country',
            state: 'State',
            city: 'City',
            locality: 'Locality',
            neighborhoodName: 'Neighborhood name',
            zipCodes: 'Zip codes (separated by comma character)',
            virtual: 'Virtual',
          },
          educationsName: 'Education',
          educations: {
            school: 'School',
            degree: 'Degree',
          },
          affiliations: 'Affiliations (separated by comma character)',
          additionalServices: 'Additional services (separated by comma character)',
          languages: 'Languages (separated by comma character)',
          categories: 'Categories',
          certifications: 'Certifications (separated by comma character)',
          providerTypes: 'Provider types',
        },
      },
    },
    search: {
      search: 'Search',
      sort: 'Sort',
      sortOptions: {
        friends: 'Friends',
        endorsements: 'Endorsements',
      },
      results: '{{length}} results',
      noResults: {
        info: 'No results with <strong>{{searchQuery}}</strong>',
        popularCategories: 'POPULAR CATEGORIES',
      },
      location: {
        title: 'Location',
        description: 'Changing location below will not impact your default location',
      },
      responses: {
        invalid: {
          categoryList: 'Could not get popular category list',
          providers: 'Could not get providers',
          parents: 'Could not get parents',
          category: 'Could not get category',
          providerType: 'Could not get providerType',
          specialty: 'Could not get specialty',
          additionalService: 'Could not get additional service',
          recentSearches: 'Could not get recent searches',
        },
      },
      buttons: {
        filter: 'Filters / Sort',
        apply: 'Apply',
        clear: 'Clear',
      },
      tabs: {
        providers: 'Providers',
        parents: 'Parents',
      },
    },
    settings: {
      title: 'Settings',
      personalInformation: {
        title: 'Personal Information',
        description: 'Changes to your name will be reflected on your profile page',
        fields: {
          firstName: 'First Name',
          lastName: 'Last Name',
          email: 'Email',
          phoneNumber: 'Phone Number',
        },
        responses: {
          valid: 'Personal information has been changed',
          invalid: 'Could not change personal information',
        },
      },
      changePassword: {
        title: 'Change Password',
        fields: {
          password: 'Existing Password',
          newPassword: 'New Password',
          confirmNewPassword: 'Re-type New Password',
        },
        responses: {
          valid: 'Password has been changed',
          invalid: 'Could not change password',
        },
      },
      deleteAccount: {
        title: 'Delete Account',
        description: 'Enter your password below to delete your account',
        fields: {
          password: 'Password',
        },
        responses: {
          valid: 'Your account has been deleted',
          invalid: 'Could not delete account',
        },
      },
      buttons: {
        save: 'Save',
        cancel: 'Cancel',
        delete: 'Delete',
      },
    },
    notFound: {
      subtitle: 'Oops, looks like you’re lost.',
      button: 'Home',
    },
    contactUs: {
      title: 'Contact us',
      description: 'Send us a message to tell us what features you would like added to ParentUp.',
      modal: {
        description: 'Your message was sent',
      },
      fields: {
        name: 'Your Name',
        email: 'Your Email',
        subject: 'Subject',
        message: 'Message',
      },
      placeholders: {
        message:
          'Let the Provider know why you are reaching out, the age of your kid(s)/due date. If you have friends that endorsed them on ParentUp let them know that.',
      },
      buttons: {
        cancel: 'Cancel',
        send: 'Send',
        home: 'Home',
      },
    },
    myFriends: {
      title: 'My Friends',
    },
    myEndorsements: {
      title: 'My Endorsements',
    },
    editNeighborhoods: {
      title: 'Edit My Neighborhoods',
      save: 'Save',
    },
  },
};
