import { ComponentType } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

interface RouterProps {
  navigate: NavigateFunction;
}

export type WithRouterProps<T> = T & RouterProps;
type OmitRouter<T> = Omit<T, keyof RouterProps>;

export function withNavigate<T>(Component: ComponentType<OmitRouter<T> & RouterProps>) {
  return (props: OmitRouter<T>) => {
    const navigate = useNavigate();

    return <Component navigate={navigate} {...props} />;
  };
}
