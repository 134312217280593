import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import theme from '@theme/index';
import { renderTranslationText } from '@utils/translation';

import { TypographyProps } from './Typography.types';
import * as Styles from './Typography.styles';

const Typography: FC<TypographyProps> = ({
  children,
  shouldTranslate = false,
  color = theme.colors.black,
  variant = 'body2',
  weight = 'normal',
  align = 'left',
  letterSpacing = '0',
  shouldBreakLongWord = false,
  disableUserSelect = false,
  noWrap = false,
  withMarkdown = false,
  lineHeight,
}) => {
  const { t } = useTranslation();

  const shouldRenderMarkdown = typeof children === 'string' && withMarkdown;
  const renderChildren = shouldRenderMarkdown ? <ReactMarkdown>{children as string}</ReactMarkdown> : children;

  return (
    <Styles.TypographyComponent
      variant={variant}
      fontWeight={weight === 'bold' ? '700' : '500'}
      color={color}
      $lineHeight={lineHeight}
      data-component-locator="typography-component"
      align={align}
      letterSpacing={letterSpacing}
      $disableUserSelect={disableUserSelect}
      $shouldBreakLongWord={shouldBreakLongWord}
      noWrap={noWrap}
    >
      {shouldTranslate ? renderTranslationText(t, renderChildren) : renderChildren}
    </Styles.TypographyComponent>
  );
};

export default Typography;
