import { styled } from '@mui/material/styles';
import { SearchBarStyleProps } from './SearchBar.types';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const SearchWrapper = styled('div')<SearchBarStyleProps>`
  display: flex;
  height: ${({ $hasSmallSearchInput = false }) => ($hasSmallSearchInput ? 42 : 56)}px;
  align-items: center;
  position: relative;
`;

export const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding-left: ${({ theme }) => theme.spacing(9)};
`;

export const Label = styled('label')`
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

export const SearchInput = styled('input')<SearchBarStyleProps>`
  width: 100%;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.body3.fontSize};
  border: 0;
  border: 1px solid ${({ theme }) => theme.colors.greyDark3};
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  padding: ${({ theme, $hasSmallSearchInput }) => {
    return $hasSmallSearchInput
      ? `${theme.spacing(5)} ${theme.spacing(8)} ${theme.spacing(5)} ${theme.spacing(27)}`
      : `${theme.spacing(8)} ${theme.spacing(8)} ${theme.spacing(8)} ${theme.spacing(27)}`;
  }};
  color: ${({ theme }) => theme.colors.black};
  outline-color: ${({ theme }) => theme.colors.greyDark3};

  ::placeholder {
    color: ${({ theme }) => theme.colors.greyDark3};
    opacity: 1;
  }

  :focus {
    border-width: 2px;
    outline: 0;
  }
`;

export const DataListWrapper = styled('div')<SearchBarStyleProps>`
  width: 100%;
  position: absolute;
  top: ${({ $hasSmallSearchInput }) => ($hasSmallSearchInput ? 42 : 58)}px;
  z-index: 10;
  padding: ${({ theme }) => `${theme.spacing(4)} 0 0`};
  margin: 0;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.customShadows.searchBarDataList};
  border-radius: 4px;
  height: fit-content;
  max-height: none;
  overflow: auto;
`;

export const ClearIconWrapper = styled('div')`
  position: absolute;
  right: 0;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing(8)};

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    margin-right: ${({ theme }) => theme.spacing(7)};
    padding: ${({ theme }) => theme.spacing(1)};
  }
`;

export const DataList = styled('ul')`
  margin: 0;
  padding: 0;
`;
