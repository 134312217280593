import { Suspense } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

import AuthProvider from '@context/Auth/Auth.provider';
import LayoutProvider from '@context/Layout/Layout.provider';
import NotificationsProvider from '@context/Notifications/Notifications.provider';
import LocationProvider from '@context/Location/Location.provider';

import theme from '@theme';
import Pages from '@pages/Pages';
import Layout from '@layout/Layout';
import Loader from '@components/Loaders/Loader';
import ErrorBoundary from '@components/ErrorBoundary';

const App = () => (
  <Suspense fallback={<Loader />}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NotificationsProvider>
        <AuthProvider>
          <LayoutProvider>
            <LocationProvider>
              <Layout>
                <ErrorBoundary>
                  <Pages />
                </ErrorBoundary>
              </Layout>
            </LocationProvider>
          </LayoutProvider>
        </AuthProvider>
      </NotificationsProvider>
    </ThemeProvider>
  </Suspense>
);

export default App;
