import { styled } from '@mui/material/styles';

import { HEADER_HEIGHT } from '@components/Headers/Header/Header.constants';

export const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  height: calc(100vh - ${HEADER_HEIGHT}px);
`;

export const ContentWrapper = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
`;

export const SubtitleWrapper = styled('div')`
  max-width: 230px;
  margin: ${({ theme }) => `${theme.spacing(8)} 0`};
  position: relative;
  z-index: 10;

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    max-width: none;
  }
`;

export const ButtonWrapper = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(8)};
  position: relative;
  z-index: 10;
`;

export const TitleWrapper = styled('div')`
  position: absolute;
  top: 40px;

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    top: 0;
  }
`;

export const Title = styled('h1')`
  font-size: 10.5625rem;
  line-height: 0;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.blueSubtle};

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    font-size: 22.8125rem;
  }
`;
