import { rootElement } from '@utils/app';

const getScrollBarWidth = () => (rootElement?.clientWidth ? window.innerWidth - rootElement.clientWidth : 0);
const scrollBarWidth = `${getScrollBarWidth()}px`;

export const onEnterTransitionCallback = (isMobile: boolean) => {
  // document.body.style.setProperty('overflow', 'hidden');
  rootElement?.style.setProperty('overflow', 'hidden');
  if (!isMobile) {
    rootElement?.style.setProperty('padding-right', scrollBarWidth);
  }
};

export const onExitedTransitionCallback = (isMobile: boolean) => {
  // document.body.style.removeProperty('overflow');
  rootElement?.style.removeProperty('overflow');
  if (!isMobile) {
    rootElement?.style.removeProperty('padding-right');
  }
};
