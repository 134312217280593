const PASSWORD_REGEX = /^[^\s]{8,20}$/;
const NAME_REGEX = /^[a-zA-Z0-9\\.,\-;: ]*$/;
const PHONE_NUMBER_REGEX = /^\d{10}$/;
const EMAIL_REGEX = /\S+@\S+\.\S+/;
const ZIP_CODE_REGEX = /^\d{5}$/;
const DATE_REGEX = /^(?:(0[1-9]|1[012])[/.](0[1-9]|[12][0-9]|3[01])[/.](19|20)[0-9]{2})$/;
const UUID_REGEX = /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/;
const URL_REGEX =
  /^(http[s]?:\/\/(www\.)?|ftp:\/\/(www\.)?|www\.){1}([0-9A-Za-z-.@:%_+~#=]+)+((\.[a-zA-Z]{2,3})+)(\/(.)*)?(\?(.)*)?/;
const FIELD_MIN_LENGTH = 2;
const FIELD_MAX_LENGTH = 100;
const FIELD_MAX_NAME_LENGTH = 150;
const FIELD_MAX_NAME_NEW_LENGTH = 30;
const ABOUT_MAX_LENGTH = 2000;
const HARACTER_MAX_LENGTH = 10;
const ZIP_CODE_LENGTH = 5;
const NAME_MAX_LENGTH = 30;
const NAME_MIN_LENGTH = 1;
const PROVIDER_MIN_LENGTH = 1;
const PROVIDER_MAX_LENGTH = 5;
const CHILD_NAME_MIN_LENGTH = 1;

const PHONE_NUMBER_MASK = '(999) 999-9999';
const AGE_DATE_MASK = '99/99/9999';

export const REGEX = {
  PASSWORD: PASSWORD_REGEX,
  PHONE_NUMBER: PHONE_NUMBER_REGEX,
  EMAIL: EMAIL_REGEX,
  ZIP_CODE: ZIP_CODE_REGEX,
  NAME: NAME_REGEX,
  URL: URL_REGEX,
  DATE: DATE_REGEX,
  UUID: UUID_REGEX,
};

export const MASKS = {
  PHONE_NUMBER: PHONE_NUMBER_MASK,
  AGE_DATE: AGE_DATE_MASK,
};

export const ERRORS = {
  FIELD_REQUIRED: 'forms.errors.fieldRequired',
  VALID_EMAIL: 'forms.errors.validEmail',
  VALID_WEBSITE: 'forms.errors.validWebsite',
  VALID_PASSWORD: 'forms.errors.validPassword',
  VALID_NAME: 'forms.errors.validName',
  VALID_URL: 'forms.errors.validUrl',
  MATCH_PASSWORD: 'forms.errors.matchPassword',
  VALID_PHONE_NUMBER: 'forms.errors.validPhoneNumber',
  VALID_ZIP_CODE: 'forms.errors.validZipCode',
  VALID_PRICE_TO: 'forms.errors.priceTo',
  VALID_DATE: 'forms.errors.validDate',
  MIN_LENGTH: 'forms.errors.minLength',
  MAX_LENGTH: 'forms.errors.maxLength',
};

export const LENGTHS = {
  MIN: FIELD_MIN_LENGTH,
  MAX_LENGTH_NAME: NAME_MAX_LENGTH,
  MAX_ABOUT_LENGTH: ABOUT_MAX_LENGTH,
  HARACTER_MAX_LENGTH,
  MIN_LENGTH_NAME: NAME_MIN_LENGTH,
  MAX_LENGTH_SERVICE_NAME: FIELD_MAX_NAME_LENGTH,
  FIELD_MAX_NAME_NEW_LENGTH,
  PROVIDER_MIN_LENGTH,
  PROVIDER_MAX_LENGTH,
  MAX: FIELD_MAX_LENGTH,
  ZIP_CODE: ZIP_CODE_LENGTH,
  CHILD_NAME_MIN: CHILD_NAME_MIN_LENGTH,
};

export const PROVIDER_LENGTHS = {
  NAME_MAX: 200,
  ABOUT_MAX: 2000,
  OFFERINGS_MAX: 3000,
  AFFILIATIONS_MAX: 100,
  WEBSITE_MAX: 50,
  CERTIFICATIONS_MAX: 300,
  EDUCATION_MAX: 300,
  SPECIALTIES_MAX: 15,
};
