import { FC, forwardRef } from 'react';

import theme from '@theme/index';
import Typography from '@components/Typography';

import { NotificationProps, NotificationRefProps } from './Notification.types';
import { TEST_ID } from './Notification.constants';
import * as Styles from './Notification.styles';

const Notification: FC<NotificationProps> = forwardRef<HTMLDivElement, NotificationRefProps>(({ message }, ref) => {
  return (
    <Styles.Notification ref={ref} data-component-locator={TEST_ID}>
      <Typography variant="body3" color={theme.colors.white}>
        {message}
      </Typography>
    </Styles.Notification>
  );
});

export default Notification;
