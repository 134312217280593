import { styled } from '@mui/material/styles';
import { Tabs as MuiTabs, Tab as MuiTab } from '@mui/material';

export const Container = styled('div')`
  width: 100%;
`;

export const TabsContainer = styled('div')`
  ${({ theme }) => theme.breakpoints.up('laptop')} {
    border-bottom: 1px solid ${({ theme }) => theme.colors.greyDark4};
  }

  .MuiTabs-root {
    min-height: unset;
  }

  .MuiButtonBase-root {
    text-transform: none;
    font-size: ${({ theme }) => theme.typography.body4.fontSize};
    line-height: ${({ theme }) => theme.typography.body4.lineHeight};
    color: ${({ theme }) => theme.colors.greyDark4};
    font-weight: 700;
    padding: 0 0 ${({ theme }) => theme.spacing(4)};
    min-width: unset;
    min-height: unset;

    :not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing(12)};
    }

    ${({ theme }) => theme.breakpoints.up('laptop')} {
      font-size: ${({ theme }) => theme.typography.h6.fontSize};
      line-height: ${({ theme }) => theme.typography.h6.lineHeight};
    }
  }

  .Mui-selected {
    color: ${({ theme }) => theme.colors.black};
  }

  .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colors.blue};
  }
`;

export const Tabs = styled(MuiTabs)``;

export const Tab = styled(MuiTab)``;

export const TabContent = styled('div')``;
