import { FC } from 'react';

import theme from '@theme';
import Typography from '@components/Typography';
import Skeleton from '@components/Loaders/Skeleton';
import { getInitials } from '@utils/user';

import { AvatarGroupProps } from './AvatarGroup.types';
import * as Styles from './AvatarGroup.styles';
import {
  AVATAR_GROUP_MAX,
  AVATAR_GROUP_TEST_ID,
  AVATAR_GROUP_TOTAL,
  AVATAR_TEST_ID,
  INITIALS_TEST_ID,
} from './AvatarGroup.constants';

const AvatarGroup: FC<AvatarGroupProps> = ({
  total = AVATAR_GROUP_TOTAL,
  max = AVATAR_GROUP_MAX,
  profiles = [],
  hasSkeleton = false,
}) => (
  <Styles.AvatarGroupWrapper total={total} max={max} data-component-locator={AVATAR_GROUP_TEST_ID}>
    {profiles.map(({ firstName, lastName, imageUrl }, index) => {
      if (hasSkeleton) {
        return (
          <Skeleton key={`avatar-skeleton-${index.toString()}`} variant="avatarGroup">
            <Styles.Avatar src="" alt="skeleton" />
          </Skeleton>
        );
      }

      if (imageUrl) {
        return (
          <Styles.Avatar
            key={`avatar-${firstName}-${lastName}-${index.toString()}`}
            src={imageUrl}
            alt={`avatar-${firstName}-${lastName}`}
            data-component-locator={`${AVATAR_TEST_ID}-${index.toString()}`}
          />
        );
      }

      return (
        <Styles.Initials
          key={`initials-${firstName}-${lastName}-${index.toString()}`}
          data-component-locator={`${INITIALS_TEST_ID}-${index.toString()}`}
        >
          <Typography variant="body5" color={theme.colors.white} disableUserSelect>
            {getInitials(firstName)}
          </Typography>
        </Styles.Initials>
      );
    })}
  </Styles.AvatarGroupWrapper>
);

export default AvatarGroup;
