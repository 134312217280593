export const colors = {
  white: '#FFF',
  black: '#000',
  blue: '#2C38EA',
  blueSemiLight: '#3D5DF6',
  blueDark: '#1D28C2',
  blueSubtle: '#E3EDFF',
  blueSubtle2: '#D7E0F1',
  blueLight: '#F1F6FF',
  blueLighter: '#99BBFF',
  grey: '#8C8C8C',
  greyDark1: '#4A4A50',
  greyDark2: '#5E6269',
  greyDark3: '#8A8E9A',
  greyDark4: '#BBBCC7',
  light1: '#D7D7DB',
  light2: '#E7E7EA',
  light3: '#F5F5F7',
  light4: '#FBFBFB',
  light5: '#E9E4E8',
  light6: '#BBBCC7',
  light7: '#F5F5F6',
  red: '#FF3B3B',
  redLight: '#ffeeed',
  green: '#2E4F28',
  yellow: '#FADF53',
  orange: '#EE742F',
  lightOrange: '#FFEBDF',
  pink: '#F4BAAA',
  backdrop: 'rgba(0, 0, 0, 0.5)',
};

export type ColorTypes = typeof colors;
export type ColorNames = keyof ColorTypes;
