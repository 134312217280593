import { styled, css } from '@mui/material/styles';

import { DataListWrapper } from '../SearchBar.styles';
import { PARENT_AVATAR_SIZE, PROVIDER_AVATAR_HEIGHT, PROVIDER_AVATAR_WIDTH } from './GlobalSearchBar.constants';
import {
  AvatarStyleProps,
  ListItemStyleProps,
  ListStyleProps,
  MobileHeadingStyleProps,
  MobileSearchDataWrapperStyleProps,
} from './GlobalSearchBar.types';

export const Wrapper = styled('div')`
  padding: ${({ theme }) => `0 0 ${theme.spacing(14)}`};

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    max-height: 610px;
  }
`;

export const HeadingWrapper = styled('div')<MobileHeadingStyleProps>`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => `0 ${theme.spacing(12)}`};

  > span {
    letter-spacing: 0.02em;
    text-transform: ${({ $isLowerCase }) => ($isLowerCase ? 'unset' : 'uppercase')};
  }

  :not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing(8)};
  }

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    > span {
      text-transform: unset;
    }
  }
`;

export const List = styled('ul')<ListStyleProps>`
  margin: 0;
  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};
  list-style: none;

  ${({ $isHeaderVariant }) =>
    $isHeaderVariant &&
    `
    div:last-child {
      flex: 1;
      max-width: 100%;
    }
  `}
`;

export const ListItem = styled('li')<ListItemStyleProps>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(6)}`};
  cursor: pointer;
  line-height: 0;

  ${({ theme, $hasPopularCategories }) =>
    $hasPopularCategories &&
    `
    :not(:last-child) {
      margin-bottom: ${theme.spacing(4)};
    }
  `}

  mark {
    background-color: transparent;
    font-weight: bold;
    color: inherit;
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.blueLight};
  }
`;

export const ListIcon = styled('div')`
  margin-right: ${({ theme }) => theme.spacing(6)};
`;

export const avatarInitialsStyle = css`
  background-color: ${({ theme }) => theme.colors.green};
  text-align: center;
`;

export const ParentAvatar = styled('div')<AvatarStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${PARENT_AVATAR_SIZE}px;
  min-width: ${PARENT_AVATAR_SIZE}px;
  height: ${PARENT_AVATAR_SIZE}px;
  margin-right: ${({ theme }) => theme.spacing(3)};
  border-radius: 50%;
  ${({ $hasAvatar }) => !$hasAvatar && avatarInitialsStyle};
`;

export const TypeWrapper = styled('div')`
  margin-left: ${({ theme }) => theme.spacing(4)};
  line-height: 0;
`;

export const HintDot = styled('span')`
  display: inline-flex;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.greyDark3};
  margin: 0 ${({ theme }) => theme.spacing(4)};
`;

export const ProviderAvatar = styled('div')<AvatarStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${PROVIDER_AVATAR_WIDTH}px;
  height: ${PROVIDER_AVATAR_HEIGHT}px;
  min-width: ${PROVIDER_AVATAR_WIDTH}px;
  object-fit: cover;
  object-position: center;
  margin-right: ${({ theme }) => theme.spacing(3)};
  border-radius: 0 0 ${PROVIDER_AVATAR_HEIGHT / 2}px ${PROVIDER_AVATAR_HEIGHT / 2}px;
  ${({ $hasAvatar }) => !$hasAvatar && avatarInitialsStyle};
`;

export const SeeAllWrapper = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(6)};
  padding: ${({ theme }) => `0 ${theme.spacing(12)}`};
`;

export const MobileSearchMask = styled('div')`
  width: 100vw;
  position: fixed;
  inset: 0;
  z-index: 20;
  background-color: ${({ theme }) => theme.colors.green};
  overflow: auto;
`;

export const MobileSearchInputWrapper = styled('div')`
  width: 100%;

  > input {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.white};
    caret-color: ${({ theme }) => theme.colors.white};
    outline: none;

    ::placeholder {
      color: ${({ theme }) => theme.colors.white};
    }

    :focus {
      border-width: 1px;
    }
  }
`;

export const MobileSearchDataWrapper = styled(DataListWrapper)<MobileSearchDataWrapperStyleProps>`
  top: 54px;
  background-color: ${({ $hasEmptySearchQuery, theme }) => ($hasEmptySearchQuery ? 'transparent' : theme.colors.white)};
  box-shadow: none;
  border-radius: 0;
  padding-bottom: ${({ theme }) => theme.spacing(14)};

  circle {
    stroke: ${({ theme, $hasEmptySearchQuery }) =>
      $hasEmptySearchQuery ? theme.colors.white : theme.colors.greyDark2};
  }

  li:hover {
    background-color: ${({ theme, $hasEmptySearchQuery }) =>
      $hasEmptySearchQuery ? 'transparent' : theme.colors.blueSubtle};
  }
`;

export const Ellipsis = styled('div')`
  max-width: 148px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.greyDark3};
  -webkit-line-clamp: 2;

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    max-width: none;
  }
`;

export const IconSearchWrapper = styled('div')`
  margin: ${({ theme }) => `0 ${theme.spacing(10)}`};
  cursor: pointer;

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin: 0;
  }
`;
