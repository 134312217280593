import { FC, useState } from 'react';

import { LocationContext } from './Location.context';
import { SelectedDataSet } from './Location.types';

export const LocationProvider: FC = ({ children }) => {
  const [selectedDataSet, setSelectedDataSet] = useState<SelectedDataSet>([]);

  return (
    <LocationContext.Provider value={{ selectedDataSet, setSelectedDataSet }}>{children}</LocationContext.Provider>
  );
};

export default LocationProvider;
