import { ReactElement, useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '@context/Auth/Auth.context';
import * as Routes from '@constants/routes';
import FriendService from '@services/Friend/Friend.service';
import { FriendsListInvites, FriendsListItems } from '@components/UserList/UserList.types';
import UserList from '@components/UserList';
import useNotification from '@hooks/useNotification';
import { copyProfileLink } from '@utils/user';

import useCloseOnRouteChange from '@hooks/useCloseOnRouteChange';
import { DEFAULT_REQUEST_SIZE } from '@constants/api';
import { getStorageToken } from '@utils/storage';
import { FriendsListProps } from './FriendsList.types';
import { DEFAULT_SIZE } from './FriendsList.constants';

const FriendsList = ({
  closeHandler,
  isModalOpen,
  hasInvitesTabOpen = false,
  isParentProfile = false,
  isMutualFriends = false,
  title,
  mutualFriendsCount,
}: FriendsListProps): ReactElement => {
  const [isLoadingFriends, setIsLoadingFriends] = useState(false);
  const [isLoadingInvites, setIsLoadingInvites] = useState(false);
  const { addNotification, addResponseErrorNotification } = useNotification();
  const { user, refreshFriends, shouldRefreshFriends } = useContext(AuthContext);
  const navigate = useNavigate();
  const { id: parentId } = useParams();
  const [friends, setFriends] = useState<FriendsListItems>([]);
  const [invites, setInvites] = useState<FriendsListInvites>([]);
  const [currentFriendsPage, setCurrentFriendsPage] = useState(0);
  const [currentInvitesPage, setCurrentInvitesPage] = useState(0);
  const [hasMoreFriends, setHasMoreFriends] = useState(false);
  const [hasMoreInvites, setHasMoreInvites] = useState(false);
  const isInitialFriendsLoad = friends.length === 0 && currentFriendsPage === 0;
  const isInitialInvitesLoad = invites.length === 0 && currentInvitesPage === 0;
  const [friendsTotalCount, setFriendsTotalCount] = useState(0);
  const [invitesTotalCount, setInvitesTotalCount] = useState(0);
  const token = getStorageToken();
  const { t } = useTranslation();

  const handleCopyLink = () => {
    const profileId = user?.id;

    if (profileId) {
      copyProfileLink(profileId);
      addNotification(t('common.copy.linkCopiedMessage'));
      return;
    }

    addNotification(t('common.copy.copyLinkError'));
  };

  const freshLoadMyFriends = () => {
    setIsLoadingFriends(true);

    FriendService.getMyFriends({
      page: 0,
      size: DEFAULT_SIZE,
      sort: 'asc',
    })
      .then(({ data }) => {
        setFriendsTotalCount(data.totalElements);
        const setFriendsWithClickHandler = (): any =>
          data.content.map((content) => {
            const { profileId } = content;

            return {
              ...content,
              clickHandler: () => navigate(`${Routes.PARENTS}/${profileId}`),
            };
          });

        setFriends(setFriendsWithClickHandler());

        setCurrentFriendsPage(1);
        setIsLoadingFriends(false);
      })
      .catch(({ errorMessage, status }) =>
        addResponseErrorNotification('pages.dashboard.responses.invalid.friendList', errorMessage, status),
      );
  };

  const loadMyFriends = () => {
    setIsLoadingFriends(true);

    FriendService.getMyFriends({
      page: isInitialFriendsLoad || !hasMoreFriends ? 0 : currentFriendsPage,
      size: DEFAULT_SIZE,
      sort: 'asc',
    })
      .then(({ data }) => {
        setFriendsTotalCount(data.totalElements || 0);
        const setFriendsWithClickHandler = () =>
          data.content.map((content) => {
            const { profileId } = content;

            return {
              ...content,
              clickHandler: () => navigate(`${Routes.PARENTS}/${profileId}`),
            };
          });

        setFriends((oldFriends: any) => {
          setHasMoreFriends(!data.last);
          if (isInitialFriendsLoad) {
            setFriendsWithClickHandler();
          }

          const newContent = setFriendsWithClickHandler();

          return [...oldFriends, ...newContent];
        });

        setCurrentFriendsPage((previousState) => (isInitialFriendsLoad ? 1 : previousState + 1));
        setIsLoadingFriends(false);
      })
      .catch(({ errorMessage, status }) =>
        addResponseErrorNotification('pages.dashboard.responses.invalid.friendList', errorMessage, status),
      );
  };

  const loadFriends = () => {
    if (!parentId) {
      return;
    }

    setIsLoadingFriends(true);
    FriendService.getFriends({
      id: parentId,
      page: isInitialFriendsLoad || !hasMoreFriends ? 0 : currentFriendsPage,
      size: DEFAULT_SIZE,
      sort: 'asc',
    })
      .then(({ data }) => {
        if (data) {
          const setFriendsWithClickHandler = () =>
            data.content.map((content) => {
              const { profileId } = content;

              return {
                ...content,
                clickHandler: () => {
                  if (user.id === profileId) {
                    navigate(Routes.PROFILE);
                  }

                  navigate(`${Routes.PARENTS}/${profileId}`);
                },
              };
            });
          setFriends((oldFriends: any) => {
            setFriendsTotalCount(data.totalElements || 0);
            setHasMoreFriends(!data.last);

            if (isInitialFriendsLoad) {
              setFriendsWithClickHandler();
            }

            const newContent = setFriendsWithClickHandler();

            return [...oldFriends, ...newContent];
          });

          setCurrentFriendsPage((previousState) => (isInitialFriendsLoad ? 1 : previousState + 1));
          setIsLoadingFriends(false);
        }
      })
      .catch(({ errorMessage, status }) => {
        addResponseErrorNotification('pages.parent.responses.invalid.getFriend', errorMessage, status);
      });
  };

  const handleFriendAccept = (friendId: string, friendName: string, loadInvitesCallback: () => void) => {
    if (user.id) {
      FriendService.acceptFriendRequest({
        userId: user.id,
        friendId,
      })
        .then(() => {
          addNotification(t('pages.dashboard.responses.valid.addFriend', { name: friendName }));
          loadInvitesCallback();
          freshLoadMyFriends();
          refreshFriends(true);
        })
        .catch(({ errorMessage, status }) => {
          addResponseErrorNotification('pages.dashboard.responses.invalid.addFriend', errorMessage, status);
        });
    }
  };

  const handleFriendDecline = (friendId: string, friendName: string, loadInvitesCallback: () => void) => {
    if (user.id) {
      FriendService.declineFriendRequest({
        userId: user.id,
        friendId,
      })
        .then(() => {
          addNotification(t('pages.dashboard.responses.valid.declineFriend', { name: friendName }));
          loadInvitesCallback();
          refreshFriends(true);
        })
        .catch(({ errorMessage, status }) => {
          addResponseErrorNotification('pages.dashboard.responses.invalid.declineFriend', errorMessage, status);
        });
    }
  };

  const freshLoadInvites = () => {
    if (user.id) {
      FriendService.getFriendRequests({ id: user.id })
        .then(({ data }) => {
          setInvitesTotalCount(data.totalElements);
          setInvites(
            data.content.map((content) => {
              const { profileId, firstName } = content;

              return {
                ...content,
                visitHandler: () => navigate(`${Routes.PARENTS}/${profileId}`),
                acceptHandler: () => handleFriendAccept(profileId, firstName, () => {}),
                declineHandler: () => handleFriendDecline(profileId, firstName, () => {}),
              };
            }),
          );
        })
        .catch(({ errorMessage, status }) => {
          addResponseErrorNotification('pages.dashboard.responses.invalid.friendList', errorMessage, status);
        });
    }
  };

  const loadInvites = () => {
    setIsLoadingInvites(true);

    if (!user.id) {
      return;
    }

    FriendService.getFriendRequests({
      id: user.id,
      page: isInitialInvitesLoad || !hasMoreInvites ? 0 : currentInvitesPage,
      size: DEFAULT_SIZE,
      sort: 'asc',
    })
      .then(({ data }) => {
        setInvitesTotalCount(data.totalElements || 0);
        const setFriendInvites = () =>
          data.content.map((content) => {
            const { profileId, firstName } = content;

            return {
              ...content,
              visitHandler: () => navigate(`${Routes.PARENTS}/${profileId}`),
              acceptHandler: () => handleFriendAccept(profileId, firstName, freshLoadInvites),
              declineHandler: () => handleFriendDecline(profileId, firstName, freshLoadInvites),
            };
          });
        setInvites((oldInvites) => {
          setHasMoreInvites(!data.last);

          if (isInitialInvitesLoad) {
            setFriendInvites();
          }

          const newContent = setFriendInvites();

          return [...oldInvites, ...newContent];
        });

        setCurrentInvitesPage((previousState) => (isInitialInvitesLoad ? 1 : previousState + 1));
        setIsLoadingInvites(false);
      })
      .catch(({ errorMessage, status }) =>
        addResponseErrorNotification('pages.dashboard.responses.invalid.friendList', errorMessage, status),
      );
  };

  const loadMutualFriends = () => {
    if (!parentId) {
      return;
    }

    setIsLoadingFriends(true);
    FriendService.getMutualFriends({
      id: parentId,
      page: isInitialFriendsLoad || !hasMoreFriends ? 0 : currentFriendsPage,
      size: DEFAULT_REQUEST_SIZE,
    })
      .then(({ data }) => {
        if (data) {
          const setFriendsWithClickHandler = () =>
            data.content.map((content) => {
              const { profileId } = content;

              return {
                ...content,
                clickHandler: () => {
                  if (user.id === profileId) {
                    navigate(Routes.PROFILE);
                  }

                  navigate(`${Routes.PARENTS}/${profileId}`);
                },
              };
            });
          setFriends((oldFriends: any) => {
            setFriendsTotalCount(data.totalElements || 0);
            setHasMoreFriends(!data.last);

            if (isInitialFriendsLoad) {
              setFriendsWithClickHandler();
            }

            const newContent = setFriendsWithClickHandler();

            return [...oldFriends, ...newContent];
          });

          setCurrentFriendsPage((previousState) => (isInitialFriendsLoad ? 1 : previousState + 1));
          setIsLoadingFriends(false);
        }
      })
      .catch(({ errorMessage, status }) => {
        addResponseErrorNotification('pages.parent.responses.invalid.getMutualFriend', errorMessage, status);
      });
  };

  useEffect(() => {
    if (!isParentProfile) {
      if (token) {
        loadMyFriends();
      }
      loadInvites();
    }
  }, []);

  useEffect(() => {
    if (isParentProfile) {
      loadFriends();
    }
  }, []);

  useEffect(() => {
    if (isParentProfile && isMutualFriends) {
      loadMutualFriends();
    }
  }, [isParentProfile, isMutualFriends]);

  useEffect(() => {
    if (shouldRefreshFriends) {
      setFriends([]);
      setInvites([]);
      if (token) {
        freshLoadMyFriends();
      }
      freshLoadInvites();
      refreshFriends(false);
    }
  }, [shouldRefreshFriends]);

  useCloseOnRouteChange(closeHandler);
  return (
    <UserList
      title={title || 'pages.myFriends.title'}
      variant="friends"
      isModalOpen={isModalOpen}
      closeHandler={closeHandler}
      handleCopyLink={handleCopyLink}
      friends={friends}
      invites={isParentProfile ? [] : invites}
      isLoadingFriends={isLoadingFriends}
      isLoadingInvites={isLoadingInvites}
      hasMoreFriends={hasMoreFriends}
      hasMoreInvites={hasMoreInvites}
      mutualFriends={mutualFriendsCount}
      friendsTotalCount={friendsTotalCount}
      invitesTotalCount={invitesTotalCount}
      loadFriendsCallback={isParentProfile ? loadFriends : loadMyFriends}
      loadInvitesCallback={isParentProfile ? () => null : loadInvites}
      size={DEFAULT_SIZE}
      hasInvitesTabOpen={isParentProfile ? false : hasInvitesTabOpen}
      hasTabs={!isParentProfile}
      hasActionCard={!isParentProfile}
    />
  );
};

export default FriendsList;
