import { styled, css } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';

import { SkeletonConfig } from './Skeleton.types';
import { SKELETON_PROVIDER_HEIGHT } from './Skeleton.constants';

const rectangularStyles = css``;

const circularStyles = css`
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
`;

const providerStyles = css`
  border-radius: 0 0 ${SKELETON_PROVIDER_HEIGHT}px ${SKELETON_PROVIDER_HEIGHT}px;
`;

const avatarGroupStyles = css`
  ${circularStyles};
  max-width: 24px;
  max-height: 24px;
  border: 1px solid ${({ theme }) => theme.colors.white};

  :not(:last-child) {
    margin-left: -10px;
  }
`;

const textStyles = css`
  max-width: max-content;
  width: fit-content;
  height: 20px;
  border-radius: 0;
`;

const config: SkeletonConfig = {
  rectangular: rectangularStyles,
  circular: circularStyles,
  avatarGroup: avatarGroupStyles,
  provider: providerStyles,
  text: textStyles,
};

export const SkeletonRoot = styled(Skeleton)`
  background-color: ${({ theme }) => theme.colors.light3};

  ${({ variant = 'rectangular' }) => config[variant]};
`;
