import { v4 as uuid } from 'uuid';
import { useRef } from 'react';

import useInfiniteLoader from '@hooks/useInfiniteLoader';

import Typography from '@components/Typography';
import theme from '@theme';
import { InfiniteListProps } from './InfiniteList.types';
import { DEFAULT_SIZE } from './InfiniteList.constants';
import * as Styles from './InfiniteList.styles';

const InfiniteList = <T,>({
  items,
  isLoading,
  hasMore,
  loadMoreCallback,
  skeletonElement,
  children,
  size = DEFAULT_SIZE,
  threshold,
}: InfiniteListProps<T>) => {
  const listRef = useRef<HTMLUListElement>(null);
  const { setElement } = useInfiniteLoader({
    observerRootElement: listRef.current as Element,
    loadMoreCallback,
    isLoading,
    threshold,
  });
  const hasNoItems = items.length === 0;
  return (
    <Styles.List ref={listRef}>
      {items.length > 0 &&
        items.map((item, index) => (
          <Styles.ListItem key={`list-item-${index}`}>{children(item, index)}</Styles.ListItem>
        ))}
      {!isLoading && hasNoItems ? (
        <Typography lineHeight="50px" color={theme.colors.greyDark3}>
          No items found
        </Typography>
      ) : (
        <>
          {(isLoading || hasNoItems) &&
            Array(size)
              .fill('')
              .map((_, index) => (
                <Styles.ListItem key={`skeleton-item-${index.toString()}`}>{skeletonElement}</Styles.ListItem>
              ))}
        </>
      )}
      {!isLoading && items.length > 0 && hasMore && <Styles.LoadMoreIndicator ref={setElement} />}
    </Styles.List>
  );
};

export default InfiniteList;
