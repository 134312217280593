import { styled, css } from '@mui/material/styles';

import { AssetProps } from './BackgroundAsset.types';

export const Asset = styled('div')<AssetProps>`
  position: absolute;
  transform: ${({ $degRotate, scale }) => `rotate(${$degRotate}deg) scale(${scale})`};
  ${({ $shadow }) => `filter: drop-shadow(${$shadow})`};

  ${({ left }) =>
    left &&
    css`
      left: ${left};
    `}
  ${({ top }) =>
    top &&
    css`
      top: ${top};
    `}
  ${({ right }) =>
    right &&
    css`
      right: ${right};
    `}
  ${({ bottom }) =>
    bottom &&
    css`
      bottom: ${bottom};
    `}
`;
