import { authConfig } from '@services/services.utils';
import axios, { axiosInstanceNew } from '@utils/api';

import {
  AxiosCategoryProps,
  RequestCategoryProps,
  RequestCategoriesProps,
  RequestCreateCategoryProps,
  AxiosCategoriesProps,
} from './Category.types';

class ProviderCategory {
  endpoint: string;

  constructor() {
    this.endpoint = 'categories';
  }

  getCategory(categoryId: number): Promise<AxiosCategoryProps> {
    return axios.get(`${this.endpoint}/${categoryId}`, authConfig);
  }

  updateCategory({ id, ...requestData }: RequestCategoryProps): Promise<void> {
    return axios.put(`${this.endpoint}/${id}`, requestData, authConfig);
  }

  createCategory(data: RequestCreateCategoryProps): Promise<AxiosCategoryProps> {
    return axios.post(`${this.endpoint}`, data, authConfig);
  }

  removeCategory(categoryId: number): Promise<void> {
    return axios.delete(`${this.endpoint}/${categoryId}`, authConfig);
  }

  getCategories({ page, size, sort }: RequestCategoriesProps): Promise<AxiosCategoriesProps> {
    return axios.get(`${this.endpoint}`, {
      params: {
        page,
        size,
        sort: sort ? `ageName,${sort}` : undefined,
      },
      ...authConfig,
    });
  }

  getAdditions({ page, size, sort }: RequestCategoriesProps): Promise<AxiosCategoriesProps> {
    return axios.get(`${this.endpoint}`, {
      params: {
        page,
        size,
        sort: sort ? `ageName,${sort}` : undefined,
      },
      ...authConfig,
    });
  }

  getCategoriesNoUser(): Promise<AxiosCategoriesProps> {
    return axiosInstanceNew.get(`spro/data/${this.endpoint}`, {
      params: {
        sPopular: true,
      },
    });
  }

  getPopularCategories(): Promise<AxiosCategoriesProps> {
    return axios.get(`${this.endpoint}`, {
      params: {
        isPopular: true,
      },
      ...authConfig,
    });
  }
}

export default new ProviderCategory();
