import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { HEADER_HEIGHT } from './Header.constants';

export const Container = styled('nav')`
  position: sticky;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  z-index: 10;
`;

export const ButtonContainer = styled(Box)``;

export const ContentContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  max-width: ${({ theme }) => `${theme.breakpoints.values.tablet}px`};
  padding: ${({ theme }) => `0 ${theme.spacing(14)}`};

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    max-width: ${({ theme }) => `${theme.breakpoints.values.desktop}px`};
    padding: ${({ theme }) => `0 ${theme.spacing(22)}`};
  }
`;

export const GroupContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const LogoWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing(10)};
  cursor: pointer;
`;

export const HamburgerWrapper = styled('div')`
  cursor: pointer;
`;

export const IconContainer = styled('div')`
  margin: ${({ theme }) => `0 ${theme.spacing(10)}`};
  cursor: pointer;

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin: 0;
  }
`;

export const SearchBarWrapper = styled('div')`
  display: flex;
  margin-right: ${({ theme }) => theme.spacing(23)};

  & > div {
    > label {
      margin-bottom: unset;
    }

    > div:first-child {
      height: unset;
    }
  }

  input {
    width: 312px;
    height: 40px;
  }
`;

export const Link = styled('a')`
  color: inherit;
  text-decoration: none;
`;
