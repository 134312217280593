import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';

import { renderTranslationText } from '@utils/translation';
import useAutoTextArea from '@hooks/useAutoTextArea';

import Typography from '@components/Typography';
import theme from '@theme';
import * as Styles from './Input.styles';
import { InputProps } from './Input.types';
import { TEST_ID } from './Input.constants';

const Input: FC<InputProps> = ({
  value,
  name,
  type = 'text',
  placeholder = '',
  changeHandler,
  maxCharacters,
  blurHandler,
  label,
  isRequired,
  isDisabled,
  assistiveText,
  hasError,
  shouldTranslate,
  shouldAutoResize = false,
  mask,
}) => {
  const { t } = useTranslation();
  const isTextAreaType = type === 'textArea';
  const parentRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { onChangeHandler, textAreaHeight } = useAutoTextArea({ parentRef, textAreaRef });

  const renderInput = () => {
    if (type === 'textArea') {
      return (
        <>
          <Styles.TextArea
            ref={textAreaRef}
            id={name}
            value={value}
            minRows={6}
            name={name}
            $hasError={hasError}
            placeholder={placeholder}
            onChange={(event) => {
              if (shouldAutoResize) {
                onChangeHandler(event);
                changeHandler(event);
                return;
              }

              changeHandler(event);
            }}
            $textAreaHeight={shouldAutoResize ? textAreaHeight : undefined}
            onBlur={blurHandler}
          />
          {maxCharacters && !hasError && (
            <Typography variant="body5" color={theme.colors.greyDark3}>
              {t('pages.addProfile.steps.step3.bio.charactersRemaining', {
                characters: maxCharacters - value.length,
              })}
            </Typography>
          )}
        </>
      );
    }

    return (
      <Styles.Input
        id={name}
        value={value}
        name={name}
        type={type}
        $hasError={hasError}
        placeholder={placeholder}
        onChange={changeHandler}
        onBlur={blurHandler}
        disabled={isDisabled}
      />
    );
  };

  return (
    <Styles.InputContainer data-component-locator={TEST_ID} ref={parentRef} $height={isTextAreaType ? 'auto' : '100px'}>
      {typeof label === 'string' && (
        <Styles.Label htmlFor={name}>
          {shouldTranslate ? renderTranslationText(t, label) : label}
          {isRequired && <Styles.RequireAsterix>*</Styles.RequireAsterix>}
        </Styles.Label>
      )}
      {mask ? (
        <InputMask
          mask={mask}
          value={value}
          disabled={false}
          onChange={changeHandler}
          onBlur={blurHandler}
          alwaysShowMask
        >
          {() => <Styles.Input type={type} $hasError={hasError} name={name} id={name} />}
        </InputMask>
      ) : (
        renderInput()
      )}
      {assistiveText && (
        <Styles.AssistiveText $hasError={hasError} $inputType={type}>
          {shouldTranslate ? renderTranslationText(t, assistiveText) : assistiveText}
        </Styles.AssistiveText>
      )}
    </Styles.InputContainer>
  );
};

export default Input;
