import { FC } from 'react';
import { SkeletonProps } from '@mui/material/Skeleton';

import * as Styles from './Skeleton.styles';
import { SKELETON_TEST_ID } from './Skeleton.constants';

const Skeleton: FC<SkeletonProps> = ({ variant = 'rectangular', children, ...props }) => (
  <Styles.SkeletonRoot variant={variant} data-component-locator={`${SKELETON_TEST_ID}-${variant}`} {...props}>
    {children}
  </Styles.SkeletonRoot>
);

export default Skeleton;
