import { useState, useRef, useEffect, Dispatch, SetStateAction } from 'react';

const DEFAULT_THRESHOLD = 1;

export interface HookProps {
  observerRootElement: Element | undefined;
  loadMoreCallback: () => void;
  isLoading: boolean;
  threshold?: number;
}

interface HookReturnProps {
  setElement: Dispatch<SetStateAction<HTMLElement | null>>;
}

const useInfiniteLoader = ({
  observerRootElement,
  loadMoreCallback,
  threshold = DEFAULT_THRESHOLD,
}: HookProps): HookReturnProps => {
  const [element, setElement] = useState<HTMLElement | null>(null);
  const loader = useRef(loadMoreCallback);
  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        const { isIntersecting } = entries[0];

        if (isIntersecting) {
          loader.current();
        }
      },
      {
        root: observerRootElement,
        threshold,
      },
    ),
  );

  useEffect(() => {
    loader.current = loadMoreCallback;
  }, [loadMoreCallback]);

  useEffect(() => {
    const currentElement = element;
    const currentObserver = observer.current;

    if (!currentElement) {
      return;
    }

    currentObserver.observe(currentElement);

    return () => {
      if (!currentElement) {
        return;
      }

      currentObserver.unobserve(currentElement);
    };
  }, [element]);

  return { setElement };
};

export default useInfiniteLoader;
