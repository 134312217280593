import { FC } from 'react';

import Button from '@components/Buttons/Button';
import Typography from '@components/Typography';
import useBreakpoint from '@hooks/useBreakpoint';
import theme from '@theme';

import { ActionCardProps } from './ActionCard.types';
import { getVariantOptions } from './ActionCard.utils';
import { TEST_ID } from './ActionCard.constants';
import * as Styles from './ActionCard.styles';

const ActionCard: FC<ActionCardProps> = ({
  variant,
  buttonIcon,
  buttonText,
  clickHandler,
  description,
  title,
  iconElement,
  margin,
  url,
}) => {
  const { isDesktop, isMobile, isLaptopOnly } = useBreakpoint();
  const { background, iconBackground } = getVariantOptions(variant);

  const renderButtonWidth = () => {
    if (isLaptopOnly) {
      return '310px';
    }

    if (isDesktop) {
      return '195px';
    }
  };

  return (
    <Styles.ActionBlock color={background} margin={margin} data-component-locator={TEST_ID}>
      <Styles.ActionBlockIconContainer color={iconBackground}>{iconElement}</Styles.ActionBlockIconContainer>
      <Styles.ActionBlockContentContainer>
        <Typography variant="h5" weight="bold" align={isMobile || isLaptopOnly ? 'center' : 'left'} shouldTranslate>
          {title}
        </Typography>
        <Typography variant="body4" shouldTranslate>
          {description}
        </Typography>
      </Styles.ActionBlockContentContainer>
      {url ? (
        <Styles.Link href={url} target="_blank" rel="noopener noreferrer nofollow">
          <Button
            variant="outlined"
            size="medium"
            width={renderButtonWidth()}
            leftIcon={buttonIcon}
            color={theme.colors.blue}
            shouldTranslate
          >
            {buttonText}
          </Button>
        </Styles.Link>
      ) : (
        <Button
          variant="outlined"
          size="medium"
          width={renderButtonWidth()}
          leftIcon={buttonIcon}
          color={theme.colors.blue}
          clickHandler={clickHandler}
          shouldTranslate
        >
          {buttonText}
        </Button>
      )}
    </Styles.ActionBlock>
  );
};

export default ActionCard;
