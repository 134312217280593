import { AxiosRequestConfig } from 'axios';

import { getStorageToken } from '@utils/storage';

export const getAuthConfig = (token: string, config: AxiosRequestConfig) => {
  const requestConfig = { ...config };
  const storageToken = getStorageToken();
  const currentToken = token || storageToken;

  if (currentToken && config.isAuthEndpoint) {
    requestConfig.headers = {
      ...requestConfig.headers,
      Authorization: `Bearer ${currentToken}`,
    };
  }

  return requestConfig;
};

export const authConfig = {
  isAuthEndpoint: true,
};
