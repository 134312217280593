export const HOME = '/onboarding';
export const ADMIN = '/admin';
export const SIGN_UP = '/sign-up';
export const ABOUT = '/about-us';
export const FOR_PROVIDER = '/for-providers';
export const PRIVACY = '/privacy-policy';
export const TERMS = '/terms-of-use';
export const SUCCESS = '/success-page';
export const LOGIN = '/login';
export const CONFIRM_ACCOUNT = '/confirm-account';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const ADD_PROFILE = '/add-profile';
export const ADD_PROVIDER = '/add-provider';
export const EDIT_NEIGHBORHOODS = '/edit-neighborhoods';
export const PROVIDERS = '/providers';
export const PROFILE = '/profile';
export const PROVIDER_PROFILE = '/provider-profile';
export const PARENTS = '/parents';
export const DASHBOARD = '/';
export const SEARCH = '/search';
export const SETTINGS = '/settings';
