import { ReactElement, useContext, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import BackgroundAsset from '@components/BackgroundAsset';
import Typography from '@components/Typography';
import Button from '@components/Buttons/Button';
import { LayoutContext } from '@context/Layout/Layout.context';
import useBreakpoint from '@hooks/useBreakpoint';
import { HOME } from '@constants/routes';

import { ErrorScreenProps } from './ErrorScreen.types';
import * as Styles from './ErrorScreen.styles';
import { desktopAssetsList, mobileAssetsList } from './ErrorScreen.utils';
import { layoutData } from './ErrorScreen.layout';

const ErrorScreen = ({
  title,
  subtitle,
  buttonText = '',
  redirectTo = HOME,
  customButton,
}: ErrorScreenProps): ReactElement => {
  const { updateLayout } = useContext(LayoutContext);
  const { isMobile } = useBreakpoint();
  const navigate = useNavigate();
  const assetsList = isMobile ? mobileAssetsList : desktopAssetsList;

  useLayoutEffect(() => updateLayout(layoutData), []);

  return (
    <Styles.Container>
      {assetsList.map((asset) => (
        <BackgroundAsset key={asset.id} {...asset} />
      ))}
      <Styles.ContentWrapper>
        {title && (
          <Styles.TitleWrapper>
            <Styles.Title>{title}</Styles.Title>
          </Styles.TitleWrapper>
        )}
        <Styles.SubtitleWrapper>
          <Typography variant={isMobile ? 'h3' : 'h2'} weight="bold" align="center" shouldTranslate>
            {subtitle}
          </Typography>
        </Styles.SubtitleWrapper>
        <Styles.ButtonWrapper>
          {!customButton ? (
            <Button
              variant="filled"
              width={isMobile ? '264px' : '328px'}
              clickHandler={() => navigate(redirectTo)}
              shouldTranslate
            >
              {buttonText}
            </Button>
          ) : (
            customButton
          )}
        </Styles.ButtonWrapper>
      </Styles.ContentWrapper>
    </Styles.Container>
  );
};

export default ErrorScreen;
