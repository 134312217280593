import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { TypographyComponentProps } from './Typography.types';

export const TypographyComponent = styled(Typography)<TypographyComponentProps>`
  user-select: ${({ $disableUserSelect }) => ($disableUserSelect ? 'none' : 'auto')};
  word-break: ${({ $shouldBreakLongWord }) => ($shouldBreakLongWord ? 'break-word' : 'normal')};
  line-height: ${({ $lineHeight }) => ($lineHeight ? `${$lineHeight} !important` : 'auto')};
`;
