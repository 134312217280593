import { styled } from '@mui/material/styles';

import { IconColorProps } from './Icon.types';

export const IconContainer = styled('div')<IconColorProps>`
  display: flex;
  align-items: center;
  transform: ${({ transform = 1, rotate = 0 }) => `scale(${transform}) rotate(${rotate}deg)`};

  & svg path {
    ${({ fill }) => fill && `fill: ${fill}`};
    ${({ stroke }) => stroke && `stroke: ${stroke}`};
  }
`;
