import { ReactElement, useContext, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import theme from '@theme/index';
import useBreakpoint from '@hooks/useBreakpoint';
import Icon from '@components/Icon';
import Button from '@components/Buttons/Button';
import Drawer from '@components/DrawerMenu';
import ContactUs from '@components/ContactUs';
import Modal from '@components/Modal';
import GlobalSearchBar from '@components/SearchBar/Global';
import FriendsList from '@components/FriendsList';
import EndorsementsList from '@components/EndorsementsList';
import { AuthContext } from '@context/Auth/Auth.context';
import MenuIcon from '@assets/icons/menu.svg';
import UserIcon from '@assets/icons/user.svg';
import UserFilledIcon from '@assets/icons/user-filled.svg';
import Logo from '@assets/images/logo.svg';
import * as Routes from '@constants/routes';

import { CONTACT_FORM_URL } from '@constants/urls';
import { HEADER_HAMBURGER_BUTTON, HEADER_LOGO_BUTTON, HEADER_SEARCH_BAR_NAME, TEST_ID } from './Header.constants';
import { HeaderProps } from './Header.types';
import * as Styles from './Header.styles';

const Header = ({
  isMenuOpen,
  isDrawerOpen,
  isContactUsFormOpen,
  isSearchOpen,
  isFriendsListOpen,
  isEndorsementsListOpen,
  handleMenuOpen,
  handleDrawerMenuOpen,
  handleDrawerClose,
  handleContactUsFormOpen,
  handleContactUsFormClose,
  handleSearchOpen,
  handleSearchClose,
  handleFriendsListClose,
  handleEndorsementsListClose,
}: HeaderProps): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const { isMobileOnly, isMobile, isTablet } = useBreakpoint();
  const hamburgerRef = useRef<HTMLDivElement>(null);

  const isUserLoggedIn = Boolean(user.email);

  const handleNavigate = () => {
    navigate(Routes.DASHBOARD);
  };

  const handleHamburgerClick = () => {
    if (isContactUsFormOpen) {
      handleContactUsFormClose();
      return;
    }

    if (isDrawerOpen) {
      handleDrawerClose();
      return;
    }

    handleDrawerMenuOpen();
  };

  const renderGlobalSearchBar = () => {
    if (isMobile) {
      return (
        <GlobalSearchBar
          label=""
          placeholder="Search"
          name={HEADER_SEARCH_BAR_NAME}
          isSearchOpen={isSearchOpen}
          handleSearchOpen={handleSearchOpen}
          handleSearchClose={handleSearchClose}
          isHeaderVariant
          hasSmallSearchInput
        />
      );
    }

    return (
      <Styles.SearchBarWrapper>
        <GlobalSearchBar
          label=""
          placeholder="Search"
          name={HEADER_SEARCH_BAR_NAME}
          isSearchOpen={isSearchOpen}
          handleSearchOpen={handleSearchOpen}
          handleSearchClose={handleSearchClose}
          isHeaderVariant
          hasSmallSearchInput
        />
      </Styles.SearchBarWrapper>
    );
  };

  return (
    <Styles.Container data-component-locator={TEST_ID}>
      <Styles.ContentContainer>
        <Styles.GroupContainer>
          <Styles.HamburgerWrapper
            ref={hamburgerRef}
            onClick={handleHamburgerClick}
            data-component-locator={HEADER_HAMBURGER_BUTTON}
          >
            <Icon icon={MenuIcon} />
          </Styles.HamburgerWrapper>
          <Styles.LogoWrapper onClick={handleNavigate} data-component-locator={HEADER_LOGO_BUTTON}>
            <Logo />
          </Styles.LogoWrapper>
        </Styles.GroupContainer>
        <Styles.GroupContainer>
          {isTablet && (
            <>
              <Styles.ButtonContainer marginRight={10}>
                <Styles.Link href={Routes.ABOUT} rel="noopener noreferrer nofollow">
                  <Button variant="text" color={theme.colors.black} shouldTranslate>
                    common.header.aboutUs
                  </Button>
                </Styles.Link>
              </Styles.ButtonContainer>
              <Styles.ButtonContainer marginRight={10}>
                <Styles.Link href={Routes.FOR_PROVIDER} rel="noopener noreferrer nofollow">
                  <Button variant="text" color={theme.colors.black} shouldTranslate>
                    common.header.forProviders
                  </Button>
                </Styles.Link>
              </Styles.ButtonContainer>
              <Styles.ButtonContainer marginRight={10}>
                <Styles.Link href={CONTACT_FORM_URL} target="_blank" rel="noopener noreferrer nofollow">
                  <Button variant="text" color={theme.colors.black} shouldTranslate>
                    common.header.contactUs
                  </Button>
                </Styles.Link>
              </Styles.ButtonContainer>
              {user?.isAdmin && (
                <Styles.ButtonContainer marginRight={10}>
                  <Button
                    variant="text"
                    color={theme.colors.black}
                    clickHandler={() => navigate(Routes.ADMIN)}
                    shouldTranslate
                  >
                    common.header.adminPanel
                  </Button>
                </Styles.ButtonContainer>
              )}
              {!isUserLoggedIn && (
                <Styles.ButtonContainer marginX={10}>
                  <Button
                    size="small"
                    variant="outlined"
                    clickHandler={() => navigate(Routes.SIGN_UP)}
                    color={theme.colors.black}
                    shouldTranslate
                  >
                    common.header.signUp
                  </Button>
                </Styles.ButtonContainer>
              )}
            </>
          )}
          {isUserLoggedIn ? (
            <>
              {location.pathname !== Routes.SEARCH && renderGlobalSearchBar()}

              <Styles.IconContainer onClick={handleMenuOpen}>
                <Icon icon={isMenuOpen ? UserFilledIcon : UserIcon} />
              </Styles.IconContainer>
              <FriendsList closeHandler={handleFriendsListClose} isModalOpen={isFriendsListOpen} />
              <EndorsementsList closeHandler={handleEndorsementsListClose} isModalOpen={isEndorsementsListOpen} />
            </>
          ) : (
            <Styles.ButtonContainer ml={10}>
              <Button
                variant="text"
                color={theme.colors.black}
                clickHandler={() => navigate(Routes.LOGIN)}
                shouldTranslate
                isBoldText
              >
                common.header.login
              </Button>
            </Styles.ButtonContainer>
          )}
        </Styles.GroupContainer>
      </Styles.ContentContainer>
      <Drawer
        isOpen={isDrawerOpen}
        hamburgerRef={hamburgerRef}
        handleMenuClose={handleDrawerClose}
        handleLogoClick={handleNavigate}
        handleContactUsFormOpen={handleContactUsFormOpen}
      />
      <Modal
        isOpen={isContactUsFormOpen}
        closeHandler={handleContactUsFormClose}
        hasClose={!isMobileOnly}
        noPadding
        withHeader
      >
        <ContactUs closeHandler={handleContactUsFormClose} />
      </Modal>
    </Styles.Container>
  );
};

export default Header;
