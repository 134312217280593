import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ConnectIcon from '@assets/icons/connect.svg';
import Button from '@components/Buttons/Button';
import useNotification from '@hooks/useNotification';
import { AuthContext } from '@context/Auth/Auth.context';
import { copyProfileLinkString, copyProviderLinkString } from '@utils/user';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import theme from '@theme';

import ProviderOnboardingService from '@services/Provider/ProviderOnbarding/ProviderOnboarding.service';
import { CopyLinkProps } from './CopyLink.types';

const CopyLink: FC<CopyLinkProps> = ({ size = 'medium', children = undefined }) => {
  const { addNotification } = useNotification();
  const [text, setText] = useState('');
  const { t } = useTranslation();

  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user.role === 'ROLE_PROVIDER') {
      ProviderOnboardingService.getProviderLink(user?.profile?.providerId).then(({ data }: Record<string, any>) => {
        setText(copyProviderLinkString(data));
      });
    } else {
      setText(copyProfileLinkString(user.id));
    }
  }, [user]);

  return (
    <CopyToClipboard
      onCopy={() => {
        addNotification(t('common.copy.linkCopiedMessage'));
      }}
      text={text}
    >
      <div>
        <Button
          leftIcon={ConnectIcon}
          variant="outlined"
          size={size}
          color={theme.colors.blue}
          fontVariant="body3"
          clickHandler={() => true}
          shouldTranslate
        >
          {children || 'common.menu.copyLink'}
        </Button>
      </div>
    </CopyToClipboard>
  );
};

export default CopyLink;
