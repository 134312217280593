import { styled, css } from '@mui/material/styles';

import {
  PARENT_AVATAR_SIZE,
  PROVIDER_AVATAR_HEIGHT,
  PROVIDER_AVATAR_RADIUS,
  PROVIDER_AVATAR_WIDTH,
  USER_CARD_MAX_HEIGHT,
  USER_CARD_MIN_HEIGHT,
} from './UserCard.constants';
import { DividerProps, InlineIconWrapperProps, UserCardStyleProps } from './UserCard.types';

export const Card = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  min-height: ${USER_CARD_MIN_HEIGHT}px;
  height: auto;
  max-height: ${USER_CARD_MAX_HEIGHT}px;
  padding: ${({ theme }) => theme.spacing(4)};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.light1};
  border-radius: ${({ theme }) => theme.customShapes.borderRadius.userCard};
  ${({ onClick = undefined }) =>
    onClick &&
    css`
      cursor: pointer;
    `};

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    max-width: none;
  }
`;

export const AvatarWrapper = styled('div')`
  display: flex;

  ${({ onClick = undefined }) =>
    onClick &&
    css`
      cursor: pointer;
    `};
`;

export const Avatar = styled('img')<UserCardStyleProps>`
  display: block;
  width: ${({ $variant }) =>
    $variant === 'parent' || $variant === 'parentInvite' ? `${PARENT_AVATAR_SIZE}px` : `${PROVIDER_AVATAR_WIDTH}px`};
  height: ${({ $variant }) =>
    $variant === 'parent' || $variant === 'parentInvite' ? `${PARENT_AVATAR_SIZE}px` : `${PROVIDER_AVATAR_HEIGHT}px`};
  border: ${({ $variant, theme }) =>
    $variant === 'parent' || $variant === 'parentInvite' ? `2px solid ${theme.colors.green}` : 'none'};
  border-radius: ${({ $variant }) =>
    $variant === 'parent' || $variant === 'parentInvite'
      ? '50%'
      : `0 0 ${PROVIDER_AVATAR_RADIUS}px ${PROVIDER_AVATAR_RADIUS}px`};
  object-fit: cover;
  object-position: center;
  box-sizing: content-box;
  user-select: none;
`;

export const InitialsAvatar = styled(Avatar)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.green};
  color: ${({ theme }) => theme.colors.white};
`;

export const SpecialtiesWrapper = styled('div')`
  height: 22px;
  overflow: hidden;
  line-height: 22px;
  margin: ${({ theme }) => `${theme.spacing(3)} 0 ${theme.spacing(7)}`};

  > span {
    line-height: 22px;
    display: block;
  }
`;

/* export const AgesWrapper = styled('div')`
  height: 16px;
  display: flex;
  padding: 0px;
  margin: ${({ theme }) => `${theme.spacing(3)} 0 ${theme.spacing(0)}`};
`; */

export const Content = styled('div')<UserCardStyleProps>`
  display: flex;
  flex-direction: column;
  padding-left: ${({ theme }) => theme.spacing(5)};
  width: ${({ $variant }) =>
    `calc(100% - ${$variant === 'provider' ? `${PROVIDER_AVATAR_WIDTH}px` : `${PARENT_AVATAR_SIZE}px`})`};
`;

export const InlineWrapper = styled('div')<InlineIconWrapperProps>`
  display: flex;
  align-items: center;
  max-width: ${({ $virtualWidth }) => ($virtualWidth ? `calc(100% - ${$virtualWidth}px)` : '100%')};
`;

export const InlineIconWrapper = styled('div')<InlineIconWrapperProps & UserCardStyleProps>`
  display: inline-flex;
  align-items: center;
  line-height: 0;
  margin-right: ${({ theme }) => theme.spacing(4)};
  ${({ theme, $hasLocations }) => $hasLocations && `max-width: calc(100% - ${theme.spacing(6)});`};
  height: 18px;

  > span {
    display: block;
  }

  :last-child {
    margin-right: 0;
  }

  > div {
    margin-right: ${({ theme }) => theme.spacing(3)};
  }
`;

export const Divider = styled('div')<DividerProps>`
  width: 100%;
  height: 1px;
  background-color: ${({ $isInvisible, theme }) => ($isInvisible ? 'transparent' : theme.colors.light3)};
  margin: ${({ $isInvisible, theme }) => ($isInvisible ? '0' : `${theme.spacing(3)} 0 0`)};
`;

export const NoEndorsementsWrapper = styled('div')`
  line-height: 0;
  padding: ${({ theme }) => `${theme.spacing(2)} 0 ${theme.spacing(1)}`};
`;

export const EndorsedByWrapper = styled('div')`
  display: flex;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing(3)};
  height: 32px;

  & span {
    user-select: none;
  }
`;

export const AccentText = styled('span')`
  color: ${({ theme }) => theme.colors.greyDark3};
`;

export const AvatarGroupWrapper = styled('div')`
  line-height: 0;
  margin-right: ${({ theme }) => theme.spacing(4)};
`;

export const ParentInviteButtons = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: ${({ theme }) => `0 ${theme.spacing(8)} ${theme.spacing(4)} auto`};

  > button:last-child {
    margin-left: ${({ theme }) => theme.spacing(4)};
  }

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    margin-bottom: 0;
  }
`;

export const NoEndorsementsSkeletonWrapper = styled('div')`
  margin-left: ${({ theme }) => theme.spacing(5)};
`;

export const CardTopSection = styled('div')`
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
`;
