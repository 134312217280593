import { AxiosMyEndorsementsProps } from '@services/Provider/Endorsement/Endorsement.types';
import axios from '@utils/api';

import { authConfig } from '../services.utils';
import {
  AxiosFriendProps,
  AxiosSendFriendRequestProps,
  RequestSimpleFriendProps,
  RequestFriendProps,
  RequestMyFriendProps,
  AxiosMutualFriendProps,
  RequestFriendEndorsementsProps,
} from './Friend.types';

class Friend {
  friendEndpoint: string;

  mutualFriendEndpoint: string;

  service: string;

  constructor() {
    this.friendEndpoint = 'friends';
    this.mutualFriendEndpoint = 'mutualFriends';
    this.service = 'users';
  }

  getMyFriends({ page, size, sort }: RequestMyFriendProps): Promise<AxiosFriendProps> {
    return axios.get(`${this.service}/me/${this.friendEndpoint}`, {
      params: {
        page,
        size,
        sort: sort ? `friend.firstName,${sort}` : undefined,
      },
      ...authConfig,
    });
  }

  getFriends({ id, page, size, sort }: RequestFriendProps): Promise<AxiosFriendProps> {
    return axios.get(`${this.service}/${id}/${this.friendEndpoint}`, {
      params: {
        page,
        size,
        sort: sort ? `friend.firstName,${sort}` : undefined,
      },
      ...authConfig,
    });
  }

  getMutualFriends({ id, page, size, sort }: RequestFriendProps): Promise<AxiosMutualFriendProps> {
    return axios.get(`${this.service}/${id}/${this.mutualFriendEndpoint}`, {
      params: {
        page,
        size,
        sort: sort ? `friend.firstName,${sort}` : undefined,
      },
      ...authConfig,
    });
  }

  sendFriendRequest({ userId, friendId }: RequestSimpleFriendProps): Promise<AxiosSendFriendRequestProps> {
    return axios.post(
      `${this.service}/${userId}/${this.friendEndpoint}`,
      {
        friendId,
      },
      authConfig,
    );
  }

  removeFriend({ userId, friendId }: RequestSimpleFriendProps): Promise<void> {
    return axios.delete(`${this.service}/${userId}/${this.friendEndpoint}/${friendId}`, authConfig);
  }

  acceptFriendRequest({ userId, friendId }: RequestSimpleFriendProps): Promise<void> {
    return axios.patch(
      `${this.service}/${userId}/${this.friendEndpoint}/${friendId}`,
      {
        accept: true,
      },
      authConfig,
    );
  }

  declineFriendRequest({ userId, friendId }: RequestSimpleFriendProps): Promise<void> {
    return axios.patch(
      `${this.service}/${userId}/${this.friendEndpoint}/${friendId}`,
      {
        accept: false,
      },
      authConfig,
    );
  }

  getFriendRequests({ id, page, size, sort }: RequestFriendProps): Promise<AxiosFriendProps> {
    return axios.get(`${this.service}/${id}/${this.friendEndpoint}/requests`, {
      params: {
        page,
        size,
        sort: sort ? `friend.firstName,${sort}` : undefined,
      },
      ...authConfig,
    });
  }

  getFriendEndorsements({
    id,
    friendId,
    page,
    size,
    sort,
  }: RequestFriendEndorsementsProps): Promise<AxiosMyEndorsementsProps> {
    return axios.get(`${this.service}/${id}/${this.friendEndpoint}/${friendId}/endorsements`, {
      params: {
        page,
        size,
        sort: sort ? `friend.firstName,${sort}` : undefined,
      },
      ...authConfig,
    });
  }
}

export default new Friend();
