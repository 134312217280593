import { styled } from '@mui/material/styles';

import { colors } from '@theme/colors';

import { LayoutContainerProps, ContentContainerProps } from './Layout.types';

export const Container = styled('div')<LayoutContainerProps>`
  position: relative;
  min-height: 100%;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ background }) => background || colors.white};
`;

export const ContentContainer = styled('main')<ContentContainerProps>`
  height: 100%;
  width: 100%;
  z-index: 1;
  margin: ${({ $alignCenter }) => ($alignCenter ? 'auto' : '0 auto')};
  max-width: ${({ $isFluid, theme }) => ($isFluid ? 'none' : `${theme.breakpoints.values.tablet}px`)};

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    max-width: ${({ $isFluid, theme }) => ($isFluid ? 'none' : `${theme.breakpoints.values.desktop}px`)};
  }
`;

export const HeaderContainer = styled('div')`
  position: relative;
  width: 100%;
`;

export const MenuContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: ${({ theme }) => `${theme.breakpoints.values.tablet}px`};

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    margin: 0 auto;
    max-width: ${({ theme }) => `${theme.breakpoints.values.desktop}px`};
    justify-content: flex-end;
  }
`;

export const AssetsContainer = styled('div')`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
`;
