import { styled } from '@mui/material/styles';

export const Notification = styled('div')`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  padding: ${({ theme }) => theme.spacing(8)};
  width: max-content;
  max-width: 350px;

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    max-width: 500px;
  }
`;
