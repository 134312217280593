import { styled, css } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Formik, Form as FormikForm } from 'formik';

import { FormProps, TextareaProps } from './Common.types';

export const ButtonsContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacing(28)};

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-top: ${({ theme }) => theme.spacing(28)};
  }
`;

export const ButtonContainer = styled('div')`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(12)};
`;

export const BorderWrapper = styled('div')`
  width: 100%;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.light5};
`;

export const BorderWrapperNoPadding = styled('div')`
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.light5};
`;

export const BorderWrapperLocation = styled('div')`
  width: 100%;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.greyDark3};
  position: relative;
`;

export const FormContainer = styled(Formik)`
  .Collapsible__contentOuter {
    overflow: visible !important;
  }
`;

export const Form = styled(FormikForm)<FormProps>`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}
`;

export const InputContainer = styled(Box)`
  width: 100%;
`;

export const LoginContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const CloseButton = styled('div')`
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
`;

export const RoleSwitchContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const RowContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const HiddenImageFile = styled('input')`
  display: none;
`;

export const Textarea = styled('textarea')<TextareaProps>`
  font-family: 'GT-America';
  font-size: ${({ theme }) => theme.typography.body3.fontSize};
  line-height: ${({ theme }) => theme.typography.body3.lineHeight};
  margin-top: ${({ theme }) => theme.spacing(8)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(8)};
  width: ${({ $isFullWidth }) => ($isFullWidth ? '100%' : '240px')};
  resize: none;
  height: 170px;
  border: 1px solid ${({ theme }) => theme.colors.greyDark3};
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};

  :focus {
    outline: 0;
    box-shadow: ${({ theme }) => `0 0 0 1px ${theme.colors.greyDark3}`};
  }
`;
