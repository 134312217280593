import { FC } from 'react';

import { BackdropProps } from './Backdrop.types';
import { TEST_ID } from './Backdrop.constants';
import * as Styles from './Backdrop.styles';

const Backdrop: FC<BackdropProps> = ({ children, isOpen }) => {
  return (
    <Styles.Backdrop data-component-locator={TEST_ID} open={isOpen}>
      {children}
    </Styles.Backdrop>
  );
};

export default Backdrop;
