import { styled } from '@mui/material/styles';

import { ContainerProps } from './TermsPage.types';

export const ContentContainer = styled('div')`
  max-width: 1356px;
  margin: 40px auto;
`;

export const ButtonWrapper = styled('div')<ContainerProps>`
  text-align: ${({ $isLoggedUserView }) => ($isLoggedUserView ? 'center' : 'left')};
`;
