import { styled } from '@mui/material/styles';

export const Menu = styled('menu')`
  position: absolute;
  width: 100%;
  max-width: ${({ theme }) => `${theme.breakpoints.values.tablet}px`};
  z-index: 999;
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(8)}`};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.customShadows.menu};
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  bottom: 0;
  margin: 0;
  list-style-type: none;

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    top: 58px;
    bottom: unset;
    width: 375px;
  }

  ${({ theme }) => `${theme.breakpoints.down('laptop')} and (orientation: landscape)`} {
    overflow: auto;
    height: 100%;
  }
`;

export const MenuItem = styled('li')`
  background-color: ${({ theme }) => theme.colors.white};
  margin: ${({ theme }) => `${theme.spacing(4)} 0`};

  a {
    text-decoration: none;
  }
`;

export const MenuButton = styled('div')``;

export const ElementContainer = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${({ theme }) => `${theme.spacing(6)} ${theme.spacing(9)}`};
  transition: all 0.1s ease-in-out;

  :hover {
    background-color: ${({ theme }) => theme.colors.blueLight};
  }
`;

export const ButtonContainer = styled('div')`
  margin: ${({ theme }) => `${theme.spacing(8)} 0`};
  padding: ${({ theme }) => `0 ${theme.spacing(9)}`};
`;

export const IconContainer = styled('div')`
  width: 30px;
  margin-right: ${({ theme }) => theme.spacing(6)};
`;
