import { styled } from '@mui/material/styles';

export const ButtonWrapper = styled('div')`
  button {
    width: 264px;
  }

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    button {
      width: 328px;
    }
  }
`;
