import { FC } from 'react';

import Icon from '@components/Icon';
import theme from '@theme/index';
import Typography from '@components/Typography';

import Loader from '@components/Loaders/Loader';
import { ButtonProps } from './Button.types';
import { generateFontVariant } from './Button.utils';
import { TEST_ID } from './Button.constants';
import * as Styles from './Button.styles';

const ButtonComponent: FC<ButtonProps> = ({
  type = 'button',
  variant = 'filled',
  size = 'big',
  width = '100%',
  isDisabled = false,
  isBoldText = false,
  clickHandler = () => null,
  shouldTranslate = false,
  isFluid = false,
  transformIcon = 1,
  rotateIcon = 0,
  fontVariant,
  color,
  leftIcon,
  rightIcon,
  children,
  isLoading,
}) => {
  return (
    <Styles.Button
      data-component-locator={TEST_ID}
      disabled={isDisabled}
      type={type}
      onClick={clickHandler}
      disableRipple
      $width={width}
      $buttonVariant={variant}
      $buttonSize={size}
      $color={color}
      $isFluid={isFluid}
    >
      {isLoading && (
        <Styles.LoaderContainer onClick={(e) => e.stopPropagation()}>
          <Loader />
        </Styles.LoaderContainer>
      )}
      <Styles.ContentContainer>
        {leftIcon && (
          <Styles.LeftIconContainer>
            <Icon icon={leftIcon} fill={color || theme.colors.white} transform={transformIcon} rotate={rotateIcon} />
          </Styles.LeftIconContainer>
        )}
        <Typography
          variant={fontVariant || generateFontVariant(size)}
          shouldTranslate={shouldTranslate}
          color={color || 'unset'}
          weight={isBoldText ? 'bold' : 'normal'}
        >
          {children}
        </Typography>
        {rightIcon && (
          <Styles.RightIconContainer>
            <Icon icon={rightIcon} fill={color || theme.colors.white} transform={transformIcon} rotate={rotateIcon} />
          </Styles.RightIconContainer>
        )}
      </Styles.ContentContainer>
    </Styles.Button>
  );
};

export default ButtonComponent;
