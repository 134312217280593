import { Theme, useMediaQuery } from '@mui/material';

const options = {
  noSsr: true,
};

const useBreakpoint = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('laptop'), options);

  const isMobileOnly = useMediaQuery((theme: Theme) => theme.breakpoints.between('mobile', 'tablet'), options);

  const isTabletOnly = useMediaQuery((theme: Theme) => theme.breakpoints.between('tablet', 'laptop'), options);
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.up('smallDesktop'), options);

  const isLaptopOnly = useMediaQuery((theme: Theme) => theme.breakpoints.between('laptop', 'smallDesktop'), options);

  const isSmallDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('smallDesktop'), options);

  const isSmallDesktopOnly = useMediaQuery(
    (theme: Theme) => theme.breakpoints.between('smallDesktop', 'desktop'),
    options,
  );

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('laptop'), options);

  const isDesktopOnly = useMediaQuery((theme: Theme) => theme.breakpoints.up('desktop'), options);

  return {
    isMobileOnly,
    isTabletOnly,
    isLaptopOnly,
    isSmallDesktopOnly,
    isDesktopOnly,
    isMobile,
    isTablet,
    isSmallDesktop,
    isDesktop,
  };
};

export default useBreakpoint;
