import { ReactElement, ChangeEvent, useRef, useState, useEffect, useContext } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Highlighter from 'react-highlight-words';

import theme from '@theme';
import SearchIcon from '@assets/icons/search.svg';
import HistoryIcon from '@assets/icons/history.svg';
import CloseIcon from '@assets/icons/close.svg';
import BackIcon from '@assets/icons/left-arrow.svg';
import Icon from '@components/Icon';
import Typography from '@components/Typography';
import Button from '@components/Buttons/Button';
import useOnClickOutside from '@hooks/useOnClickOutside';
import useDebounce from '@hooks/useDebounce';
import useBreakpoint from '@hooks/useBreakpoint';
import useHideScroll from '@hooks/useHideScroll';
import useSearch from '@hooks/useSearch';
import { generateSrcUrl, getInitials } from '@utils/user';
import { joinWithComma } from '@utils/text';
import { clearFilterParams, generateSearchParams, getNeighborhoods } from '@utils/search';
import { onEnterKeyPressed } from '@utils/keyboard';
import * as Routes from '@constants/routes';
import { AuthContext } from '@context/Auth/Auth.context';
import { RequestProviderFilterSpecification } from '@services/services.types';

import ProviderOnboardingService from '@services/Provider/ProviderOnbarding/ProviderOnboarding.service';
import {
  SEARCH_BAR_TEST_ID,
  SEARCH_ICON_TEST_ID,
  SEARCH_INPUT_MOBILE_TEST_ID,
  SEARCH_INPUT_TEST_ID,
} from '../SearchBar.constants';
import * as SearchStyles from '../SearchBar.styles';
import { GlobalSearchBarProps } from './GlobalSearchBar.types';
import {
  ADDITIONAL_SERVICE_TYPE,
  CATEGORY_TYPE,
  MAX_VISIBLE_PARENTS_COUNT,
  MAX_VISIBLE_PROVIDERS_COUNT,
  PROVIDER_TYPE_TYPE,
  SEARCH_MAX_LENGTH,
  SEARCH_MIN_LENGTH,
  SPECIALTY_TYPE,
  TIMEOUT_DURATION,
} from './GlobalSearchBar.constants';
import * as Styles from './GlobalSearchBar.styles';

const GlobalSearchBar = ({
  label,
  name,
  placeholder,
  selectedNeighborhoods = [],
  isSearchOpen = false,
  handleSearchOpen = () => {},
  handleSearchClose = () => {},
  isHeaderVariant = false,
  hasSmallSearchInput = false,
}: GlobalSearchBarProps): ReactElement => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoint();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { user } = useContext(AuthContext);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const mobileInputRef = useRef<HTMLInputElement>(null);

  const [searchQuery, setSearchQuery] = useState(searchParams.get('query') || '');
  const debouncedSearchQuery = useDebounce(searchQuery.toLowerCase());
  const hasEmptySearchQuery = debouncedSearchQuery.length === 0;

  const {
    isLoading,
    popularCategories,
    recentSearches,
    providersSearchResults,
    parentsSearchResults,
    categorySearchResults,
    providerTypeSearchResults,
    specialtySearchResults,
    additionalServiceSearchResults,
  } = useSearch({
    isUserLoggedIn: Boolean(user.email),
    searchQuery: debouncedSearchQuery,
  });

  const shouldShowSeeAllProviders = providersSearchResults.length > MAX_VISIBLE_PROVIDERS_COUNT;
  const hasProvidersSearchResults = providersSearchResults.length > 0;

  const shouldShowSeeAllParents = parentsSearchResults.length > MAX_VISIBLE_PARENTS_COUNT;
  const hasParentsSearchResults = parentsSearchResults.length > 0;

  const hasCategorySearchResults = categorySearchResults.length > 0;
  const hasProviderTypeSearchResults = providerTypeSearchResults.length > 0;
  const hasSpecialtySearchResults = specialtySearchResults.length > 0;
  const hasAdditionalServiceSearchResults = additionalServiceSearchResults.length > 0;

  const [isDataListOpen, setIsDataListOpen] = useState(false);

  const hasRecentSearches = recentSearches.length > 0;

  const hasNoResults =
    !hasCategorySearchResults &&
    !hasProviderTypeSearchResults &&
    !hasParentsSearchResults &&
    !hasProvidersSearchResults &&
    !hasEmptySearchQuery &&
    !hasSpecialtySearchResults &&
    !hasAdditionalServiceSearchResults;
  const iconColor = isMobile ? theme.colors.white : theme.colors.greyDark2;
  const textColor = isMobile ? theme.colors.white : theme.colors.black;
  const noResultsIconColor = hasNoResults ? theme.colors.greyDark2 : iconColor;
  const noResultsTextColor = hasNoResults ? theme.colors.black : textColor;

  const blurInput = () => inputRef.current && inputRef.current.blur();
  const focusMobileInput = () => mobileInputRef.current && mobileInputRef.current.focus();
  const focusTimeout = () => setTimeout(() => focusMobileInput, TIMEOUT_DURATION);

  const openDataList = () => setIsDataListOpen(true);

  const handleSearchOnMouseDown = () => {
    if (isHeaderVariant) {
      handleSearchOpen();
      return;
    }

    openDataList();
  };

  const closeDataList = () => {
    setIsDataListOpen(false);
    if (isHeaderVariant) {
      handleSearchClose();
    }
  };

  const clearSearchQuery = () => {
    setSearchQuery('');
    setSearchParams(generateSearchParams({ query: '' }, searchParams).toString(), { replace: true });
  };

  const navigateTo = (
    pathname: string,
    params?: Partial<RequestProviderFilterSpecification>,
    isParentsSearch = false,
  ) => {
    closeDataList();

    const isSearchPageNavigation = pathname === Routes.SEARCH;
    const isSearchPageWithParamsNavigation = pathname === Routes.SEARCH && params !== undefined;
    const state = {
      isParentsSearch,
      searchQuery: params?.query || '',
    };
    const neighborhoods = selectedNeighborhoods.length > 0 ? selectedNeighborhoods : getNeighborhoods(user);
    const userNeighborhoods = neighborhoods.map(({ neighborhoodName }) => neighborhoodName);

    const updatedParams = { neighborhoods: userNeighborhoods, ...params };

    if (isSearchPageWithParamsNavigation) {
      const clearedSearchParams = clearFilterParams(searchParams);

      navigate(
        { pathname, search: generateSearchParams(updatedParams, clearedSearchParams).toString() },
        {
          state,
        },
      );
      return;
    }

    if (isSearchPageNavigation) {
      navigate(pathname, {
        state,
      });
      return;
    }

    navigate(pathname);
  };

  const handleClickOutside = () => {
    if (isMobile) {
      return;
    }

    closeDataList();
  };

  useOnClickOutside(wrapperRef, handleClickOutside);
  useHideScroll(isMobile && isDataListOpen, [isMobile, isDataListOpen]);

  const handleSearchQuery = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const valueLength = value.length;

    if (valueLength <= SEARCH_MAX_LENGTH) {
      setSearchQuery(value);
    }
  };

  const handleSearchOnResultClick = (type: string, resultName: string) => {
    const navigateCallback = {
      [PROVIDER_TYPE_TYPE]: () => navigateTo(Routes.SEARCH, { query: '', providerTypes: [resultName] }),
      [CATEGORY_TYPE]: () => navigateTo(Routes.SEARCH, { query: resultName }),
      [SPECIALTY_TYPE]: () => navigateTo(Routes.SEARCH, { query: resultName }),
      [ADDITIONAL_SERVICE_TYPE]: () => navigateTo(Routes.SEARCH, { query: resultName }),
    }[type];

    if (navigateCallback !== undefined) {
      navigateCallback();
    }
  };

  useEffect(() => {
    if (isDataListOpen && mobileInputRef.current) {
      focusTimeout();
    }
  }, [mobileInputRef, isMobile, isDataListOpen]);

  useEffect(() => {
    setSearchQuery(searchParams.get('query') || '');
  }, [searchParams]);

  const renderRecentSearches = () => (
    <>
      <Styles.HeadingWrapper>
        <Typography variant="body5" weight="bold" color={textColor} shouldTranslate>
          common.globalSearch.headings.recentSearches
        </Typography>
      </Styles.HeadingWrapper>
      <Styles.List>
        {recentSearches.map(({ searchQuery: value }, index) => (
          <Styles.ListItem
            key={`recent-search-${index.toString()}`}
            onClick={() => navigateTo(Routes.SEARCH, { query: value })}
          >
            <Styles.ListIcon>
              <Icon icon={HistoryIcon} fill={iconColor} />
            </Styles.ListIcon>
            <Typography variant="body2" color={textColor} shouldTranslate>
              {value}
            </Typography>
          </Styles.ListItem>
        ))}
      </Styles.List>
    </>
  );

  const renderResult = (id: number, resultName: string, type: string) => (
    <Styles.ListItem key={`${type}-${resultName}-${id}`} onClick={() => handleSearchOnResultClick(type, resultName)}>
      <Styles.ListIcon>
        <Icon icon={SearchIcon} fill={theme.colors.greyDark2} />
      </Styles.ListIcon>
      <Typography variant="body3" shouldTranslate>
        <Highlighter searchWords={[debouncedSearchQuery]} textToHighlight={resultName} />
      </Typography>
      <Styles.TypeWrapper>
        <Typography variant="body5" color={theme.colors.greyDark3} shouldTranslate>
          {type}
        </Typography>
      </Styles.TypeWrapper>
    </Styles.ListItem>
  );

  const renderResults = () => (
    <Styles.List>
      {hasAdditionalServiceSearchResults &&
        additionalServiceSearchResults.map(({ name: resultName }, index) =>
          renderResult(index, resultName, ADDITIONAL_SERVICE_TYPE),
        )}
      {hasCategorySearchResults &&
        categorySearchResults.map(({ id, categoryName }) => renderResult(id, categoryName, CATEGORY_TYPE))}
      {hasProviderTypeSearchResults &&
        providerTypeSearchResults.map(({ id, providerTypeName }) =>
          renderResult(id, providerTypeName, PROVIDER_TYPE_TYPE),
        )}
      {hasSpecialtySearchResults &&
        specialtySearchResults.map(({ name: resultName }, index) => renderResult(index, resultName, SPECIALTY_TYPE))}
    </Styles.List>
  );

  const renderPopularCategories = () => (
    <>
      <Styles.HeadingWrapper>
        <Typography variant="body5" weight="bold" color={noResultsTextColor} shouldTranslate>
          common.globalSearch.headings.popularCategories
        </Typography>
      </Styles.HeadingWrapper>
      <Styles.List>
        {popularCategories.map(({ id, categoryName }) => (
          <Styles.ListItem
            key={`popular-category-${id}`}
            onClick={() => navigateTo(Routes.SEARCH, { query: categoryName })}
            $hasPopularCategories
          >
            <Styles.ListIcon>
              <Icon icon={SearchIcon} fill={noResultsIconColor} />
            </Styles.ListIcon>
            <Typography variant="body2" color={noResultsTextColor} shouldTranslate noWrap>
              {categoryName}
            </Typography>
          </Styles.ListItem>
        ))}
      </Styles.List>
    </>
  );

  const handleProviderNavigate = (id: string) => {
    if (id) {
      ProviderOnboardingService.getProviderLink(id)
        .then(({ data }: Record<string, any>) => {
          navigate(`${Routes.PROVIDERS}/${data || id}`);
        })
        .catch(() => navigate(`${Routes.PROVIDERS}/${id}`));
    }
  };

  const renderProviders = () => (
    <>
      <Styles.HeadingWrapper>
        <Typography variant="body5" weight="bold" shouldTranslate>
          common.globalSearch.headings.providers
        </Typography>
      </Styles.HeadingWrapper>
      <Styles.List $isHeaderVariant={isHeaderVariant || isMobile}>
        {providersSearchResults.map(({ providerId, name: providerName, providerTypes, imageUrl, link }, index) =>
          index === MAX_VISIBLE_PROVIDERS_COUNT ? null : (
            <Styles.ListItem key={`provider-${providerId}`} onClick={() => handleProviderNavigate(providerId)}>
              {imageUrl ? (
                <Styles.ProviderAvatar as="img" src={generateSrcUrl(imageUrl)} $hasAvatar />
              ) : (
                <Styles.ProviderAvatar>
                  <Typography variant="h6" color={theme.colors.white} weight="bold" noWrap>
                    {getInitials(providerName)}
                  </Typography>
                </Styles.ProviderAvatar>
              )}
              <Typography variant="body3" noWrap>
                <Highlighter searchWords={[debouncedSearchQuery]} textToHighlight={providerName} />
              </Typography>
              {providerTypes?.length > 0 && (
                <>
                  <Styles.HintDot />
                  <Styles.Ellipsis>
                    <Typography variant="body5" color={theme.colors.greyDark3}>
                      <Highlighter searchWords={[debouncedSearchQuery]} textToHighlight={providerTypes[0]} />
                    </Typography>
                  </Styles.Ellipsis>
                </>
              )}
            </Styles.ListItem>
          ),
        )}
      </Styles.List>
      {shouldShowSeeAllProviders && (
        <Styles.SeeAllWrapper>
          <Button
            variant="text"
            fontVariant="h6"
            clickHandler={() => navigateTo(Routes.SEARCH, { query: searchQuery })}
            isBoldText
            isFluid
            shouldTranslate
          >
            common.globalSearch.seeAll.providers
          </Button>
        </Styles.SeeAllWrapper>
      )}
    </>
  );

  const renderParents = () => (
    <>
      <Styles.HeadingWrapper>
        <Typography variant="body5" weight="bold" shouldTranslate>
          common.globalSearch.headings.parents
        </Typography>
      </Styles.HeadingWrapper>
      <Styles.List $isHeaderVariant={isHeaderVariant}>
        {parentsSearchResults.map(
          ({ profileId, firstName, lastName, neighborhoods: parentNeighborhoods, imageUrl }, index) =>
            index >= MAX_VISIBLE_PARENTS_COUNT ? null : (
              <Styles.ListItem key={`parent-${profileId}`} onClick={() => navigateTo(`${Routes.PARENTS}/${profileId}`)}>
                {imageUrl ? (
                  <Styles.ParentAvatar as="img" src={imageUrl} $hasAvatar />
                ) : (
                  <Styles.ParentAvatar>
                    <Typography variant="h6" weight="bold" color={theme.colors.white} noWrap>
                      {getInitials(firstName, lastName)}
                    </Typography>
                  </Styles.ParentAvatar>
                )}
                <Typography variant="body3" noWrap>
                  {firstName} <Highlighter searchWords={[debouncedSearchQuery]} textToHighlight={lastName} />
                </Typography>
                {parentNeighborhoods.length > 0 && (
                  <>
                    <Styles.HintDot />
                    <Typography variant="body5" color={theme.colors.greyDark3} noWrap>
                      {joinWithComma(parentNeighborhoods.map(({ neighborhoodName }) => neighborhoodName))}
                    </Typography>
                  </>
                )}
              </Styles.ListItem>
            ),
        )}
      </Styles.List>
      {shouldShowSeeAllParents && (
        <Styles.SeeAllWrapper>
          <Button
            variant="text"
            fontVariant="h6"
            clickHandler={() => navigateTo(Routes.SEARCH, { query: searchQuery }, true)}
            isBoldText
            isFluid
            shouldTranslate
          >
            common.globalSearch.seeAll.parents
          </Button>
        </Styles.SeeAllWrapper>
      )}
    </>
  );

  const renderNoResults = () => {
    if (debouncedSearchQuery.length < SEARCH_MIN_LENGTH) {
      return (
        <Styles.HeadingWrapper $isLowerCase>
          <Typography variant="body3">{t('common.fields.minSearchLength', { length: SEARCH_MIN_LENGTH })}</Typography>
        </Styles.HeadingWrapper>
      );
    }

    return (
      <Styles.HeadingWrapper $isLowerCase>
        <Typography variant="body3" shouldTranslate>
          common.globalSearch.noResults
        </Typography>{' '}
        <Typography variant="body3" weight="bold">
          {debouncedSearchQuery}
        </Typography>
      </Styles.HeadingWrapper>
    );
  };

  const renderData = () => (
    <>
      {hasNoResults && !isLoading && renderNoResults()}
      {hasEmptySearchQuery && hasRecentSearches && renderRecentSearches()}
      {(hasEmptySearchQuery || hasNoResults) && renderPopularCategories()}
      {(hasCategorySearchResults ||
        hasProviderTypeSearchResults ||
        hasSpecialtySearchResults ||
        hasAdditionalServiceSearchResults) &&
        renderResults()}
      {hasProvidersSearchResults && renderProviders()}
      {hasParentsSearchResults && renderParents()}
    </>
  );

  const renderDataList = () => (
    <SearchStyles.DataListWrapper $hasSmallSearchInput={hasSmallSearchInput}>
      <Styles.Wrapper>{renderData()}</Styles.Wrapper>
    </SearchStyles.DataListWrapper>
  );

  const renderSearchInput = (isSmall: boolean) => (
    <SearchStyles.SearchInput
      autoComplete="off"
      ref={inputRef}
      type="text"
      id={name}
      name={name}
      placeholder={t(placeholder)}
      value={searchQuery}
      onClick={isMobile ? openDataList : undefined}
      onMouseDown={isMobile ? undefined : handleSearchOnMouseDown}
      onChange={handleSearchQuery}
      onKeyUp={({ key }) => {
        if (searchQuery.length > 0 && searchQuery.length < SEARCH_MIN_LENGTH) {
          return;
        }

        onEnterKeyPressed(key, () => {
          blurInput();
          navigateTo(Routes.SEARCH, { query: searchQuery });
        });
      }}
      data-component-locator={SEARCH_INPUT_TEST_ID}
      $hasSmallSearchInput={isSmall}
    />
  );

  const renderMobileSearchInput = (isSmall: boolean) => (
    <SearchStyles.SearchInput
      autoComplete="off"
      ref={mobileInputRef}
      type="text"
      id={`mobile-${name}`}
      name={`mobile-${name}`}
      placeholder={t(placeholder)}
      value={searchQuery}
      onMouseDown={isHeaderVariant ? handleSearchOpen : openDataList}
      onChange={handleSearchQuery}
      onKeyUp={({ key }) => {
        if (searchQuery.length > 0 && searchQuery.length < SEARCH_MIN_LENGTH) {
          return;
        }

        onEnterKeyPressed(key, () => {
          blurInput();
          navigateTo(Routes.SEARCH, { query: searchQuery });
        });
      }}
      data-component-locator={SEARCH_INPUT_MOBILE_TEST_ID}
      $hasSmallSearchInput={isSmall}
      autoFocus
      tabIndex={-1}
    />
  );

  const renderMobileView = () =>
    createPortal(
      <Styles.MobileSearchMask>
        <SearchStyles.SearchWrapper>
          <SearchStyles.IconWrapper onClick={closeDataList}>
            <Icon icon={BackIcon} fill={theme.colors.white} />
          </SearchStyles.IconWrapper>
          <Styles.MobileSearchInputWrapper>{renderMobileSearchInput(false)}</Styles.MobileSearchInputWrapper>
          {hasEmptySearchQuery ? (
            <Styles.MobileSearchDataWrapper $hasEmptySearchQuery>
              {hasRecentSearches && renderRecentSearches()}
              {renderPopularCategories()}
            </Styles.MobileSearchDataWrapper>
          ) : (
            <Styles.MobileSearchDataWrapper>{renderData()}</Styles.MobileSearchDataWrapper>
          )}
          {!hasEmptySearchQuery && (
            <SearchStyles.ClearIconWrapper onClick={clearSearchQuery}>
              <Icon icon={CloseIcon} fill={theme.colors.white} />
            </SearchStyles.ClearIconWrapper>
          )}
        </SearchStyles.SearchWrapper>
      </Styles.MobileSearchMask>,
      document.body,
    );

  if (isMobile && isHeaderVariant) {
    return (
      <>
        <Styles.IconSearchWrapper onClick={handleSearchOpen}>
          <Icon icon={SearchIcon} />
        </Styles.IconSearchWrapper>
        {isSearchOpen && renderMobileView()}
      </>
    );
  }

  return (
    <SearchStyles.Wrapper ref={wrapperRef} data-component-locator={SEARCH_BAR_TEST_ID}>
      <SearchStyles.Label htmlFor={name}>
        <Typography variant="body2" weight="bold" shouldTranslate>
          {label}
        </Typography>
      </SearchStyles.Label>
      <SearchStyles.SearchWrapper $hasSmallSearchInput={hasSmallSearchInput}>
        <SearchStyles.IconWrapper onClick={openDataList} data-component-locator={SEARCH_ICON_TEST_ID}>
          <Icon icon={SearchIcon} />
        </SearchStyles.IconWrapper>
        {isDataListOpen && isMobile ? renderMobileView() : renderSearchInput(hasSmallSearchInput)}
        {(isDataListOpen || isSearchOpen) && !isMobile && renderDataList()}
        {!hasEmptySearchQuery && (
          <SearchStyles.ClearIconWrapper onClick={clearSearchQuery}>
            <Icon icon={CloseIcon} fill={theme.colors.greyDark3} />
          </SearchStyles.ClearIconWrapper>
        )}
      </SearchStyles.SearchWrapper>
    </SearchStyles.Wrapper>
  );
};

export default GlobalSearchBar;
