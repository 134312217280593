const REFERRAL_PARAM = 'referral';
const EMAIL_PARAM = 'email';
const UUID_PARAM = 'uuid';
const ACTIVATION_CODE_PARAM = 'activationCode';
const RESET_PASSWORD_CODE_PARAM = 'resetPasswordCode';

export const URL_PARAMS = {
  REFERRAL: REFERRAL_PARAM,
  EMAIL: EMAIL_PARAM,
  UUID: UUID_PARAM,
  ACTIVATION_CODE: ACTIVATION_CODE_PARAM,
  RESET_PASSWORD_CODE: RESET_PASSWORD_CODE_PARAM,
};
