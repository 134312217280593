import { createContext } from 'react';

import { AuthContextProps } from './Auth.types';
import { initialUserData } from './Auth.utils';

export const AuthContext = createContext<AuthContextProps>({
  updateToken: () => {},
  user: initialUserData,
  updateUser: () => {},
  refreshProfile: () => {},
  logout: () => {},
  setCurrentLocation: () => {},
  setCurrentProviderLocation: () => {},
  shouldRefreshFriends: false,
  refreshFriends: () => {},
  shouldRefreshEndorsements: false,
  refreshEndorsements: () => {},
  currentLocation: null,
  hasNoNeighborhoods: false,
  setHasNoNeighborhoods: () => {},
});
