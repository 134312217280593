export const TEST_ID = 'search-page';
export const RESULTS_SECTION_TEST_ID = `${TEST_ID}-results-section`;
export const FILTER_BUTTON_TEST_ID = `${TEST_ID}-mobile-filter-button`;
export const FILTER_MENU_TEST_ID = `${TEST_ID}-mobile-filter-menu`;
export const PROVIDERS_CARDS_CONTAINER_TEST_ID = `${TEST_ID}-providers-cards-container`;
export const PARENTS_CARDS_CONTAINER_TEST_ID = `${TEST_ID}-providers-cards-container`;

export const PAGE_TITLE = 'Search';
export const FILTER_PILL_SCALE = 0.75;
export const DEFAULT_SORT = 'friends,desc';
export const DEFAULT_PROVIDERS = 'endorsements,desc';
export const NEIGHBORHOODS_FILTER = 'neighborhoods';
export const QUERY_FILTER = 'query';
