import { styled } from '@mui/material/styles';

export const Container = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: ${({ theme }) => `${theme.spacing(8)} ${theme.spacing(12)}`};
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down('tablet')} {
    & > div:first-child {
      padding: ${({ theme }) => `${theme.spacing(8)} 0 ${theme.spacing(10)}`};
    }
  }

  ${({ theme }) => theme.breakpoints.up('tablet')} {
    height: 100%;
  }

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    max-width: 836px;
    padding: ${({ theme }) => `${theme.spacing(62)} ${theme.spacing(77)}`};
  }
`;

export const DescriptionWrapper = styled('div')`
  margin: ${({ theme }) => `${theme.spacing(7)} 0 ${theme.spacing(8)}`};

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    margin: ${({ theme }) => `0 0 ${theme.spacing(8)}`};
  }
`;

export const InputsContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const ButtonsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(8)};

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.spacing(24)};
  }
`;

export const ConfirmModalWrapper = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 326px;
  padding: ${({ theme }) => `${theme.spacing(58)} ${theme.spacing(12)} ${theme.spacing(30)}`};
`;

export const ConfirmModalIconWrapper = styled('div')`
  display: flex;
  justify-content: center;
`;

export const IconWrapper = styled('div')`
  position: absolute;
  top: -76px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 152px;
  height: 152px;
  border-radius: 50%;
  margin-top: 0;
  background-color: ${({ theme }) => theme.colors.blueDark};
  box-shadow: ${({ theme }) => theme.customShadows.profilePicture};
  user-select: none;

  &::before {
    content: none;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    position: absolute;
    inset: 0 0 0 -4px;
    margin: auto;
    z-index: -1;
    background-color: ${({ theme }) => theme.colors.green};
    border-radius: 50%;
  }

  ${({ theme }) => theme.breakpoints.up('tablet')} {
    align-self: flex-start;
  }
`;
