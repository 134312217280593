import { styled } from '@mui/material/styles';

import { HEADER_HEIGHT } from '@components/Headers/Header/Header.constants';

import { USER_LIST_MAX_WIDTH } from './UserList.constants';

export const ListOnlyContainer = styled('div')``;

export const Container = styled('div')`
  position: relative;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.light4};

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    min-height: calc(100vh - ${HEADER_HEIGHT}px);
    background-color: transparent;
  }
`;

export const Header = styled('header')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 10;
  width: 100%;
  height: 48px;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    position: relative;
    margin: 0;
  }
`;

export const ContentWrapper = styled('div')`
  padding: ${({ theme }) => `${theme.spacing(24)} ${theme.spacing(12)} ${theme.spacing(20)}`};
  margin-top: ${({ theme }) => theme.spacing(8)};

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    width: 100%;
    min-height: 90vh;
    padding: ${({ theme }) => `${theme.spacing(60)} ${theme.spacing(82)}`};
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const ListWrapper = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(8)};

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    max-width: ${USER_LIST_MAX_WIDTH}px;
  }
`;

export const TabsWrapper = styled('div')`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(8)};

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    margin-top: ${({ theme }) => theme.spacing(15)};
  }
`;

export const UserCardWrapper = styled('div')`
  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }
`;

export const ContentFiller = styled('div')`
  ${({ theme }) => theme.breakpoints.up('laptop')} {
    width: ${USER_LIST_MAX_WIDTH}px;
  }
`;

export const CenterWrapper = styled('div')`
  text-align: center;
`;

export const InlineTitleWrapper = styled('div')`
  display: inline-flex;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(13)};

  h5:first-child {
    margin-right: ${({ theme }) => theme.spacing(4)};
  }

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    padding-bottom: ${({ theme }) => theme.spacing(5)};
    border-bottom: 1px solid ${({ theme }) => theme.colors.greyDark4};
  }
`;

export const MutualFriendsWrapper = styled('span')`
  margin-left: ${({ theme }) => theme.spacing(4)};
  line-height: 0;
`;

export const ModalTitleWrapper = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

export const CloseIconWrapper = styled('div')`
  position: absolute;
  right: 0;
  padding-right: ${({ theme }) => theme.spacing(14)};
`;
