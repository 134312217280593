import { FC } from 'react';

import { CircularProgress } from '@mui/material';

import * as Styles from './Loader.styles';

const Loader: FC = () => (
  <Styles.BoxContainer
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="100%"
    data-component-locator="loader-component"
  >
    <CircularProgress color="secondary" />
  </Styles.BoxContainer>
);

export default Loader;
