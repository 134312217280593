import { Button as MuiButton } from '@mui/material';
import { styled, css } from '@mui/material/styles';

import { ButtonStyleProps } from './Button.types';
import { generateButtonPadding } from './Button.utils';

export const Button = styled(MuiButton)<ButtonStyleProps>`
  text-transform: none;
  position: relative;
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  color: ${({ theme }) => theme.colors.white};
  width: ${({ $width }) => $width};
  min-height: 40px;
  padding: ${({ $buttonSize, theme }) => `${theme.spacing(generateButtonPadding($buttonSize))} ${theme.spacing(8)}`};

  ${({ $isFluid }) =>
    $isFluid &&
    css`
      min-width: fit-content;
      width: fit-content;
    `};

  ${({ $buttonVariant, theme }) =>
    $buttonVariant === 'filled' &&
    css`
      background-color: ${theme.colors.blue};
      color: ${theme.colors.white};

      :disabled {
        background-color: ${theme.colors.blueSubtle};
        border: 1px solid transparent;
        color: ${theme.colors.greyDark3};
      }

      :hover,
      :active {
        background-color: ${theme.colors.blueDark};
      }
    `};

  ${({ $buttonVariant, $color, theme }) =>
    $buttonVariant === 'outlined' &&
    css`
      background-color: transparent;
      border: 1px solid ${$color || theme.colors.blue};
      color: ${theme.colors.blue};

      :disabled {
        border-color: ${$color || theme.colors.greyDark3};
        color: ${theme.colors.greyDark3};
        opacity: 0.6;
      }

      :hover,
      :active {
        background-color: ${theme.colors.blueSubtle};
        border-color: ${$color || theme.colors.blue};
      }
    `};

  ${({ $buttonVariant, theme }) =>
    ($buttonVariant === 'text' || $buttonVariant === 'textBox') &&
    css`
      background-color: transparent;
      border: none;
      color: ${theme.colors.blue};
      padding: ${theme.spacing(1)};

      :disabled {
        color: ${theme.colors.greyDark3};
      }

      :hover,
      :active {
        background-color: unset;
      }
    `};

  ${({ $buttonVariant, $buttonSize, theme }) =>
    $buttonVariant === 'textBox' &&
    css`
      padding: ${theme.spacing(generateButtonPadding($buttonSize))} ${theme.spacing(8)};
    `};
`;

export const LoaderContainer = styled('div')`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  pointer-events: none;
`;

export const ContentContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LeftIconContainer = styled('div')`
  margin-right: ${({ theme }) => theme.spacing(7)};
`;

export const RightIconContainer = styled('div')`
  margin-left: ${({ theme }) => theme.spacing(7)};
`;
