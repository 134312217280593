import { FC, lazy, useContext } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';

import useQuery from '@hooks/useQuery';
import { AuthContext } from '@context/Auth/Auth.context';
import * as RouteNames from '@constants/routes';
import TermsPage from '@pages/TermsPage';

interface RouteProps {
  path: string;
  element: JSX.Element;
  guard: ((element: JSX.Element, role: string) => JSX.Element) | null;
}

const HomePage = lazy(() => import('@pages/Home'));
const SuccessPage = lazy(() => import('@pages/SuccessPage'));
const SignUpPage = lazy(() => import('@pages/SignUp'));
const LoginPage = lazy(() => import('@pages/Login'));
const ConfirmAccountPage = lazy(() => import('@pages/ConfirmAccount'));
const AddProfilePage = lazy(() => import('@pages/AddProfile'));
const AddProvider = lazy(() => import('@pages/AddProvider'));
const EditNeighborhoodsPage = lazy(() => import('@pages/EditNeighborhoods'));
const ForgotPasswordPage = lazy(() => import('@pages/ForgotPassword'));
const ResetPasswordPage = lazy(() => import('@pages/ResetPassword'));
const ProviderPage = lazy(() => import('@pages/Provider'));
const ProfilePage = lazy(() => import('@pages/Profile'));
const ProviderProfilePage = lazy(() => import('@pages/ProviderPage'));
const ParentPage = lazy(() => import('@pages/Parent'));
const DashboardPage = lazy(() => import('@pages/Dashboard'));
const SearchPage = lazy(() => import('@pages/Search'));
const SettingsPage = lazy(() => import('@pages/Settings'));
const NotFoundPage = lazy(() => import('@pages/NotFound'));
const AdminPanelPage = lazy(() => import('@pages/AdminPanel'));
const AboutPage = lazy(() => import('@pages/AboutPage'));
const ForProviderPage = lazy(() => import('@pages/ForProviderPage'));
const PrivacyPage = lazy(() => import('@pages/PrivacyPage'));

const RoleGuard: FC<any> = ({ children, userRoles, role }) => {
  const navigate = useNavigate();

  if (role && !userRoles.find((userRole: string) => userRole === role)) {
    navigate(RouteNames.DASHBOARD);
  }

  return <>{children}</>;
};

const roleGuard = (userRoles: string[]) => (element: JSX.Element, role: string) =>
  (
    <RoleGuard role={role} userRoles={userRoles}>
      {element}
    </RoleGuard>
  );

export const routes: Array<RouteProps> = [
  { path: RouteNames.HOME, element: <HomePage />, guard: null },
  { path: RouteNames.SUCCESS, element: <SuccessPage />, guard: roleGuard(['ROLE_PROVIDER']) },
  { path: RouteNames.SIGN_UP, element: <SignUpPage />, guard: null },
  { path: RouteNames.LOGIN, element: <LoginPage />, guard: null },
  {
    path: RouteNames.CONFIRM_ACCOUNT,
    element: <ConfirmAccountPage />,
    guard: null,
  },
  {
    path: RouteNames.FORGOT_PASSWORD,
    element: <ForgotPasswordPage />,
    guard: null,
  },
  {
    path: RouteNames.ABOUT,
    element: <AboutPage />,
    guard: null,
  },
  {
    path: RouteNames.FOR_PROVIDER,
    element: <ForProviderPage />,
    guard: null,
  },
  {
    path: RouteNames.PRIVACY,
    element: <PrivacyPage />,
    guard: null,
  },
  {
    path: RouteNames.TERMS,
    element: <TermsPage />,
    guard: null,
  },
  {
    path: RouteNames.RESET_PASSWORD,
    element: <ResetPasswordPage />,
    guard: null,
  },
  {
    path: RouteNames.ADD_PROFILE,
    element: <AddProfilePage />,
    guard: roleGuard(['ROLE_PROVIDER', 'ROLE_PARENT']),
  },
  { path: RouteNames.ADD_PROVIDER, element: <AddProvider />, guard: roleGuard(['ROLE_PROVIDER']) },
  {
    path: RouteNames.EDIT_NEIGHBORHOODS,
    element: <EditNeighborhoodsPage />,
    guard: roleGuard(['ROLE_PARENT', 'ROLE_ADMIN']),
  },
  { path: RouteNames.PROFILE, element: <ProfilePage />, guard: roleGuard(['ROLE_PARENT', 'ROLE_ADMIN']) },
  {
    path: RouteNames.PROVIDER_PROFILE,
    element: <ProviderProfilePage />,
    guard: roleGuard(['ROLE_PROVIDER']),
  },
  { path: RouteNames.DASHBOARD, element: <DashboardPage />, guard: null },
  { path: RouteNames.SEARCH, element: <SearchPage />, guard: null },
  { path: RouteNames.SETTINGS, element: <SettingsPage />, guard: null },
  { path: `${RouteNames.PARENTS}/:id`, element: <ParentPage />, guard: null },
  {
    path: `${RouteNames.PROVIDERS}/:id`,
    element: <ProviderPage />,
    guard: null,
  },
  {
    path: RouteNames.ADMIN,
    element: <AdminPanelPage />,
    guard: roleGuard(['ROLE_ADMIN']),
  },
];

const RouterComponent: FC = () => {
  const {
    user: { role },
  } = useContext(AuthContext);

  return (
    <Routes>
      {routes.map(({ element, path, guard }) => (
        <Route key={path} path={path} element={guard ? guard(element, role) : element} />
      ))}

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default RouterComponent;
