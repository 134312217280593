import { v4 as uuid } from 'uuid';
import { TFunction } from 'react-i18next';
import {
  differenceInCalendarDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
  startOfToday,
  startOfDay,
} from 'date-fns';

import { ChildrenProps, ResponseContentParentProps, ProfileProps } from '@services/User/User.types';
import { ResponseContentFriendProps } from '@services/Friend/Friend.types';
import { ResponseContentEndorsementsProps } from '@services/Provider/Endorsement/Endorsement.types';
import { URL_PARAMS } from '@constants/urlParams';
import { copyText, isArrayFilled } from '@utils/common';

import { joinWithSlash } from './text';

export interface ProfileChildrenListProps {
  id: string;
  years: number | null;
  hasHalfYear: boolean;
  additionalText: string;
}

export const newParentPlaceholder: ResponseContentParentProps = {
  profileId: '',
  firstName: '',
  lastName: '',
  mutualFriends: 0,
  imageUrl: '',
  neighborhoods: [],
};

export const mockedAvatars: Array<any> = Array(2)
  .fill('')
  .map(() => ({
    id: uuid(),
    src: 'https://i.pravatar.cc/300',
    alt: `avatar of user ${uuid()}`,
  }));

export const mockedInitials: Array<any> = [
  {
    id: uuid(),
    firstName: 'Tom',
    lastName: 'Lorem',
  },
  {
    id: uuid(),
    firstName: 'Alex',
    lastName: 'Ipsum',
  },
  {
    id: uuid(),
    firstName: 'Doris',
    lastName: 'Dolor',
  },
];

export const getInitials = (firstName: string, lastName?: string) => {
  if (lastName) {
    return [firstName, lastName].map((text) => `${text.charAt(0).toUpperCase()}`).join('');
  }

  return firstName.charAt(0).toUpperCase();
};

export const copyProfileLink = (id: string) => {
  copyText(`${process.env.PUBLIC_BASE_URL}?${URL_PARAMS.REFERRAL}=${id}`);
};

export const copyProfileLinkString = (id: string | null) => {
  return `${process.env.PUBLIC_BASE_URL}?${URL_PARAMS.REFERRAL}=${id}`;
};

export const copyProviderLink = (id?: string) => {
  copyText(`${process.env.PUBLIC_BASE_URL}providers/${id}`);
};

export const copyProviderLinkString = (id?: string) => {
  return `${process.env.PUBLIC_BASE_URL}providers/${id}`;
};

export const createProfileChildrenList = (
  t: TFunction,
  children: Array<ChildrenProps>,
  compareDate?: Date,
): Array<ProfileChildrenListProps> => {
  const getMinValue = (value: number, minValue: number) => Math.min(value, minValue);
  const today = compareDate ? startOfDay(compareDate) : startOfToday();
  const yearsOld = (value = '') => `${value !== '' ? value : t('common.dates.years')} ${t('common.dates.old')}`;

  return children
    .sort(({ birthDate: dateA }, { birthDate: dateB }) => new Date(dateA).getTime() - new Date(dateB).getTime())
    .map(({ birthDate, id }) => {
      const birthDateTime = new Date(birthDate);

      const daysDifference = differenceInCalendarDays(today, birthDateTime);
      const strictMonthsDifference = differenceInMonths(today, birthDateTime);
      const weeksDifference = differenceInWeeks(today, birthDateTime);
      const yearsDifference = differenceInYears(today, birthDateTime);

      if (daysDifference < 0) {
        return {
          id,
          years: null,
          hasHalfYear: false,
          additionalText: t('common.dates.expecting'),
        };
      }

      if (strictMonthsDifference < 1) {
        const displayWeek = weeksDifference === 1 ? t('common.dates.week') : t('common.dates.weeks');

        return {
          id,
          years: getMinValue(weeksDifference, 4),
          hasHalfYear: false,
          additionalText: yearsOld(displayWeek),
        };
      }

      if (yearsDifference < 2) {
        const displayMonth = strictMonthsDifference === 1 ? t('common.dates.month') : t('common.dates.months');

        return {
          id,
          years: getMinValue(strictMonthsDifference, 24),
          hasHalfYear: false,
          additionalText: yearsOld(displayMonth),
        };
      }

      if (yearsDifference < 4) {
        const hasHalfYear =
          (strictMonthsDifference >= 30 && strictMonthsDifference < 36) ||
          (strictMonthsDifference >= 42 && strictMonthsDifference < 48);

        return {
          id,
          years: yearsDifference,
          hasHalfYear,
          additionalText: yearsOld(),
        };
      }

      return {
        id,
        years: yearsDifference,
        hasHalfYear: false,
        additionalText: yearsOld(),
      };
    });
};

export const generateSrcUrl = (profileImage: string | undefined, imageUuid = '') => {
  if (profileImage && profileImage.includes('base64')) {
    return profileImage;
  }

  if (profileImage) {
    return `${profileImage}?${imageUuid || uuid()}`;
  }

  return undefined;
};

export const isUserProfileFilled = (profile: ProfileProps | null) => {
  if (!profile) {
    return false;
  }

  const { bio, children, neighborhoods } = profile;
  const hasChildren = isArrayFilled(children);
  const hasNeighborhoods = isArrayFilled(neighborhoods);

  return bio || hasChildren || hasNeighborhoods;
};

const createTextLocations = (locations: Array<string>) => {
  const uniqueLocations = new Set(locations);
  return joinWithSlash(Array.from(uniqueLocations));
};

const changeDirectionLocations = (locations: Array<string>) => {
  const changeDirection = locations[locations.length - 1] === 'United States';
  if (changeDirection) {
    return ['United States', ...locations];
  }
  return locations;
};

export const generateDisplayProviderLocations = (locations: Record<string, any>[] | null, returnText = true) => {
  if (!locations || locations.length === 0) {
    return '';
  }
  const locationList = locations.map(({ locality, city, district, state, country }) => {
    if (locality) {
      return locality;
    }
    if (city) {
      return city;
    }
    if (district) {
      return district;
    }
    if (state) {
      return state;
    }
    if (country) {
      return country;
    }
  });

  return returnText ? createTextLocations(locationList) : changeDirectionLocations(locationList);
};

export const friendSkeletonElement: ResponseContentFriendProps = {
  firstName: '',
  lastName: '',
  imageUrl: '',
  mutualFriends: 0,
  profileId: '',
};

export const providerSkeletonElement: ResponseContentEndorsementsProps = {
  name: '',
  imageUrl: '',
  providerId: '',
  providerTypes: [],
  providerLocations: [],
  service_locations: [],
  endorsedBy: {
    friends: 0,
    me: false,
    others: 0,
    total: 0,
    profiles: [],
  },
};
