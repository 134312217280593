import { styled } from '@mui/material/styles';

export const List = styled('ul')`
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  width: 100%;
`;

export const ListItem = styled('li')`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  width: 100%;
`;

export const LoadMoreIndicator = styled('li')`
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 0;
`;
