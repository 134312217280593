import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import UserService from '@services/User/User.service';
import { getAuthConfig } from '@services/services.utils';
import useNotification from '@hooks/useNotification';
import useQuery from '@hooks/useQuery';
import { getStorageToken, removeStorageToken } from '@utils/storage';
import { generateUrlWithParams } from '@utils/common';
import { decodeToken } from '@utils/token';
import axiosInstance, { axiosInstanceNew } from '@utils/api';
import { routes } from '@pages/Pages';
import { URL_PARAMS } from '@constants/urlParams';
import * as Routes from '@constants/routes';

import ProviderService from '@services/Provider/Provider/Provider.service';
import { AuthContext } from './Auth.context';
import { UserAuthContextProps } from './Auth.types';
import { initialUserData, defaultErrorResponseData } from './Auth.utils';
import { TEST_ID } from './Auth.constants';

export const AuthProvider: FC = ({ children }) => {
  const { addNotification, addResponseErrorNotification } = useNotification();
  const navigate = useNavigate();
  const location = useLocation();
  const { getParam } = useQuery();
  const { t } = useTranslation();
  const [user, setUser] = useState<UserAuthContextProps>(initialUserData);
  const [token, setToken] = useState('');
  const [currentLocation, setCurrentLocation] = useState<string | null>(null);
  const [currentProviderLocation, setCurrentProviderLocation] = useState<string | null>(null);
  const [freshLogin, setFreshLogin] = useState(true);
  const [loggedOut, setLoggedOut] = useState(false);
  const [shouldRefreshFriends, setShouldRefreshFriends] = useState(false);
  const [shouldRefreshEndorsements, setShouldRefreshEndorsements] = useState(false);
  const [hasNoNeighborhoods, setHasNoNeighborhoods] = useState(false);
  const updateUser = (userData: UserAuthContextProps) => {
    setUser({ ...user, ...userData });
  };

  const logout = (shouldRedirect = true) => {
    setUser(initialUserData);
    setLoggedOut(true);
    setToken('');
    removeStorageToken();

    if (shouldRedirect) {
      const referralParam = getParam(URL_PARAMS.REFERRAL);
      const resetPasswordCodeParam = getParam(URL_PARAMS.RESET_PASSWORD_CODE);
      const confirmAccountCodeParam = getParam(URL_PARAMS.ACTIVATION_CODE);
      const uuidParam = getParam(URL_PARAMS.UUID);
      const { pathname } = location;
      const isVisitorRoute = Boolean(routes.find((route) => route.path === pathname || !route.guard));
      let route = isVisitorRoute ? pathname : Routes.LOGIN;

      const params = [
        { name: URL_PARAMS.REFERRAL, param: referralParam },
        { name: URL_PARAMS.RESET_PASSWORD_CODE, param: resetPasswordCodeParam },
        { name: URL_PARAMS.ACTIVATION_CODE, param: confirmAccountCodeParam },
        { name: URL_PARAMS.UUID, param: uuidParam },
      ];

      if (resetPasswordCodeParam) {
        route = Routes.RESET_PASSWORD;
      }

      if (confirmAccountCodeParam && uuidParam) {
        route = Routes.CONFIRM_ACCOUNT;
      }

      const navigateUrl = generateUrlWithParams(route, params);
      navigate(navigateUrl);
      return;
    }

    navigate(Routes.LOGIN);
  };

  const refreshProfile = (tokenString?: string, onLoad?: () => void) => {
    const referralParam = getParam(URL_PARAMS.REFERRAL);
    if (tokenString) {
      UserService.getMyProfile()
        .then(({ data: profile }) => {
          const tokenData = decodeToken(tokenString || token);
          const addTokenToUser = { ...tokenData, profile };
          if (tokenData?.role === 'ROLE_PROVIDER') {
            ProviderService.getCommonProvider()
              .then(({ data }) => {
                if (data) {
                  setUser({ ...user, ...addTokenToUser, ...data });

                  if (referralParam) {
                    return navigate(`${Routes.PARENTS}/${referralParam}`);
                  }
                  if (data.status === 'DRAFT' && (location.pathname === '/' || location.pathname === '/login')) {
                    return navigate(Routes.HOME);
                  }
                  if (onLoad) {
                    return onLoad();
                  }
                }
              })
              .catch(() => {
                setUser({ ...user, ...addTokenToUser });
                if (onLoad) {
                  onLoad();
                }
              });
          } else if (tokenData?.role === 'ROLE_PARENT') {
            setUser({ ...user, ...addTokenToUser });
            if (referralParam) {
              return navigate(`${Routes.PARENTS}/${referralParam}`);
            }
            if (!profile.zipCode && (location.pathname === '/' || location.pathname === '/login')) {
              return navigate(Routes.HOME);
            }
            if (onLoad) {
              return onLoad();
            }
          } else if (tokenData?.role === 'ROLE_ADMIN') {
            setUser({ ...user, ...addTokenToUser });
            if (referralParam) {
              return navigate(`${Routes.PARENTS}/${referralParam}`);
            }
            if (onLoad) {
              onLoad();
            }
          }
        })
        .catch(({ errorMessage, status }) => {
          addResponseErrorNotification('common.responses.invalid.refreshProfile', errorMessage, status);
        });
    }
    if (!tokenString && referralParam) {
      navigate(`${Routes.LOGIN}/?referral=${referralParam}`);
    }
  };
  const handleIncomingStatusCode = (statusCode: number) => {
    if (statusCode === 403 && token) {
      logout();
      addNotification(t('common.session.expired'));
    }
  };

  useMemo(
    () =>
      axiosInstance.interceptors.request.use((config) => {
        return getAuthConfig(token, config);
      }),

    [token],
  );
  useMemo(
    () =>
      axiosInstanceNew.interceptors.request.use((config) => {
        return getAuthConfig(token, config);
      }),

    [token],
  );

  useMemo(
    () =>
      axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => {
          const errorResponse = error?.response;

          if (errorResponse) {
            const { status, data } = errorResponse;

            handleIncomingStatusCode(status);

            return Promise.reject({ ...data, status });
          }

          return Promise.reject(defaultErrorResponseData);
        },
      ),
    [],
  );

  useEffect(() => {
    const storageToken = getStorageToken() || undefined;
    if (storageToken) {
      axiosInstance.interceptors.request.use((config) => {
        return getAuthConfig(storageToken, config);
      });
    }
    refreshProfile(storageToken);
  }, []);

  useEffect(() => {
    if (currentLocation && freshLogin && !loggedOut) {
      navigate(currentLocation);
      setCurrentLocation(null);
      setFreshLogin(false);
    }
  }, [currentLocation]);

  useEffect(() => {
    if (currentProviderLocation && user.email) {
      navigate(currentProviderLocation);
      setCurrentProviderLocation(null);
    }
  }, [currentProviderLocation, user]);

  return (
    <AuthContext.Provider
      value={{
        user,
        updateUser,
        logout,
        updateToken: setToken,
        refreshProfile,
        setCurrentLocation,
        setCurrentProviderLocation,
        currentLocation,
        shouldRefreshFriends,
        refreshFriends: setShouldRefreshFriends,
        shouldRefreshEndorsements,
        refreshEndorsements: setShouldRefreshEndorsements,
        hasNoNeighborhoods,
        setHasNoNeighborhoods,
      }}
      data-component-locator={TEST_ID}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
