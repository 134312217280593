import UserIcon from '@assets/icons/user.svg';
import MapPinIcon from '@assets/icons/map-pin.svg';
import UsersIcon from '@assets/icons/users.svg';
import ThumbUpIcon from '@assets/icons/thumb-up.svg';
import CogIcon from '@assets/icons/cog.svg';
import ConnectIcon from '@assets/icons/connect.svg';
import * as Routes from '@constants/routes';

import { MenuItemProps } from './Menu.types';

export const menuItems = (role: string): Array<MenuItemProps> => {
  if (role === 'ROLE_PARENT') {
    return [
      {
        id: 0,
        icon: UserIcon,
        text: 'common.menu.viewProfile',
        route: Routes.PROFILE,
        action: null,
        type: 'element',
      },
      {
        id: 1,
        icon: MapPinIcon,
        text: 'common.menu.editNeighborhoods',
        route: Routes.EDIT_NEIGHBORHOODS,
        action: null,
        type: 'element',
      },
      {
        id: 2,
        icon: UsersIcon,
        text: 'common.menu.myFriends',
        route: null,
        action: 'myFriends',
        type: 'element',
      },
      {
        id: 3,
        icon: ThumbUpIcon,
        text: 'common.menu.myEndorsements',
        route: null,
        action: 'myEndorsements',
        type: 'element',
      },
      {
        id: 4,
        icon: CogIcon,
        text: 'common.menu.settings',
        route: Routes.SETTINGS,
        action: null,
        type: 'element',
      },
      // {
      //   id: 5,
      //   icon: ProviderIcon,
      //   text: 'common.menu.becomeProvider',
      //   route: '',
      //   action: 'becameProvider',
      //   type: 'button',
      //   variant: 'filled',
      // },
      {
        id: 6,
        icon: ConnectIcon,
        text: 'common.menu.copyLink',
        route: '',
        action: 'copyLink',
        isCopy: 'parent',
        type: 'button',
        variant: 'outlined',
      },
      {
        id: 7,
        icon: null,
        text: 'common.menu.logout',
        route: '',
        action: 'logout',
        type: 'button',
        variant: 'outlined',
      },
    ];
  }
  if (role === 'ROLE_PROVIDER') {
    return [
      {
        id: 0,
        icon: UserIcon,
        text: 'common.menu.viewProviderProfile',
        route: Routes.PROVIDER_PROFILE,
        action: null,
        type: 'element',
      },
      {
        id: 4,
        icon: CogIcon,
        text: 'common.menu.settings',
        route: Routes.SETTINGS,
        action: null,
        type: 'element',
      },
      {
        id: 5,
        icon: ConnectIcon,
        text: 'common.menu.copyLink',
        route: '',
        action: 'copyProviderLink',
        isCopy: 'provider',
        type: 'button',
        variant: 'outlined',
      },
      {
        id: 6,
        icon: null,
        text: 'common.menu.logout',
        route: '',
        action: 'logout',
        type: 'button',
        variant: 'outlined',
      },
    ];
  }

  return [
    {
      id: 0,
      icon: UserIcon,
      text: 'common.menu.viewProfile',
      route: Routes.PROFILE,
      action: null,
      type: 'element',
    },
    {
      id: 1,
      icon: MapPinIcon,
      text: 'common.menu.editNeighborhoods',
      route: Routes.EDIT_NEIGHBORHOODS,
      action: null,
      type: 'element',
    },
    {
      id: 2,
      icon: UsersIcon,
      text: 'common.menu.myFriends',
      route: null,
      action: 'myFriends',
      type: 'element',
    },
    {
      id: 3,
      icon: ThumbUpIcon,
      text: 'common.menu.myEndorsements',
      route: null,
      action: 'myEndorsements',
      type: 'element',
    },
    {
      id: 4,
      icon: CogIcon,
      text: 'common.menu.settings',
      route: Routes.SETTINGS,
      action: null,
      type: 'element',
    },
    {
      id: 5,
      icon: ConnectIcon,
      text: 'common.menu.copyLink',
      route: '',
      action: 'copyLink',
      isCopy: 'parent',
      type: 'button',
      variant: 'outlined',
    },
    {
      id: 6,
      icon: null,
      text: 'common.menu.logout',
      route: '',
      action: 'logout',
      type: 'button',
      variant: 'outlined',
    },
  ];
};
