import { styled } from '@mui/material/styles';

import { ActionBlockProps } from './ActionCard.types';

export const ActionBlock = styled('div')<ActionBlockProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ color }) => color};
  padding: ${({ theme }) => theme.spacing(4)};
  border-radius: 4px;
  position: relative;
  text-align: center;
  margin: ${({ margin }) => margin || 0};
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('smallDesktop')} {
    padding: ${({ theme }) => `${theme.spacing(14)} ${theme.spacing(8)}`};
    flex-direction: row;
    justify-content: center;
  }
`;

export const ActionBlockContentContainer = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(8)};

  ${({ theme }) => theme.breakpoints.up('smallDesktop')} {
    margin: ${({ theme }) => `0 ${theme.spacing(7)} 0 0`};
  }
`;

export const ActionBlockIconContainer = styled('div')<ActionBlockProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color};
  width: 40px;
  height: 40px;
  border-radius: 100px;
  box-shadow: ${({ theme }) => theme.customShadows.actionCardCircle};
  margin-top: ${({ theme }) => theme.spacing(-10)};

  ${({ theme }) => theme.breakpoints.up('smallDesktop')} {
    margin: ${({ theme }) => `0 ${theme.spacing(7)} 0 0`};
  }
`;

export const Link = styled('a')`
  text-decoration: none;
`;
