import axios, { axiosInstanceNew } from '@utils/api';
import { authConfig } from '@services/services.utils';

import {
  AxiosAdditionalServicesProps,
  AxiosCategoriesProps,
  AxiosParentsProps,
  AxiosProvidersProps,
  AxiosProviderTypesProps,
  AxiosSearchProvidersProps,
  AxiosSpecialtiesProps,
  ParentsSearchProps,
  PartNameSearchProps,
  ProvidersSearchProps,
  AxiosRecentSearchedProps,
} from './Search.types';

const RECENT_SEARCHES_SIZE = 3;

class Search {
  usersEndpoint: string;

  providersEndpoint: string;

  categoriesEndpoint: string;

  providerTypesEndpoint: string;

  providerTypesEndpointNew: string;

  searchEndpoint: string;

  specialtiesEndpoint: string;

  additionalServicesEndpointNew: string;

  additionalServicesEndpoint: string;

  constructor() {
    this.usersEndpoint = 'users';
    this.providersEndpoint = 'providers';
    this.categoriesEndpoint = 'categories';
    this.providerTypesEndpoint = 'providerTypes';
    this.providerTypesEndpointNew = 'provider-types';
    this.searchEndpoint = 'search';
    this.specialtiesEndpoint = 'specialties';
    this.additionalServicesEndpoint = 'additionalServices';
    this.additionalServicesEndpointNew = 'additional-services';
  }

  getRecentSearches(): Promise<AxiosRecentSearchedProps> {
    return axios.get(`${this.usersEndpoint}/me/recentSearches`, {
      params: {
        size: RECENT_SEARCHES_SIZE,
        sort: 'searchTime,desc',
      },
      ...authConfig,
    });
  }

  getParents({ searchQuery, size = 3, ...rest }: PartNameSearchProps): Promise<AxiosParentsProps> {
    return axios.get(this.usersEndpoint, {
      params: {
        size,
        partName: searchQuery,
        ...rest,
      },
      ...authConfig,
    });
  }

  getParentsNopUser({ searchQuery, size = 3 }: PartNameSearchProps): Promise<AxiosParentsProps> {
    return axios.get(`${this.usersEndpoint}/spro/public`, {
      params: {
        size,
        partName: searchQuery,
      },
    });
  }

  getProviders({ searchQuery, size = 4, ...rest }: PartNameSearchProps): Promise<AxiosProvidersProps> {
    return axios.get(`${this.providersEndpoint}/cards`, {
      params: {
        size,
        partName: searchQuery,
        ...rest,
      },
      ...authConfig,
    });
  }

  getProvidersNoUser({ searchQuery, size = 4 }: PartNameSearchProps): Promise<AxiosProvidersProps> {
    return axiosInstanceNew.get(`spro/${this.providersEndpoint}/public/cards`, {
      params: {
        size,
        partName: searchQuery,
      },
    });
  }

  getCategories({ searchQuery, size = 5 }: PartNameSearchProps): Promise<AxiosCategoriesProps> {
    return axios.get(this.categoriesEndpoint, {
      params: {
        size,
        partName: searchQuery,
      },
      ...authConfig,
    });
  }

  getCategoriesNoUser({ searchQuery, size = 5 }: PartNameSearchProps): Promise<AxiosCategoriesProps> {
    return axiosInstanceNew.get(`spro/data/${this.categoriesEndpoint}`, {
      params: {
        size,
        partName: searchQuery,
      },
    });
  }

  getProviderTypes({ searchQuery, size = 5 }: PartNameSearchProps): Promise<AxiosProviderTypesProps> {
    return axios.get(this.providerTypesEndpoint, {
      params: {
        size,
        partName: searchQuery,
      },
      ...authConfig,
    });
  }

  getProviderTypesNoUser({ searchQuery, size = 5 }: PartNameSearchProps): Promise<AxiosProviderTypesProps> {
    return axiosInstanceNew.get(`spro/data/${this.providerTypesEndpointNew}`, {
      params: {
        size,
        partName: searchQuery,
      },
    });
  }

  getSpecialties({ searchQuery, size = 5 }: PartNameSearchProps): Promise<AxiosSpecialtiesProps> {
    return axios.get(this.specialtiesEndpoint, {
      params: {
        size,
        partName: searchQuery,
      },
      ...authConfig,
    });
  }

  getSpecialtiesNoUser({ searchQuery, size = 5 }: PartNameSearchProps): Promise<AxiosSpecialtiesProps> {
    return axiosInstanceNew.get(`spro/data/${this.specialtiesEndpoint}`, {
      params: {
        size,
        partName: searchQuery,
      },
    });
  }

  getAdditionalServices({ searchQuery, size = 5 }: PartNameSearchProps): Promise<AxiosAdditionalServicesProps> {
    return axios.get(this.additionalServicesEndpoint, {
      params: {
        size,
        partName: searchQuery,
      },
      ...authConfig,
    });
  }

  getAdditionalServicesNoUser({ searchQuery, size = 5 }: PartNameSearchProps): Promise<AxiosAdditionalServicesProps> {
    return axiosInstanceNew.get(`spro/data/${this.additionalServicesEndpointNew}`, {
      params: {
        size,
        partName: searchQuery,
      },
    });
  }

  searchProviders({
    params,
    page,
    size,
    sort = 'friends,desc',
  }: ProvidersSearchProps): Promise<AxiosSearchProvidersProps> {
    return axios.get(`${this.providersEndpoint}/${this.searchEndpoint}`, {
      params: {
        page,
        size,
        sort,
        ...params,
      },
      ...authConfig,
    });
  }

  searchProvidersPublic({
    params,
    page,
    size,
    sort = 'friends,desc',
  }: ProvidersSearchProps): Promise<AxiosSearchProvidersProps> {
    return axios.get(`${this.providersEndpoint}/public/${this.searchEndpoint}`, {
      params: {
        page,
        size,
        sort,
        ...params,
      },
      ...authConfig,
    });
  }

  searchParents({ params, page, size }: ParentsSearchProps): Promise<AxiosParentsProps> {
    return axios.get(`${this.usersEndpoint}/${this.searchEndpoint}`, {
      params: {
        page,
        size,
        ...params,
      },
      ...authConfig,
    });
  }

  searchParentsPublic({ params, page, size }: ParentsSearchProps): Promise<AxiosParentsProps> {
    return axios.get(`${this.usersEndpoint}/public/${this.searchEndpoint}`, {
      params: {
        page,
        size,
        ...params,
      },
      ...authConfig,
    });
  }
}

export default new Search();
