import { TFunction } from 'i18next';
import * as Yup from 'yup';

import { ERRORS, LENGTHS } from '@constants/forms';

import { MESSAGE_MAX_LENGTH } from './ContactUs.constants';

export const formSchema = (t: TFunction) =>
  Yup.object().shape({
    name: Yup.string()
      .required(t(ERRORS.FIELD_REQUIRED))
      .min(LENGTHS.MIN, t(ERRORS.MIN_LENGTH, { length: LENGTHS.MIN }))
      .max(LENGTHS.MAX, t(ERRORS.MAX_LENGTH, { length: LENGTHS.MAX })),
    email: Yup.string()
      .email(t(ERRORS.VALID_EMAIL))
      .required(t(ERRORS.FIELD_REQUIRED))
      .max(LENGTHS.MAX, t(ERRORS.MAX_LENGTH, { length: LENGTHS.MAX })),
    subject: Yup.string()
      .required(t(ERRORS.FIELD_REQUIRED))
      .min(LENGTHS.MIN, t(ERRORS.MIN_LENGTH, { length: LENGTHS.MIN }))
      .max(LENGTHS.MAX, t(ERRORS.MAX_LENGTH, { length: LENGTHS.MAX })),
    message: Yup.string()
      .required(t(ERRORS.FIELD_REQUIRED))
      .min(LENGTHS.MIN, t(ERRORS.MIN_LENGTH, { length: LENGTHS.MIN }))
      .max(LENGTHS.MAX, t(ERRORS.MAX_LENGTH, { length: MESSAGE_MAX_LENGTH })),
  });
