import { styled, css } from '@mui/material/styles';
import { CircularProgress, Box } from '@mui/material';
import { ActionBarStyleProps, EditContainerStyleProps, HasEditModeStyleProp } from './Parent.types';

export const Container = styled('div')`
  position: relative;
  background-color: ${({ theme }) => theme.colors.light3};
`;

export const ContentWrapper = styled('section')`
  padding: ${({ theme }) => theme.spacing(12)};

  ${({ theme }) => theme.breakpoints.up('tablet')} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.white};
  }

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    padding: ${({ theme }) => `${theme.spacing(14)} ${theme.spacing(16)}`};
    margin-bottom: ${({ theme }) => theme.spacing(40)};
  }

  ${({ theme }) => theme.breakpoints.up('smallDesktop')} {
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 0;
  }

  ${({ theme }) => theme.breakpoints.up('desktop')} {
    padding: ${({ theme }) => `${theme.spacing(32)} ${theme.spacing(44)}`};
  }
`;

export const SectionWrapper = styled('div')`
  flex: 1;

  :last-child {
    margin-top: ${({ theme }) => theme.spacing(16)};
  }

  ${({ theme }) => theme.breakpoints.up('smallDesktop')} {
    margin-right: ${({ theme }) => theme.spacing(12)};

    :last-child {
      margin: 0;
    }
  }
`;

export const EndorsedProvidersWrapper = styled('div')`
  max-width: none;

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    max-width: 328px;
  }
`;

export const DesktopWrapper = styled('div')`
  ${({ theme }) => theme.breakpoints.up('laptop')} {
    display: flex;
    justify-content: space-between;
    padding: ${({ theme }) => `0 ${theme.spacing(22)}`};

    > section {
      width: 100%;
      margin-right: ${({ theme }) => theme.spacing(12)};

      :last-child {
        margin-right: 0;
        flex: 1;
      }
    }
  }
`;

export const BackIconWrapper = styled('div')<HasEditModeStyleProp>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 24px;
  line-height: 0;
  cursor: pointer;
  position: absolute;
  top: ${({ $hasEditMode }) => ($hasEditMode ? 'auto' : '0')};
  left: ${({ $hasEditMode }) => ($hasEditMode ? 'auto' : '0')};
  z-index: 15;

  ${({ theme }) => theme.breakpoints.up('tablet')} {
    left: 24px;
  }

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    position: static;
    top: unset;
    left: unset;
    z-index: unset;
    width: fit-content;
    height: fit-content;

    > h5 {
      margin-left: ${({ theme }) => theme.spacing(10)};
    }
  }
`;

export const ProfileCard = styled('section')`
  position: relative;
  height: fit-content;
  padding: ${({ theme }) => `${theme.spacing(12)} ${theme.spacing(12)} ${theme.spacing(16)}`};
  background: ${({ theme }) => theme.customGradients.modal};
  box-shadow: ${({ theme }) => theme.customShadows.menu};
  z-index: 0;

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    max-width: 493px;
    width: 100%;
    background: ${({ theme }) => theme.colors.white};
    margin-top: ${({ theme }) => theme.spacing(95)};
    padding: ${({ theme }) => `${theme.spacing(52)} ${theme.spacing(16)} ${theme.spacing(22)}`};
    box-shadow: none;
  }

  ${({ theme }) => theme.breakpoints.up('smallDesktop')} {
    padding: ${({ theme }) => `${theme.spacing(52)} ${theme.spacing(22)} ${theme.spacing(43)}`};
  }
`;

export const ProfileLayoutWrapper = styled('div')`
  max-width: ${({ theme }) => theme.breakpoints.values.tablet}px;
  width: 100%;
  margin: 0 auto;
  position: relative;

  ${({ theme }) => theme.breakpoints.up('tablet')} {
    padding: ${({ theme }) => `0 ${theme.spacing(14)}`};
  }

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    position: static;
  }

  ${({ theme }) => theme.breakpoints.up('smallDesktop')} {
    max-width: 100%;
    padding: 0;
    display: flex;
  }
`;

export const ProfilePictureWrapper = styled('div')`
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    position: absolute;
    top: -185px;
    left: 0;
    right: 0;

    > div {
      width: 288px;
      height: 288px;
      margin: 0;
    }

    > div > div {
      width: 100%;
      height: 100%;
    }
  }

  ${({ theme }) => theme.breakpoints.up('smallDesktop')} {
    margin: 0;
  }
`;

export const ProfileContentWrapper = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(8)};
`;

export const ProfileTitle = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const ProfileTextBox = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(16)};

  h5 {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  :last-child {
    margin-bottom: 0;
  }
`;

export const ProfileCopyLink = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(25)};
`;

export const ProfileFriendButton = styled('div')`
  margin: ${({ theme }) => `${theme.spacing(10)} 0 ${theme.spacing(16)}`};
`;

export const ProfileEdit = styled('div')`
  position: absolute;
  right: 0;
  transform: translateY(-40px);

  ${({ theme }) => theme.breakpoints.up('tablet')} {
    right: 24px;
  }
`;

export const ProfileEditMode = styled('div')`
  position: absolute;
  right: 28px;
`;

export const CardWrapper = styled('div')`
  cursor: pointer;

  > div {
    margin-bottom: ${({ theme }) => theme.spacing(4)};
    max-width: 100%;
  }

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    > div {
      min-width: 328px;
    }
  }
`;

export const MoreLink = styled('div')`
  margin: ${({ theme }) => `${theme.spacing(4)} 0 ${theme.spacing(16)}`};

  span {
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  }

  :last-child {
    margin-bottom: 0;
  }
`;

export const ActionBar = styled('div')<ActionBarStyleProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  position: ${({ $isSticky }) => ($isSticky ? 'sticky' : 'relative')};
  top: 0;
  z-index: 15;
  padding: ${({ theme }) => `0 ${theme.spacing(12)}`};
  margin: ${({ theme, $hasEditMode }) => ($hasEditMode ? 0 : `0 0 ${theme.spacing(4)}`)};
  background-color: ${({ theme, $isSticky }) => ($isSticky ? theme.colors.light3 : 'transparent')};

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    position: relative;
    padding: ${({ theme }) => `0 ${theme.spacing(22)}`};
    margin: ${({ theme }) => `${theme.spacing(4)} 0`};
  }
`;

export const ButtonsWrapper = styled('div')`
  display: flex;

  > button {
    background-color: ${({ theme }) => theme.colors.white};
  }

  > button:not(:only-child):first-child {
    margin-right: ${({ theme }) => theme.spacing(8)};
  }
`;

export const ChildWrapper = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  :last-child {
    margin-bottom: 0;
  }
`;

export const LoaderWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export const Loader = styled(CircularProgress)``;

export const HorizontalLine = styled('div')`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.light3};
  margin: ${({ theme }) => `${theme.spacing(8)} 0`};

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    margin: ${({ theme }) => `${theme.spacing(5)} 0 ${theme.spacing(4)}`};
  }
`;

export const PillsList = styled('div')`
  display: flex;
  flex-wrap: wrap;

  > div {
    margin: ${({ theme }) => theme.spacing(2)};
  }
`;

export const InlineIconElement = styled('div')`
  display: flex;
  align-items: center;
  line-height: 0;

  svg {
    margin-right: ${({ theme }) => theme.spacing(7)};
  }
`;

export const NoEndorsmentsWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

export const TitleWrapper = styled('div')`
  margin: ${({ theme }) => `0 0 ${theme.spacing(16)}`};
`;

export const ElementContainer = styled(Box)`
  ${({ onClick = undefined }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

export const AvatarContainer = styled('div')`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => `${theme.spacing(8)} 0`};
`;

export const EndorsedByContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const EditHeader = styled('div')`
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const EditHeaderTitle = styled('div')`
  margin: 0 auto;
`;

export const EditContainer = styled('div')<EditContainerStyleProps>`
  position: relative;
  background-color: ${({ theme, $isImageCropMode }) => ($isImageCropMode ? theme.colors.white : theme.colors.light4)};
  padding: ${({ theme }) => `${theme.spacing(8)} ${theme.spacing(14)}`};

  > div:last-child {
    ${({ $isImageCropMode }) =>
      $isImageCropMode &&
      css`
        position: relative;
        padding: 0;
        z-index: 15;
      `}
  }

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    padding: ${({ theme }) => `${theme.spacing(60)} ${theme.spacing(82)}`};
    background-color: ${({ theme }) => theme.colors.white};

    > div:last-child {
      ${({ $isImageCropMode }) =>
        $isImageCropMode &&
        css`
          position: absolute;
          padding: inherit;
          z-index: auto;
        `}
    }
  }
`;

export const MutualFriendsContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const MutualFriendsWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(3)};

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    margin-top: ${({ theme }) => theme.spacing(8)};
  }
`;
