import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getActiveTab } from './Tabs.utils';
import { TabsProps } from './Tabs.types';
import { TABS_COMPONENT_TEST_ID, TABS_CONTENT_ELEMENT_TEST_ID, TABS_TAB_ELEMENT_TEST_ID } from './Tabs.constants';
import * as Styles from './Tabs.styles';

const Tabs: FC<TabsProps> = ({ children, headers, setActiveTab, activeTab = 0, shouldTranslate = false }) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(getActiveTab(activeTab, children));

  const handleChange = (_: SyntheticEvent, newTab: number) => {
    if (setActiveTab !== undefined) {
      setActiveTab(newTab);
      return;
    }

    setCurrentTab(newTab);
  };

  useEffect(() => {
    setCurrentTab(getActiveTab(activeTab, children));
  }, [activeTab]);

  return (
    <Styles.Container data-component-locator={TABS_COMPONENT_TEST_ID}>
      <Styles.TabsContainer>
        <Styles.Tabs value={currentTab} onChange={handleChange}>
          {headers.map((header, index) => (
            <Styles.Tab
              data-component-locator={TABS_TAB_ELEMENT_TEST_ID}
              key={`tab-${header}-${index.toString()}`}
              label={shouldTranslate ? t(header) : header}
              disableRipple
            />
          ))}
        </Styles.Tabs>
      </Styles.TabsContainer>
      {children.map((childTab, index) => (
        <Styles.TabContent
          data-component-locator={TABS_CONTENT_ELEMENT_TEST_ID}
          key={`tab-child-${index.toString()}`}
          hidden={currentTab !== index}
        >
          {currentTab === index && childTab}
        </Styles.TabContent>
      ))}
    </Styles.Container>
  );
};

export default Tabs;
