import { FC } from 'react';

import AddPhotoIcon from '@assets/icons/add-photo.svg';
import BinIcon from '@assets/icons/bin.svg';
import PencilIcon from '@assets/icons/pencil.svg';
import Icon from '@components/Icon';
import Typography from '@components/Typography';
import theme from '@theme';
import { generateSrcUrl } from '@utils/user';

import { UserProfilePictureProps } from './UserProfilePicture.types';
import * as Styles from './UserProfilePicture.styles';
import {
  IMAGE_EDITOR_CONTAINER_TEST_ID,
  EDIT_ACTION_TEST_ID,
  REMOVE_ACTION_TEST_ID,
  INITIALS_TEST_ID,
  PROFILE_IMAGE_TEST_ID,
  EMPTY_PROFILE_TEST_ID,
} from './UserProfilePicture.constants';

const UserProfilePicture: FC<UserProfilePictureProps> = ({
  profileImage,
  handleImageAdd,
  handleImageRemove,
  hasEditMode = true,
  initials = '',
  variant = 'parent',
}) => {
  if (!profileImage && initials === '' && hasEditMode) {
    return (
      <Styles.ImageEditorContainer
        onClick={handleImageAdd}
        data-component-locator={IMAGE_EDITOR_CONTAINER_TEST_ID}
        $variant={variant}
      >
        <Icon icon={AddPhotoIcon} />
        <Typography variant="body3" color={theme.colors.white} shouldTranslate>
          common.profilePhoto.edit
        </Typography>
      </Styles.ImageEditorContainer>
    );
  }

  if (!profileImage && !hasEditMode && initials === '') {
    return <Styles.EmptyProfilePicture data-component-locator={EMPTY_PROFILE_TEST_ID} $variant={variant} />;
  }

  return (
    <Styles.ImageContainer $variant={variant}>
      {initials && !profileImage ? (
        <Styles.InitialsWrapper data-component-locator={INITIALS_TEST_ID} $variant={variant}>
          <Typography variant="initials" color={theme.colors.white}>
            {initials}
          </Typography>
        </Styles.InitialsWrapper>
      ) : (
        <Styles.ProfileImage
          src={generateSrcUrl(profileImage)}
          data-component-locator={PROFILE_IMAGE_TEST_ID}
          $variant={variant}
        />
      )}
      {hasEditMode && (
        <Styles.ProfileImageActions $variant={variant}>
          <Styles.ProfileImageIconContainer onClick={handleImageAdd} data-component-locator={EDIT_ACTION_TEST_ID}>
            <Icon icon={PencilIcon} />
          </Styles.ProfileImageIconContainer>
          <Styles.ProfileImageIconContainer onClick={handleImageRemove} data-component-locator={REMOVE_ACTION_TEST_ID}>
            <Icon icon={BinIcon} />
          </Styles.ProfileImageIconContainer>
        </Styles.ProfileImageActions>
      )}
    </Styles.ImageContainer>
  );
};

export default UserProfilePicture;
