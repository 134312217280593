import { authConfig } from '@services/services.utils';
import axios, { axiosInstanceNew } from '@utils/api';

import { FormikValues } from 'formik/dist/types';
import {
  AxiosProviderCardsProps,
  AxiosProviderProps,
  AxiosProvidersProps,
  RequestProvidersProps,
} from '@services/Provider/Provider/Provider.types';
import { providerIdProps, step1FieldsProps, step2FieldsProps, step4FieldsProps } from './ProviderOnboarding.types';

class ProviderAge {
  endpoint: string;

  endpointLink: string;

  endpointAdmin: string;

  constructor() {
    this.endpoint = '/spro/providers';
    this.endpointAdmin = 'spro/admin/provider';
    this.endpointLink = 'spro/data/link';
  }

  goToUser(id?: string, userId?: string): Promise<step1FieldsProps> {
    return axiosInstanceNew.put(
      `${id ? this.endpointAdmin : this.endpoint}/${id}/to-user${userId ? `/${userId}` : ''}`,
      {},
      authConfig,
    );
  }

  getProviderLink(providerId?: string): Promise<AxiosProviderProps> {
    return axiosInstanceNew.get(`${this.endpointLink}/by-provider/${providerId}`, authConfig);
  }

  getStep1(id?: string | null): Promise<step1FieldsProps> {
    return axiosInstanceNew.get(
      `${id ? this.endpointAdmin : this.endpoint}/basic-info${id ? `/${id}` : ''}`,
      authConfig,
    );
  }

  sendStep1(id: string | null, data: FormikValues): Promise<void> {
    return axiosInstanceNew.put(
      `${id ? this.endpointAdmin : this.endpoint}/basic-info${id ? `/${id}` : ''}`,
      data,
      authConfig,
    );
  }

  addStatus(id: string | null, status: string): Promise<void> {
    return axiosInstanceNew.put(`${this.endpointAdmin}/set-state/${id}?status=${status}`, {}, authConfig);
  }

  getStep2(id?: string | null): Promise<step2FieldsProps> {
    return axiosInstanceNew.get(
      `${id ? this.endpointAdmin : this.endpoint}/contact-info${id ? `/${id}` : ''}`,
      authConfig,
    );
  }

  sendStep2(id: string | null, data: FormikValues): Promise<void> {
    return axiosInstanceNew.put(
      `${id ? this.endpointAdmin : this.endpoint}/contact-info${id ? `/${id}` : ''}`,
      data,
      authConfig,
    );
  }

  getStep3(id: string | null): Promise<step2FieldsProps> {
    return axiosInstanceNew.get(
      `${id ? this.endpointAdmin : this.endpoint}/other-details${id ? `/${id}` : ''}`,
      authConfig,
    );
  }

  getProvidersReviewed({ page, size, status }: RequestProvidersProps): Promise<AxiosProvidersProps> {
    return axiosInstanceNew.get(`${this.endpointAdmin}`, {
      params: {
        page,
        size,
        sort: `reviewDate,createdAt,name,desc`,
        status,
      },
      ...authConfig,
    });
  }

  sendStep3(id: string | null, data: FormikValues): Promise<void> {
    return axiosInstanceNew.put(
      `${id ? this.endpointAdmin : this.endpoint}/other-details${id ? `/${id}` : ''}`,
      data,
      authConfig,
    );
  }

  getStep4(id?: string | null): Promise<step4FieldsProps> {
    return axiosInstanceNew.get(
      `${id ? this.endpointAdmin : this.endpoint}/additional-services${id ? `/${id}` : ''}`,
      authConfig,
    );
  }

  sendStep4(id: string | null, data: FormikValues): Promise<void> {
    return axiosInstanceNew.put(
      `${id ? this.endpointAdmin : this.endpoint}/additional-services${id ? `/${id}` : ''}`,
      data,
      authConfig,
    );
  }

  sendReview(): Promise<void> {
    return axiosInstanceNew.put(`${this.endpoint}/submit-to-review`, {}, authConfig);
  }

  createProviderId(): Promise<providerIdProps> {
    return axiosInstanceNew.post(`${this.endpointAdmin}`, {}, authConfig);
  }

  updateStatus(id?: string): Promise<providerIdProps> {
    return axiosInstanceNew.put(`${this.endpointAdmin}/${id}/change-activate`, {}, authConfig);
  }

  getProviderCardsNoUser(): Promise<AxiosProviderCardsProps> {
    return axiosInstanceNew.get(`${this.endpoint}/public/cards`, {
      params: {
        size: 20,
        sort: 'createdAt,desc',
      },
      ...authConfig,
    });
  }
}

export default new ProviderAge();
