import { STORAGE_TOKEN } from '@constants/storage';

export const getStorageToken = () => {
  return localStorage.getItem(STORAGE_TOKEN);
};

export const setStorageToken = (token: string) => {
  return localStorage.setItem(STORAGE_TOKEN, token);
};

export const removeStorageToken = () => {
  localStorage.removeItem(STORAGE_TOKEN);
};
