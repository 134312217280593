import { Component, ErrorInfo } from 'react';

import ErrorScreen from '@components/ErrorScreen';
import Button from '@components/Buttons/Button';
import { withNavigate } from '@utils/router';
import * as Routes from '@constants/routes';

import { ErrorBoundaryProps, ErrorBoundaryState } from './ErrorBoundary.types';
import * as Styles from './ErrorBoundary.styles';

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.group('%cErrorHandler', 'color: red; background-color: black; border: 1px solid red; padding: 2px');
    console.log(`%cErrorMessage: %c${error.message}`, 'color: red; font-weight: bold', 'color: white');
    console.log(`%cComponentStack: %c${errorInfo.componentStack}`, 'color: red; font-weight: bold', 'color: white');
    console.groupEnd();
  }

  buttonClickHandler() {
    const { navigate } = this.props;

    navigate(Routes.HOME);
    window.location.reload();
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? (
      <>
        <ErrorScreen
          subtitle="common.errorScreen.subtitle"
          customButton={
            <Styles.ButtonWrapper>
              <Button variant="filled" clickHandler={() => this.buttonClickHandler()} shouldTranslate>
                common.errorScreen.button
              </Button>
            </Styles.ButtonWrapper>
          }
        />
      </>
    ) : (
      children
    );
  }
}

export default withNavigate<ErrorBoundaryProps>(ErrorBoundary);
