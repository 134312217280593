import { BackgroundAssetProps } from '@components/BackgroundAsset/BackgroundAsset.types';

import BlueCloud from '@assets/backgrounds/blue-cloud.svg';
import YellowGate from '@assets/backgrounds/yellow-gate.svg';
import OrangeEllipse from '@assets/backgrounds/orange-ellipse.svg';
import PinkRock from '@assets/backgrounds/pink-rock.svg';
import GreenTwig from '@assets/backgrounds/green-twig.svg';

export const mobileAssetsList: Array<BackgroundAssetProps> = [
  {
    id: 0,
    asset: PinkRock,
    coordinates: {
      bottom: '-20px',
      right: '-140px',
    },
    scale: 0.5,
  },
  {
    id: 1,
    asset: OrangeEllipse,
    coordinates: {
      bottom: '40px',
      left: '-80px',
    },
    degRotate: -36,
    scale: 0.5,
  },
  {
    id: 2,
    asset: GreenTwig,
    coordinates: {
      bottom: '-80px',
      left: '100px',
    },
    scale: 2,
    degRotate: 2,
  },
  {
    id: 3,
    asset: BlueCloud,
    coordinates: {
      top: '-90px',
      left: '-190px',
    },
    degRotate: -20,
    scale: 0.5,
  },
  {
    id: 4,
    asset: YellowGate,
    coordinates: {
      top: '-65px',
      right: '-50px',
    },
    degRotate: -90,
    scale: 0.35,
  },
  {
    id: 5,
    asset: BlueCloud,
    coordinates: {
      top: '-75px',
      right: '-230px',
    },
    degRotate: -10,
    scale: 0.5,
  },
];

export const desktopAssetsList: Array<BackgroundAssetProps> = [
  {
    id: 0,
    asset: PinkRock,
    coordinates: {
      bottom: '-40px',
      right: '180px',
    },
  },
  {
    id: 1,
    asset: OrangeEllipse,
    coordinates: {
      bottom: '40px',
      left: '210px',
    },
    degRotate: -36,
  },
  {
    id: 2,
    asset: GreenTwig,
    coordinates: {
      bottom: '-20px',
      left: '100px',
    },
    scale: 2,
    degRotate: 2,
  },
  {
    id: 3,
    asset: BlueCloud,
    coordinates: {
      top: '124px',
      left: '150px',
    },
    degRotate: -20,
  },
  {
    id: 4,
    asset: YellowGate,
    coordinates: {
      top: '20px',
      right: '220px',
    },
    degRotate: -90,
    scale: 0.75,
  },
  {
    id: 5,
    asset: BlueCloud,
    coordinates: {
      top: '24px',
      right: '-40px',
    },
    degRotate: -10,
  },
];
