import jwtDecode from 'jwt-decode';

import { UserAuthContextProps } from '@context/Auth/Auth.types';
import _ from 'lodash';
import { removeStorageToken } from './storage';

interface TokenProps {
  sub: string;
  uuid: string;
  enabled: boolean;
  exp: number;
  is_admin: boolean;
  role: string;
}

const commonUserData = {
  image: null,
  profile: null,
};

export const decodeToken = (storageToken: string): UserAuthContextProps => {
  try {
    const token: TokenProps = jwtDecode(storageToken);
    const { sub, uuid, enabled, is_admin, role } = token;
    const dataProvider = {};
    return {
      ...commonUserData,
      id: uuid,
      email: sub,
      isVerified: enabled,
      isAdmin: is_admin,
      role: _.isArray(role) ? role[0] : '',
      ...dataProvider,
    };
  } catch (error) {
    removeStorageToken();

    return {
      ...commonUserData,
      id: null,
      email: null,
      isVerified: false,
      isAdmin: false,
      role: '',
    };
  }
};
