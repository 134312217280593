import { UserAuthContextProps } from './Auth.types';

export const initialUserData: UserAuthContextProps = {
  id: null,
  email: null,
  profile: null,
  isVerified: false,
  isAdmin: false,
  role: '',
};

export const defaultErrorResponseData = {
  status: 500,
  httpStatus: '',
  errorMessage: 'Server error',
};
