import { FormikValues } from 'formik';

export const joinWithSlash = (textArray: Array<string>) => textArray.join(' / ');

export const joinWithComma = (textArray: Array<string>) => textArray.join(', ');

export const joinWithAnd = (textArray: Array<string>) => textArray.join(' and ');

export const getValuesFromNestedTextName = (values: FormikValues, name: string) => {
  const valuePaths = name.split('.');

  const fullPathValue = valuePaths.reduce((acc, path) => {
    return acc ? acc[path] : '';
  }, values);

  return typeof fullPathValue === 'string' || typeof fullPathValue === 'boolean' || typeof fullPathValue === 'number'
    ? fullPathValue
    : '';
};
