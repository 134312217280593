import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useCloseOnRouteChange = (closeHandler: () => void) => {
  const { pathname } = useLocation();
  const [currentPath] = useState(pathname);

  useEffect(() => {
    if (pathname !== currentPath) {
      closeHandler();
    }
  }, [pathname]);
};

export default useCloseOnRouteChange;
