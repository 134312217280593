import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const useNotification = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const addResponseErrorNotification = (tMessage: string, responseMessage: string, status: number) => {
    if (status !== 403) {
      enqueueSnackbar(`${t(tMessage)}${responseMessage ? `: ${responseMessage}` : ''}`);
    }
  };

  return {
    addNotification: enqueueSnackbar,
    removeNotification: closeSnackbar,
    addResponseErrorNotification,
  };
};

export default useNotification;
