import ThumbUpIcon from '@assets/icons/thumb-up.svg';
import UsersIcon from '@assets/icons/users.svg';
import ConnectIcon from '@assets/icons/connect.svg';
import theme from '@theme';
import ActionCard from '@components/ActionCard';
import Typography from '@components/Typography';
import UserCard from '@components/UserCard';
import Icon from '@components/Icon';

import { RefObject } from 'react';
import { ENDORSE_PROVIDER_FORM_URL } from '@constants/urls';
import { EndorsementsList, FriendsList } from './UserList.types';
import * as Styles from './UserList.styles';
import { NO_ENDORSEMENTS_CONTENT_WRAPPER, NO_FRIENDS_CONTENT_WRAPPER } from './UserList.constants';

export const renderFriendCard = ({ handleCopyLink }: Pick<FriendsList, 'handleCopyLink'>) => (
  <ActionCard
    variant="blue"
    buttonIcon={ConnectIcon}
    buttonText="pages.dashboard.actionCards.friends.button"
    title="pages.dashboard.actionCards.friends.title"
    description="pages.dashboard.actionCards.friends.description"
    iconElement={<Icon icon={UsersIcon} stroke={theme.colors.white} />}
    clickHandler={handleCopyLink}
  />
);

export const renderEndorsementCard = ({ handleEndorse }: Pick<EndorsementsList, 'handleEndorse'>) => (
  <ActionCard
    variant="orange"
    buttonIcon={ThumbUpIcon}
    buttonText="pages.dashboard.actionCards.provider.button"
    title="pages.dashboard.actionCards.provider.title"
    description="pages.dashboard.actionCards.provider.description"
    iconElement={<Icon icon={ThumbUpIcon} fill={theme.colors.white} />}
    url={ENDORSE_PROVIDER_FORM_URL}
    clickHandler={handleEndorse}
  />
);

export const renderNoFriends = () => (
  <Styles.CenterWrapper data-component-locator={NO_FRIENDS_CONTENT_WRAPPER}>
    <Typography variant="body4" color={theme.colors.greyDark3} shouldTranslate>
      common.userList.empty.friends
    </Typography>
  </Styles.CenterWrapper>
);

export const renderNoEndorsements = () => (
  <Styles.CenterWrapper data-component-locator={NO_ENDORSEMENTS_CONTENT_WRAPPER}>
    <Typography variant="body4" color={theme.colors.greyDark3} shouldTranslate>
      common.userList.empty.endorsements
    </Typography>
  </Styles.CenterWrapper>
);

export const ParentSkeletonCard = () => (
  <UserCard variant="parent" avatar="" friendsCount={0} fullName="" parentInitials="" hasSkeleton />
);

export const ProviderSkeletonCard = () => (
  <UserCard
    variant="provider"
    avatar=""
    fullName=""
    locations=""
    providerLocations={[]}
    endorsedBy={{ friends: 0, me: false, others: 0, profiles: [], total: 0 }}
    providerTypes={[]}
    hasSkeleton
  />
);

export const getScrollElement = (ref: RefObject<HTMLDivElement>) => {
  if (ref.current) {
    const scrollBody = ref.current.querySelector('.MuiDialog-scrollBody');

    return scrollBody as Element;
  }

  return undefined;
};
