import { FC, useRef, useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LaptopIcon from '@assets/icons/laptop.svg';
import PinIcon from '@assets/icons/pin.svg';
import ThumbUpIcon from '@assets/icons/thumb-up.svg';
import theme from '@theme';
import Typography from '@components/Typography';
import Icon from '@components/Icon';
import AvatarGroup from '@components/AvatarGroup';
import Button from '@components/Buttons/Button';
import Skeleton from '@components/Loaders/Skeleton';
import { printPluralText } from '@utils/translation';
import { generateSrcUrl, getInitials } from '@utils/user';
import { joinWithSlash } from '@utils/text';
import useBreakpoint from '@hooks/useBreakpoint';

import { getStorageToken } from '@utils/storage';
import {
  UserCardProps,
  Parent,
  Provider,
  UserCardVariants,
  UserCardFactoryConfig,
  ParentInvite,
  SkeletonAvatarConfig,
} from './UserCard.types';
import * as Styles from './UserCard.styles';
import {
  AVATAR_GROUP_FRIENDS_MIN_COUNT,
  AVATAR_TEST_ID,
  INVITE_TEST_ID,
  NEIGHBORHOODS_ICON_WRAPPER_TEST_ID,
  NO_ENDORSEMENTS_TEST_ID,
  SPECIALTIES_WRAPPER_TEST_ID,
  USER_CARD_TEST_ID,
} from './UserCard.constants';
import { defaultEndorsedBy } from './UserCard.utils';

const UserCard: FC<UserCardProps> = ({
  avatar,
  fullName,
  variant,
  hasSkeleton = false,
  clickHandler = undefined,
  imageUuid,
  isSmall,
  ...props
}) => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoint();
  const token = getStorageToken();
  const profilePicture = generateSrcUrl(avatar, imageUuid);
  const virtualWrapperRef = useRef<HTMLDivElement>(null);
  const [virtualWidth, setVirtualWidth] = useState(0);

  const getVirtualWrapperWidth = useCallback(() => {
    setVirtualWidth(virtualWrapperRef.current?.offsetWidth || 0);
  }, [virtualWrapperRef.current?.offsetWidth]);

  useEffect(() => getVirtualWrapperWidth(), [virtualWrapperRef.current?.offsetWidth]);

  const renderParentContent = ({ friendsCount, shouldHideMutualFriends = false }: Parent) => {
    if (shouldHideMutualFriends) {
      return <></>;
    }

    return hasSkeleton ? (
      <Skeleton variant="text">
        <Typography variant="body4" shouldTranslate>
          common.loaders.content
        </Typography>
      </Skeleton>
    ) : (
      <Typography variant="body4" color={theme.colors.grey} letterSpacing="0.04em" disableUserSelect>
        {token && (
          <>
            {' '}
            {friendsCount}{' '}
            {printPluralText(t, {
              isPlural: friendsCount > 1 || friendsCount === 0,
              plural: 'common.userCard.mutualFriends',
              singular: 'common.userCard.mutualFriend',
            })}
          </>
        )}
      </Typography>
    );
  };

  const renderProviderContent = ({ providerTypes = [], locations = '', providerLocations = [] }: Provider) => {
    const isVirtual = (providerLocations || []).some((providerLocation) => providerLocation.virtual);

    return (
      <>
        <Styles.SpecialtiesWrapper data-component-locator={SPECIALTIES_WRAPPER_TEST_ID}>
          {hasSkeleton ? (
            <Skeleton variant="text">
              <Typography variant="body4" shouldTranslate>
                common.loaders.content
              </Typography>
            </Skeleton>
          ) : (
            <Typography variant="body4" color={theme.colors.greyDark2} disableUserSelect noWrap>
              {joinWithSlash(providerTypes)}
            </Typography>
          )}
        </Styles.SpecialtiesWrapper>
        <Styles.InlineWrapper $virtualWidth={virtualWidth}>
          {hasSkeleton ? (
            <Skeleton variant="text">
              <Typography variant="body5" shouldTranslate>
                common.loaders.content
              </Typography>
            </Skeleton>
          ) : (
            <>
              {isVirtual && (
                <Styles.InlineIconWrapper ref={virtualWrapperRef} $variant={variant}>
                  <Icon icon={LaptopIcon} />
                  <Typography variant="body5" color={theme.colors.greyDark3} shouldTranslate disableUserSelect>
                    common.userCard.virtual
                  </Typography>
                </Styles.InlineIconWrapper>
              )}
              <Styles.InlineIconWrapper
                data-component-locator={NEIGHBORHOODS_ICON_WRAPPER_TEST_ID}
                $variant={variant}
                $hasLocations
              >
                {locations && <Icon icon={PinIcon} />}
                <Typography variant="body5" color={theme.colors.greyDark3} disableUserSelect noWrap>
                  {locations}
                </Typography>
              </Styles.InlineIconWrapper>
            </>
          )}
        </Styles.InlineWrapper>
        {/* <Styles.AgesWrapper>
          {hasSkeleton ? (
            <Skeleton variant="text">
              <Typography variant="body5" shouldTranslate>
                common.loaders.content
              </Typography>
            </Skeleton>
          ) : (
            <>
              <Styles.InlineIconWrapper
                data-component-locator={NEIGHBORHOODS_ICON_WRAPPER_TEST_ID}
                $variant={variant}
                $hasLocations
              >
                <Typography variant="body5" color={theme.colors.blue} disableUserSelect noWrap>
                  Ages: Expecting - 3 mo  {minAge} - {maxAge} 
                </Typography>
              </Styles.InlineIconWrapper>
            </>
          )}
        </Styles.AgesWrapper> */}
      </>
    );
  };

  const config: UserCardFactoryConfig = {
    parent: () => renderParentContent(props as Parent),
    provider: () => renderProviderContent(props as Provider),
    parentInvite: () => renderParentContent(props as Parent),
  };

  const skeletonAvatarConfig: SkeletonAvatarConfig = {
    parent: 'circular',
    provider: 'provider',
    parentInvite: 'circular',
  };

  const cardContentFactory = (type: UserCardVariants) => config[type]();

  const renderSimpleEndorsements = (count = 0) => (
    <Styles.NoEndorsementsWrapper data-component-locator={NO_ENDORSEMENTS_TEST_ID}>
      <Styles.InlineIconWrapper $variant={variant}>
        {hasSkeleton ? (
          <Skeleton variant="circular">
            <Icon icon={ThumbUpIcon} />
          </Skeleton>
        ) : (
          <Icon icon={ThumbUpIcon} fill={theme.colors.greyDark3} transform={0.8} />
        )}
        {hasSkeleton ? (
          <Styles.NoEndorsementsSkeletonWrapper>
            <Skeleton variant="text">
              <Typography variant="body5" shouldTranslate>
                common.loaders.content
              </Typography>
            </Skeleton>
          </Styles.NoEndorsementsSkeletonWrapper>
        ) : (
          <>
            <Typography variant="body5" color={theme.colors.black} disableUserSelect>
              {count}
            </Typography>
            <Typography variant="body5" disableUserSelect>
              &nbsp;
            </Typography>
            <Typography variant="body5" color={theme.colors.greyDark3}>
              {printPluralText(t, {
                isPlural: count > 1 || count === 0,
                plural: 'common.userCard.endorsements',
                singular: 'common.userCard.endorsement',
              })}
            </Typography>
          </>
        )}
      </Styles.InlineIconWrapper>
    </Styles.NoEndorsementsWrapper>
  );

  const renderEndorsements = ({ endorsedBy = defaultEndorsedBy }: Provider) => {
    const { friends, others, total, profiles } = endorsedBy;

    if (friends === 0 && others >= 0) {
      return renderSimpleEndorsements(total);
    }

    const friendsText = printPluralText(t, {
      isPlural: friends > 1,
      plural: 'common.userCard.friends',
      singular: 'common.userCard.friend',
    });

    const othersText = printPluralText(t, {
      isPlural: others > 1,
      plural: 'common.userCard.others',
      singular: 'common.userCard.other',
    });

    const hasFriends = friends > 0;
    const hasOthers = others > 0;
    const hasProfiles = profiles.length > 0;
    const avatarGroupMax =
      profiles.length > AVATAR_GROUP_FRIENDS_MIN_COUNT ? profiles.length : AVATAR_GROUP_FRIENDS_MIN_COUNT;

    return (
      <>
        {hasProfiles && (
          <Styles.AvatarGroupWrapper>
            <AvatarGroup max={avatarGroupMax} total={avatarGroupMax} profiles={profiles} hasSkeleton={hasSkeleton} />
          </Styles.AvatarGroupWrapper>
        )}
        {hasSkeleton ? (
          <Skeleton variant="text">
            <Typography variant="body5" shouldTranslate>
              common.loaders.content
            </Typography>
          </Skeleton>
        ) : (
          <Typography variant="body5" color={theme.colors.black} disableUserSelect>
            {(hasFriends || hasOthers) && <Styles.AccentText>{t('common.userCard.endorsedBy')}</Styles.AccentText>}{' '}
            {hasFriends && (
              <>
                {friends} {friendsText}
              </>
            )}{' '}
            {hasFriends && hasOthers && <Styles.AccentText>{t('common.connectors.and')}</Styles.AccentText>}{' '}
            {hasOthers && (
              <>
                {others} {othersText}
              </>
            )}
          </Typography>
        )}
      </>
    );
  };

  const renderParentInviteButtons = ({ acceptHandler, declineHandler }: ParentInvite) => (
    <Styles.ParentInviteButtons data-component-locator={INVITE_TEST_ID}>
      <Button variant="textBox" color={theme.colors.blue} size="small" clickHandler={declineHandler} shouldTranslate>
        common.userCard.decline
      </Button>
      <Button variant="outlined" color={theme.colors.blue} size="small" clickHandler={acceptHandler} shouldTranslate>
        common.userCard.accept
      </Button>
    </Styles.ParentInviteButtons>
  );

  const renderParentInitials = ({ parentInitials }: Parent) => parentInitials;

  const renderProviderInitials = () => {
    const initials = (fullName || '').split(' ');
    return getInitials(initials[0], initials[1]);
  };

  const renderAvatar = () =>
    hasSkeleton ? (
      <Skeleton variant={skeletonAvatarConfig[variant]}>
        <Styles.Avatar $variant={variant} src="" alt="placeholder" />
      </Skeleton>
    ) : (
      <Styles.Avatar
        $variant={variant}
        src={profilePicture}
        alt={`${fullName} avatar`}
        data-component-locator={AVATAR_TEST_ID}
      />
    );

  const renderInitials = () =>
    hasSkeleton ? (
      <Skeleton variant={skeletonAvatarConfig[variant]}>
        <Styles.InitialsAvatar as="div" $variant={variant} />
      </Skeleton>
    ) : (
      <Styles.InitialsAvatar as="div" $variant={variant} data-component-locator={AVATAR_TEST_ID}>
        {variant === 'provider' ? renderProviderInitials() : renderParentInitials(props as Parent)}
      </Styles.InitialsAvatar>
    );

  return (
    <Styles.Card
      data-component-locator={USER_CARD_TEST_ID}
      onClick={
        variant === 'parentInvite' || hasSkeleton ? undefined : (props as ParentInvite).visitHandler || clickHandler
      }
    >
      <Styles.CardTopSection>
        <Styles.AvatarWrapper
          onClick={variant === 'parentInvite' || !hasSkeleton ? (props as ParentInvite).visitHandler : undefined}
        >
          {profilePicture ? renderAvatar() : renderInitials()}
        </Styles.AvatarWrapper>
        <Styles.Content $variant={variant}>
          {hasSkeleton ? (
            <Skeleton variant="text">
              <Typography variant="h6" shouldTranslate>
                common.loaders.fullName
              </Typography>
            </Skeleton>
          ) : (
            <Typography variant="h6" weight="bold" disableUserSelect noWrap>
              {fullName}
            </Typography>
          )}
          {cardContentFactory(variant)}
        </Styles.Content>
      </Styles.CardTopSection>
      {variant === 'provider' && !isSmall && (
        <>
          <Styles.Divider />
          <Styles.EndorsedByWrapper>{renderEndorsements(props as Provider)}</Styles.EndorsedByWrapper>
        </>
      )}
      {variant === 'parentInvite' && (
        <>
          {isMobile && <Styles.Divider $isInvisible />}
          {renderParentInviteButtons(props as ParentInvite)}
        </>
      )}
    </Styles.Card>
  );
};

export default UserCard;
