import { ReactElement } from 'react';

import { IconProps } from './Icon.types';
import { TEST_ID } from './Icon.constants';
import * as Styles from './Icon.styles';

const Icon = ({ icon: IconElement, fill, stroke, transform, rotate }: IconProps): any => (
  <Styles.IconContainer
    data-component-locator={TEST_ID}
    fill={fill}
    stroke={stroke}
    transform={transform}
    rotate={rotate}
  >
    <IconElement />
  </Styles.IconContainer>
);

export default Icon;
