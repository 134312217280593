import { styled } from '@mui/material/styles';
import { Dialog } from '@mui/material';

import { HEADER_HEIGHT } from '@components/Headers/Header/Header.constants';
import { MODAL_AVATAR_SIZE } from './Modal.constants';
import { CustomBackgroundProps, ModalStyleProps, NoPaddingProps, WithHeaderProps } from './Modal.types';

export const ModalWrapper = styled(Dialog)<NoPaddingProps & CustomBackgroundProps & WithHeaderProps>`
  top: ${({ $withHeader }) => ($withHeader ? `${HEADER_HEIGHT}px` : '0')};

  .MuiBackdrop-root {
    display: ${({ $withHeader }) => ($withHeader ? 'none' : 'block')};
  }

  .MuiPaper-root {
    width: 100%;
    min-width: 375px;
    max-width: ${({ theme }) => theme.breakpoints.values.tablet}px;
    min-height: ${({ $minHeight }) => $minHeight || '326px'};
    margin: 0;
    padding: ${({ theme, $noPadding }) => ($noPadding ? '0' : `0 ${theme.spacing(12)} ${theme.spacing(4)}`)};
    background: ${({ theme, $customBackground }) =>
      $customBackground ? theme.colors[$customBackground] : theme.customGradients.modal};
    box-shadow: ${({ theme, $withHeader }) => ($withHeader ? 'none' : theme.customShadows.menu)};
    overflow: visible;
    border-radius: ${({ $withHeader }) => ($withHeader ? '0' : '4px')};

    ${({ theme }) => theme.breakpoints.up('laptop')} {
      max-width: ${({ $maxWidth }) => ($maxWidth ? `${$maxWidth}px !important` : '870px')};
      width: 100%;
      margin: ${({ theme }) => theme.spacing(16)};
    }
  }
`;

export const ModalTitle = styled('header')<ModalStyleProps>`
  padding-top: ${({ $hasProfileImage, theme }) => ($hasProfileImage ? 0 : theme.spacing(14))};
`;

export const ProfileImageWrapper = styled('div')`
  max-width: ${MODAL_AVATAR_SIZE}px;
  max-height: ${MODAL_AVATAR_SIZE}px;
  margin: -${MODAL_AVATAR_SIZE / 2}px auto ${({ theme }) => theme.spacing(14)};

  > div {
    z-index: 1;
    margin-top: 0;
    max-width: ${MODAL_AVATAR_SIZE}px;
    max-height: ${MODAL_AVATAR_SIZE}px;

    > div {
      width: 100%;
      height: 100%;
    }
  }
`;

export const ModalContent = styled('div')`
  max-width: 100%;

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    margin-bottom: ${({ theme }) => theme.spacing(12)};
  }
`;

export const ModalActions = styled('footer')`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 2;

  button:first-child {
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }
`;

export const ModalClose = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  padding: ${({ theme }) => theme.spacing(12)};
  z-index: 15;
  cursor: pointer;
`;

export const ModalBack = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: ${({ theme }) => theme.spacing(12)};
  z-index: 15;
  cursor: pointer;
`;
