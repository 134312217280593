// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/GT-America-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/GT-America-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/GT-America-Bold-Italic.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'GT-America';\n  font-style: normal;\n  font-weight: normal;\n  src: local('GT-America'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff');\n}\n\n@font-face {\n  font-family: 'GT-America';\n  font-style: normal;\n  font-weight: bold;\n  src: local('GT-America'), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff');\n}\n\n@font-face {\n  font-family: 'GT-America';\n  font-style: italic;\n  font-weight: bold;\n  src: local('GT-America'), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\n}\n\nbody {\n  font-family: 'GT-America, sans-serif';\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  overflow-x: hidden;\n}\n\nhtml,\nbody,\n#root {\n  height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;EACnB,gFAAoF;AACtF;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;EACjB,gFAAiF;AACnF;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;EACjB,oFAA2F;AAC7F;;AAEA;EACE,qCAAqC;EACrC,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;;;EAGE,YAAY;AACd","sourcesContent":["@font-face {\n  font-family: 'GT-America';\n  font-style: normal;\n  font-weight: normal;\n  src: local('GT-America'), url(./assets/fonts/GT-America-Regular.woff) format('woff');\n}\n\n@font-face {\n  font-family: 'GT-America';\n  font-style: normal;\n  font-weight: bold;\n  src: local('GT-America'), url(./assets/fonts/GT-America-Bold.woff) format('woff');\n}\n\n@font-face {\n  font-family: 'GT-America';\n  font-style: italic;\n  font-weight: bold;\n  src: local('GT-America'), url(./assets/fonts/GT-America-Bold-Italic.ttf) format('truetype');\n}\n\nbody {\n  font-family: 'GT-America, sans-serif';\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  overflow-x: hidden;\n}\n\nhtml,\nbody,\n#root {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
