export const PARENT_AVATAR_SIZE = 64;
export const PROVIDER_AVATAR_WIDTH = 68;
export const PROVIDER_AVATAR_HEIGHT = 87;
export const PROVIDER_AVATAR_RADIUS = PROVIDER_AVATAR_WIDTH / 2;
export const AVATAR_GROUP_FRIENDS_MIN_COUNT = 2;
export const USER_CARD_MIN_HEIGHT = 88;
export const USER_CARD_MAX_HEIGHT = 161;
export const USER_CARD_PARENT_INVITE_HEIGHT = 135;

export const USER_CARD_TEST_ID = 'user-card';
export const AVATAR_TEST_ID = `${USER_CARD_TEST_ID}-avatar`;
export const SPECIALTIES_WRAPPER_TEST_ID = `${USER_CARD_TEST_ID}-specialties-wrapper`;
export const NEIGHBORHOODS_ICON_WRAPPER_TEST_ID = `${USER_CARD_TEST_ID}-neighborhoods-icon-wrapper`;
export const NO_ENDORSEMENTS_TEST_ID = `${USER_CARD_TEST_ID}-no-endorsements-wrapper`;
export const INVITE_TEST_ID = `${USER_CARD_TEST_ID}-invite-wrapper`;
