export default {
  forms: {
    errors: {
      fieldRequired: 'This field is required',
      validEmail: 'Provide a valid email address',
      validName: 'Provide a valid name',
      validUrl: 'Provide a valid Url',
      validWebsite: 'Provide a valid url address',
      validPassword: 'Password should have 8-20 characters',
      matchPassword: 'Confirm password does not match password',
      priceTo: 'The number must exceed Price From',
      validZipCode: 'Provide a valid zip code',
      validDate: 'Provide a valid date (MM/DD/YYYY)',
      minLength: 'This field requires minimum {{length}} characters',
      maxLength: 'This field requires maximum {{length}} characters',
    },
  },
};
