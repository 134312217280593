import { FC, useContext, useLayoutEffect, useState } from 'react';
import { LayoutContext } from '@context/Layout/Layout.context';
import * as Styles from './TermsPage.styles';
import '../../webFlow.css';

const TermsPage: FC = () => {
  const { updateLayout } = useContext(LayoutContext);
  useLayoutEffect(() => {
    updateLayout({
      showHeader: false,
      assets: [],
    });
  }, []);

  return (
    <Styles.ContentContainer>
      <body>
        EFFECTIVE DATE: March 01, 2022
        <br />
        <br />
        Welcome to ParentUp, a web application to directly connect with trusted providers and other parents. Please read
        these Terms of Use (the “Agreement” or “Terms”) carefully, as they contain the legal terms and conditions that
        govern your use and access of the website, https://parentup.com/ (the “Site”) and the mobile application (“App,”
        collectively with the Site, “ParentUp” or the “Platform”). This Agreement governs your use of ParentUp and
        constitutes a legally-binding agreement between each user (“you” or “your”) and ParentUp, Inc. d/b/a ParentUp
        (“we,” “us,” “our,” or the “Company”; collectively with “you” or “your,” the “parties”). By simply accessing or
        using ParentUp, including just browsing our Site, you are agreeing to our Terms, so please read carefully! If
        you do not agree to these Terms or any other policies, including our Privacy Policy, do not access or use
        ParentUp. If you have any questions about this Agreement please contact us at info@parentup.com.
        <br />
        <br />
        PLEASE NOTE THESE TERMS CONTAIN AN ARBITRATION AGREEMENT AND CLASS ACTION WAIVER THAT REQUIRE YOU TO ARBITRATE
        ALL DISPUTES YOU HAVE WITH US ON AN INDIVIDUAL BASIS.
        <ol type="1">
          <h2>
            <li>Acceptance</li>
          </h2>
          <ol type="A">
            <li>
              By using (or even just browsing) our Site, downloading our App, or uploading data or information to
              ParentUp (“Content”), you’re agreeing to the Terms and our associated Privacy Policy. If you don’t agree
              with the Terms, please refrain from using our Platform.
            </li>
          </ol>
          <h2>
            <li>Eligibility</li>
          </h2>
          <ol type="A">
            <li>
              We only permit individuals who are at least 18 years old and can form legally binding contracts with us to
              use ParentUp. If you are under 18 years old and would like to use ParentUp, you can, but only if a parent
              or legal guardian who is at least 18 years old supervises you. In all cases, the adult would be the user
              and is responsible for any and all activity.
            </li>
            <li>
              By using ParentUp, you represent and warrant that you meet all eligibility requirements outlined in these
              Terms. We reserve the right to refuse to let certain people access or use ParentUp and the right to change
              our eligibility criteria.
            </li>
            <li>
              You can only use ParentUp to the extent the laws of your jurisdiction or the United States do not bar you
              from doing so. Please make sure these Terms are in compliance with all laws, rules, and regulations that
              apply to you.
            </li>
          </ol>
          <h2>
            <li>Your Privacy</li>
          </h2>
          <ol type="A">
            <li>
              We know your personal information is important to you, so it’s important to us. Our Privacy Policy details
              how your information is used when you use our Platform, and it’s also a part of our Terms, so please
              carefully read it at here.
            </li>
          </ol>
          <h2>
            <li>Creating an Account</li>
          </h2>
          <ol type="A">
            <li>
              On ParentUp, we allow various Providers to create a profile with information about them as well as their
              offering details. Offering details can include a one-time service or a longer term engagement. We also
              create Provider profiles through Parent endorsements and may not be directly managed by a Provider. You
              need to create an account on ParentUp in order to browse through our Providers.
              <br />
              <br />
              In order to create an account for ParentUp you’ll need to provide us with your name, email address, and
              phone number. You can add your zipcode, your relevant neighborhoods, your photo, your bio, your child’s
              name, and your child’s birthday. This information is stored in the ParentUp database. By creating an
              account, you’re agreeing to a few common sense rules and acknowledgements:
            </li>
            <ol type="i">
              <li>
                By creating an account, you’re agreeing to provide accurate and complete information about yourself.
                It’s not OK to use false information or impersonate another person or company through your account;
              </li>
              <li>
                A respectful community is important to us. We ask that you respect others in the community. This will
                allow our community to continue to thrive and be a positive space for everyone here. If we find out that
                you are negatively impacting the experience of fellow ParentUp users, we reserve the right to terminate
                your account;
              </li>
              <li>You’re solely responsible for any activity on your account. Your accounts are not transferable.</li>
            </ol>
          </ol>
          <h2>
            <li>Email Communication</li>
          </h2>
          <ol type="A">
            <li>
              By creating an account with ParentUp, you automatically opt in to receive email communications regarding
              ParentUp. Some of those emails may relate to the age of your child that you entered during your
              registration or in your profile on ParentUp. Other emails may be promotional in nature. You can opt-out of
              receiving our promotional emails by clicking an unsubscribe link found at the bottom of our promotional
              emails.
            </li>
          </ol>
          <h2>
            <li>Password &amp; Security</li>
          </h2>
          <ol type="A">
            <li>
              You’re responsible for safeguarding the password that you use to access ParentUp and for any activities or
              actions under your password. We require you to use “strong” passwords (passwords that use a combination of
              upper and lower case letters, numbers, and symbols) with your account. We cannot and will not be liable
              for any loss or damage arising from your failure to comply with the above. Please contact
              info@parentup.com to answer any questions you have about signing up for and registering an account with
              ParentUp.
            </li>
          </ol>
          <h2>
            <li>No Agency Relationship</li>
          </h2>
          <ol type="A">
            <li>
              By creating an account with ParentUp, or using our Platform, you are not creating an agency, partnership,
              joint venture, employment, or franchisee relationship with our Company.
            </li>
          </ol>
          <h2>
            <li>Third Party Services and Content</li>
          </h2>
          <ol type="A">
            <li>
              We often utilize links and other tools to connect users to third party services and websites, such as
              Providers, advertisers, special offers, classes, camps, or other events that are not owned or controlled
              by ParentUp (individually a “Third Party Service,” collectively the “Third Party Services”). We do not
              control the content and policies of these Third Party Services, and in no event shall we be held
              responsible or liable for the accuracy, reliability, or currentness of any third party’s content or
              policies. Users who access or use Third Party Services through ParentUp are solely responsible for
              complying with the terms and policies of these third parties. In no event shall a description or reference
              to a third party’s product or service (including, but not limited to, providing a description or reference
              via hyperlink) be construed as an endorsement of such third party products or services by us. We retain
              the exclusive right to add to, modify, or cancel the availability of any Third Party Service.
            </li>
          </ol>
          <h2>
            <li>Representations and Warranties</li>
          </h2>
          <ol type="A">
            <li>
              You have read and understood our Privacy Policy. If you use the Service outside of the United States of
              America, you consent to having your personal data transferred to and processed in the United States of
              America.
            </li>
            <li>
              You also represent and warrant that you will not, and will not assist, encourage, or enable others to use
              ParentUp to:
              <ol type="i">
                <li>Violate our Terms of Use;</li>
                <li>
                  Violate any third party’s rights, including any breach of confidence, copyright, trademark, patent,
                  trade secret, moral right, privacy right, right of publicity, or any other intellectual property or
                  proprietary right;
                </li>
                <li>Violate any applicable law;</li>
                <li>
                  Remove or modify any copyright, trademark, or other proprietary rights (collectively, “Proprietary
                  Information”) notice that appears on any portion of ParentUp;
                </li>
                <li>
                  Use any software to “crawl,” “scrape,” or “spider” ParentUp or to reverse engineer or attempt to
                  obtain our source code or data;
                </li>
                <li>
                  Attempt to gain unauthorized access to ParentUp, accounts, computer systems or networks connected to
                  ParentUp through hacking, password mining or any other means;
                </li>
                <li>
                  Use ParentUp to transmit any computer viruses, worms, defects, Trojan horses, malicious code, spyware,
                  malware or other items of a destructive or harmful nature;
                </li>
                <li>
                  Take any action that imposes, or may impose, in our sole discretion, an unreasonable or
                  disproportionately large load on ParentUp’s technology infrastructure or otherwise make excessive
                  traffic demands of our platform; or
                </li>
                <li>
                  Use the name “ParentUp” and other trademarks, service marks, phrases, logos and designs that we use in
                  connection with our Platform, without our permission or unless indicated.
                </li>
              </ol>
            </li>
          </ol>
          <h2>
            <li>Termination</li>
          </h2>
          <ol type="A">
            <li>
              We may terminate or suspend your account and your access to ParentUp at any time, for any reason, without
              advance notice. If we do so, it’s important to understand that you don’t have a contractual or legal right
              to continue to use our Platform. ParentUp may refuse to allow anyone to use the Platform, at any time, for
              any reason.
            </li>
            <li>
              If we terminate your account, you must immediately stop using ParentUp and you agree not to attempt to
              regain access to our Platform without our express permission. Such termination will result in the
              suspension or deletion of your account and access to your account. In the event of termination of your
              account, the provisions of this Agreement shall remain in effect.
            </li>
            <li>
              If you terminate your account, or if we terminate your account, you may lose any information associated
              with your account, including your Content and friend connections.
            </li>
          </ol>
          <h2>
            <li>Disclaimers and Limitations of Liability</li>
          </h2>
          Please read this section carefully since its limits the liability of ParentUp to you. Additionally, you
          understand and agree that we have no control over, and no duty to take any action regarding: What Providers
          you access via our Platform; or What actions you may take as a result of your exposure to or use of ParentUp.
          You release us from all liability related to your use of ParentUp, including any liability resulting from any
          content posted on your ParentUp account, or any Providers you interact with. We make no representations
          concerning any content contained in or accessed through our Platform and will not be responsible or liable for
          the accuracy, copyright compliance, legality, or decency of material contained in or accessed through ParentUp
          by our users or Providers. By accessing or using the service, you represent that you have read, understood,
          and agree to these terms, including this section. You are agreeing substantial legal rights by agreeing to
          these terms.
          <br />
          <br />
          <ol type="A">
            <li>
              YOU UNDERSTAND AND AGREE THAT USING PARENTUP IS AT YOUR SOLE RISK AND WE MAKE NO REPRESENTATIONS OR
              WARRANTIES WITH RESPECT TO PARENTUP, THE ACCURACY OR COMPLETENESS OF ANY INFORMATION OR MATERIALS
              PUBLISHED ON PARENTUP, INCLUDING ANY INFORMATION OR MATERIALS RELATED TO OFFERINGS OR PROVIDERS OR ANY
              OTHER ACTIVITY REFERENCED IN THIS AGREEMENT, ALL OF WHICH ARE PROVIDED “AS IS” AND “AS AVAILABLE.” TO THE
              FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS, IMPLIED, OR
              STATUTORY, INCLUDING ANY WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, AVAILABILITY, ACCURACY, COMPLETENESS, SECURITY, COMPATIBILITY, RELIABILITY, OR OTHERWISE, WITH
              RESPECT TO THE PARENTUP PLATFORM.
            </li>
            <li>
              PARENTUP DOES NOT AND OUR OFFICERS, DIRECTORS, REPRESENTATIVES, AGENTS, SERVANTS, COUNSEL, EMPLOYEES,
              CONSULTANTS, AND OTHER PERSONNEL AUTHORIZED TO ACT, ACTING, OR PURPORTING TO ACT ON OUR BEHALF (THE
              “PARENTUP PARTIES”) WARRANT THAT PARENTUP WILL BE ERROR-FREE OR THAT DEFECTS WITH PARENTUP WILL BE
              CORRECTED. MOREOVER, YOU EXPRESSLY AGREE THAT YOUR USE OF THIS SITE AND ANY THIRD-PARTY SOFTWARE ON
              PARENTUP IS AT YOUR OWN RISK. THE PARENTUP PARTIES DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
              RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A PROVIDER OR THIRD PARTY THROUGH
              PARENTUP OR ANY HYPERLINKED SERVICES FEATURED IN ANY BANNER OR OTHER ADVERTISING.
            </li>
            <li>
              NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM US WILL CREATE ANY WARRANTY NOT EXPRESSLY
              MADE HEREIN.
            </li>
            <li>
              TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, PARTICIPATION IN ANY PROVIDER SERVICES THAT
              WERE POSTED TO PARENTUP IS SOLELY AT YOUR OWN RISK. IN NO EVENT SHALL THE PARENTUP PARTIES BE LIABLE FOR
              ANY ACT, ERROR, OR OMISSION BY ANY PROVIDER OR OTHER THIRD PARTY. WE ARE NOT AN AGENT OF ANY PROVIDER OR
              OTHER THIRD PARTY.
            </li>
            <li>
              IN THE EVENT THAT YOU HAVE A DISPUTE WITH A PROVIDER, YOU AGREE TO RELEASE THE PARENTUP PARTIES FROM ANY
              AND ALL CLAIMS, DEMANDS, OR DAMAGES (ACTUAL OR CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN,
              SUSPECTED AND UNSUSPECTED, DISCLOSED AND UNDISCLOSED, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH
              DISPUTES. WE EXPRESSLY DISCLAIM ANY LIABILITY THAT MAY ARISE BETWEEN YOU AND PROVIDER.
            </li>
            <li>WE EXPLICITLY DISCLAIM ALL LIABILITY FOR ANY ACT OR OMISSION OF ANY PROVIDER OR OTHER THIRD PARTY.</li>
            <li>
              SOME STATES DO NOT ALLOW THE DISCLAIMER OF IMPLIED WARRANTIES, SO THE FOREGOING DISCLAIMERS MAY NOT APPLY
              TO YOU. THIS PARAGRAPH GIVES YOU SPECIFIC LEGAL RIGHTS AND YOU MAY ALSO HAVE OTHER LEGAL RIGHTS THAT VARY
              FROM STATE TO STATE.
            </li>
            <li>
              TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL WE OR ANY OF THE PARENTUP PARTIES BE
              LIABLE TO YOU FOR ANY SPECIAL, INDIRECT, INCIDENTAL, OR CONSEQUENTIAL DAMAGES UNDER ANY THEORY OF
              LIABILITY, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE AND PRODUCT LIABILITY), OR OTHERWISE,
              EVEN IF PARENTUP HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. APPLICABLE LAW MAY NOT ALLOW THE
              LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR
              EXCLUSION MAY NOT APPLY TO YOU. IN SUCH CASES, OUR LIABILITY WILL BE LIMITED TO THE FULLEST EXTENT
              PERMITTED BY APPLICABLE LAW.
            </li>
            <li>
              THESE LIMITATIONS APPLY REGARDLESS OF LEGAL THEORY, WHETHER BASED ON TORT, STRICT LIABILITY, BREACH OF
              CONTRACT, BREACH OF WARRANTY, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT WE WERE ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES.
            </li>
            <li>
              YOU SPECIFICALLY ACKNOWLEDGE THAT WE AND THE PARENTUP PARTIES SHALL NOT BE LIABLE FOR CONTENT OR THE
              DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY PROVIDER OR THIRD PARTY AND THAT THE RISK OF HARM OR
              DAMAGE FROM THE FOREGOING RESTS ENTIRELY WITH YOU. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
              LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY.
            </li>
          </ol>
          <h2>
            <li>Arbitration, Disputes, and Choice of Law</li>
          </h2>
          <ol type="A">
            <li>
              You agree to arbitrate any dispute with us arising from this Agreement or your use of ParentUp on an
              individual basis in an arbitration. That means you cannot sue us in court or have a trial by jury. Any
              disputes will be resolved individually in a private proceeding.
            </li>
            <li>
              The parties agree that: (i) any arbitration will occur in New York, New York; and (ii) the arbitration
              will be conducted confidentially by a single arbitrator in accordance with the rules of the American
              Arbitration Association for arbitration of Consumer-Related Disputes, in the English language, and with
              limited discovery.
            </li>
            <li>
              At your request, hearings may be conducted in person or by telephone and the arbitrator may provide for
              submitting and determining motions on briefs, without oral hearings. Other than the class procedures and
              remedies discussed below, the arbitrator has the authority to grant any remedy that would otherwise be
              available to a court or other tribunal.
            </li>
            <li>
              This Agreement shall be governed by, subject to, and interpreted in accordance with the laws of the State
              of New York, and the federal laws of the United States, without regard to conflict of laws principles. You
              waive any objection based on lack of personal jurisdiction, place of residence, improper venue, or forum
              non conveniens in any such action.
            </li>
            <li>
              THE PREVAILING PARTY IN ANY ACTION OR PROCEEDING TO ENFORCE THESE TERMS SHALL BE ENTITLED TO COSTS AND
              ATTORNEYS’ FEES. THE ARBITRAL DECISION MAY BE ENFORCED IN ANY COURT.
            </li>
            <li>
              WHETHER THE DISPUTE IS HEARD IN ARBITRATION OR IN COURT, THE PARTIES WILL NOT COMMENCE AGAINST THE OTHER A
              CLASS ACTION, CLASS ARBITRATION, OR REPRESENTATIVE ACTION OR PROCEEDING.
            </li>
            <li>
              If for any reason a claim proceeds in court rather than in arbitration, the parties agree that the claim
              will be brought exclusively in a federal court located in New York, New York. The parties expressly
              consent to the exclusive jurisdiction of, and waive any and all objections to venue or jurisdiction.
              However, either party may seek injunctive or other equitable relief to protect its intellectual property
              rights and any claims regarding infringement or misappropriation of its intellectual property rights in
              any court of competent jurisdiction.
            </li>
          </ol>
          <h2>
            <li>Indemnity</li>
          </h2>
          You understand that we are not a Provider and any services listed on ParentUp are operated and delivered by
          the respective Providers. Although we hope Providers only offer the highest quality services, we are not
          responsible for the quality, substance, or performance of any of the services listed on the Platform. You
          understand that there are certain inherent risks and dangers in attending or participating in the services
          listed on ParentUp. By signing up for a ParentUp account you acknowledge and agree, on behalf of yourself,
          your heirs, personal representatives and/or assigns that you are aware of these risks which include, but are
          not limited to, property damage, illness, and bodily injury or death. You acknowledge that some of these risks
          cannot be eliminated and you specifically assume the risk of injury or harm to you or your child. You
          acknowledge and agree that it is your responsibility to consult with your primary physician or child’s primary
          care physician prior to participating in a service and to determine whether a service is appropriate for you
          or your child. You also understand and agree that ParentUp may offer parent-related or child-related
          information that is designed for informational, educational, and entertainment purposes only. The use of any
          of the information provided on ParentUp is solely at your own risk.
          <br />
          <br />
          To the fullest extent permitted by law, you agree to release, defend, indemnify, and hold harmless the
          ParentUp Parties from any and all responsibility, claims, actions, suits, procedures, costs, expenses,
          damages, obligations, losses, costs, debts, and liabilities arising out of or in any way related to: (i) your
          use of ParentUp; (ii) you or your child’s participation in any service listed through ParentUp, including with
          respect to bodily injury, physical harm, illness, death or property damage; (ii) your violation of any terms
          of this Agreement; (iii) any infringement or misappropriation of a third party’s intellectual property rights;
          or (iv) your violation of any law, rule or regulation, or the rights of any third party.
          <h2>
            <li>General Terms</li>
          </h2>
          <ol type="A">
            <li>
              We reserve the right to change, suspend, or discontinue ParentUp at any time, for any reason, and without
              notice. We will not be liable to you for the effect that any changes to the Platform may have on you and
              you may not be able to recover any Content that you posted to ParentUp.
            </li>
            <li>
              The Terms contain the entire agreement between you and us regarding the use of ParentUp, and supersede any
              prior agreement between you and us on such subject matter. The parties acknowledge that no reliance is
              placed on any representation made but not expressly contained in these Terms.
            </li>
            <li>
              Any failure on ParentUp’s part to exercise or enforce any right or provision of the Terms does not
              constitute a waiver of such right or provision. The failure of either party to exercise in any respect any
              right provided for herein shall not be deemed a waiver of any further rights hereunder. The Terms may not
              be waived, except pursuant to a writing executed by ParentUp.
            </li>
            <li>
              If any provision of the Terms is found to be unenforceable or invalid by an arbitrator or court of
              competent jurisdiction, then only that provision shall be modified to reflect the parties’ intention or
              eliminated to the minimum extent necessary so that the Terms shall otherwise remain in full force and
              effect and enforceable
            </li>
            <li>
              The Terms, and any rights or obligations hereunder, are not assignable, transferable or sublicensable by
              you except with Yelp’s prior written consent, but may be assigned or transferred by us without
              restriction. Any attempted assignment by you shall violate these Terms and be void.
            </li>
            <li>
              You agree that no joint venture, partnership, employment, agency, special or fiduciary relationship exists
              between you and ParentUp as a result of these Terms or your use of ParentUp.
            </li>
            <li>The section titles in the Terms are for convenience only and have no legal or contractual effect.</li>
            <li>
              ParentUp will not be held liable for any delays, failure in performance, or interruption of service which
              result directly or indirectly from any cause or condition beyond our reasonable control, including, but
              not limited to, any delay or failure due to any act of God, act of civil or military authorities, act of
              terrorism, civil disturbance, war, strike, or other labor dispute, fire, interruption in
              telecommunications or Internet services or network provider services, failure of equipment and/or
              software, or other catastrophe or any other occurrence which is beyond our reasonable control and shall
              not affect the validity and enforceability of any remaining provisions.
            </li>
          </ol>
        </ol>
        <h2>Questions?</h2>
        We’d be happy to answer them. Feel free to send us an email:
        <br />
        <br />
        Email: info@parentup.com
        <br />
        <br />
        EFFECTIVE DATE: March 01, 2022
      </body>
    </Styles.ContentContainer>
  );
};

export default TermsPage;
