import { styled, css } from '@mui/material/styles';

import { ImageProps } from './UserProfilePicture.types';

const ProfilePicture = styled('div')<ImageProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: ${({ $variant }) => ($variant === 'parent' ? '224px' : '225px')};
  height: ${({ $variant }) => ($variant === 'parent' ? '224px' : '291px')};
  border-radius: ${({ $variant }) => ($variant === 'parent' ? '50%' : '6px 6px 210px 210px')};
  margin-top: ${({ theme }) => theme.spacing(8)};
  box-shadow: ${({ theme }) => theme.customShadows.profilePicture};
  background-color: ${({ theme }) => theme.colors.green};

  // &::before {
  //   content: '';
  //   width: calc(100% + 8px);
  //   height: calc(100% + 8px);
  //   position: absolute;
  //   inset: 0 0 0 -4px;
  //   margin: auto;
  //   z-index: -1;
  //   background-color: ${({ theme }) => theme.colors.green};
  //   border-radius: 50%;
  // }

  ${({ $variant }) =>
    $variant === 'provider' &&
    css`
      ::before {
        content: none;
      }
    `}

  ${({ theme }) => theme.breakpoints.up('tablet')} {
    align-self: flex-start;
  }
`;

export const ImageEditorContainer = styled(ProfilePicture)`
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.greyDark4};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: relative;

  ::before {
    content: none;
  }

  :hover,
  :active {
    background-color: ${({ theme }) => theme.colors.greyDark3};
  }
`;

export const ImageFile = styled('input')`
  display: none;
`;

export const ImageContainer = styled(ProfilePicture)`
  position: relative;
`;

export const ProfileImage = styled('img')<ImageProps>`
  max-width: 100%;
  width: 100%;
  height: 100%;
  border-radius: ${({ $variant }) => ($variant === 'parent' ? '50%' : '6px 6px 210px 210px')};
  position: relative;
  border: 3px solid ${({ theme }) => theme.colors.green};
`;

export const ProfileImageActions = styled('div')<ImageProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: ${({ $variant }) => ($variant === 'parent' ? '50%' : '6px 6px 210px 210px')};
  display: flex;
  justify-content: center;
  align-items: flex-end;
  opacity: 1;
  transition: all 0.1s ease-in-out;
  background: ${({ theme }) => theme.customGradients.profilePicture};

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    opacity: 0;
  }

  :hover {
    opacity: 1;
  }
`;

export const ProfileImageIconContainer = styled('div')`
  cursor: pointer;
  margin: 0 ${({ theme }) => `${theme.spacing(8)} ${theme.spacing(15)}`};
  padding: 5px;
`;

export const EmptyProfilePicture = styled(ProfilePicture)`
  background-color: ${({ theme }) => theme.colors.green};
`;

export const InitialsWrapper = styled(ProfilePicture)`
  margin-top: 0;
  background-color: ${({ theme }) => theme.colors.green};
  user-select: none;

  ::before {
    content: none;
  }
`;
