import { Fragment, ReactElement, useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Typography from '@components/Typography';
import Button from '@components/Buttons/Button';
import Icon from '@components/Icon';
import theme from '@theme';
import useNotification from '@hooks/useNotification';
import { AuthContext } from '@context/Auth/Auth.context';
import { copyProfileLink, copyProviderLink } from '@utils/user';

import * as Routes from '@constants/routes';
import { use } from 'i18next';
import userService from '@services/User/User.service';
import { setStorageToken } from '@utils/storage';
import ProviderOnboardingService from '@services/Provider/ProviderOnbarding/ProviderOnboarding.service';
import CopyLink from '@components/Buttons/CopyLink';
import { menuItems } from './Menu.utils';
import { TEST_ID } from './Menu.constants';
import { ActionType, MenuProps, PassedActionConfig, RenderMenuItemArgs, RenderMenuLinkArgs } from './Menu.types';
import * as Styles from './Menu.styles';

const Menu = ({ handleMenuClose, handleOpenMyFriendsList, handleOpenMyEndorsementsList }: MenuProps): ReactElement => {
  const { pathname } = useLocation();
  const { user, logout, refreshProfile } = useContext(AuthContext);
  const navigate = useNavigate();
  const { addNotification } = useNotification();
  const { t } = useTranslation();
  const [currentPath] = useState(pathname);

  const handleLogout = () => {
    logout(false);
    handleMenuClose();
    addNotification(t('common.menu.logoutMessage'));
  };

  const handleCopyLink = () => {
    const profileId = user?.id;

    if (profileId) {
      copyProfileLink(profileId);
      addNotification(t('common.copy.linkCopiedMessage'));
      return;
    }

    addNotification(t('common.copy.copyLinkError'));
  };

  const handleCopyProviderLink = () => {
    const profileId = user?.profile?.providerId;
    if (profileId) {
      ProviderOnboardingService.getProviderLink(profileId)
        .then(({ data }: Record<string, any>) => {
          if (data) {
            copyProviderLink(data);
          } else {
            copyProviderLink(profileId);
          }
          addNotification(t('common.copy.linkCopiedMessage'));
        })
        .catch(() => {
          copyProviderLink(profileId);
          addNotification(t('common.copy.linkCopiedMessage'));
        });
    } else {
      addNotification(t('common.copy.copyLinkError'));
    }
  };

  const handleOpenMyFriends = () => {
    handleMenuClose();
    handleOpenMyFriendsList();
  };

  const handleBecomeProvider = () => {
    userService
      .becomeProvider()
      .then(({ data }: any) => {
        if (data.token) {
          navigate(Routes.HOME);
          setStorageToken(data.token);
          refreshProfile(data.token);
        }
      })
      .catch(({ errorMessage }) => {
        addNotification(
          `${t('pages.addProvider.responses.invalid.becomeProvider')}${errorMessage ? `: ${errorMessage}` : ''}`,
        );
      });
  };

  const handleOpenMyEndorsements = () => {
    handleMenuClose();
    handleOpenMyEndorsementsList();
  };

  const passedActionConfig: PassedActionConfig = {
    logout: handleLogout,
    copyLink: handleCopyLink,
    copyProviderLink: handleCopyProviderLink,
    myFriends: handleOpenMyFriends,
    myEndorsements: handleOpenMyEndorsements,
    becameProvider: handleBecomeProvider,
  };

  const handlePassedAction = (action: ActionType) => {
    if (action) {
      return passedActionConfig[action];
    }

    return undefined;
  };

  useEffect(() => {
    if (pathname !== currentPath) {
      handleMenuClose();
    }
  }, [pathname]);

  const renderLink = ({ icon, text }: RenderMenuLinkArgs) => (
    <Styles.ElementContainer>
      {icon && (
        <Styles.IconContainer>
          <Icon icon={icon} />
        </Styles.IconContainer>
      )}
      <Typography variant="body3" shouldTranslate disableUserSelect>
        {text}
      </Typography>
    </Styles.ElementContainer>
  );

  const renderMenuItem = ({ icon, text, route, action }: RenderMenuItemArgs) =>
    action ? (
      <Styles.MenuItem onClick={handlePassedAction(action)}>{renderLink({ icon, text })}</Styles.MenuItem>
    ) : (
      <Styles.MenuItem>
        <Link to={(route as string) || ''}>{renderLink({ icon, text })}</Link>
      </Styles.MenuItem>
    );

  return (
    <Styles.Menu data-component-locator={TEST_ID}>
      {menuItems(user.role).map(({ id, variant, text, icon, isCopy, route, type, action }) => (
        <Fragment key={id}>
          {type === 'element' && renderMenuItem({ text, icon, route, action })}
          {type === 'button' && (
            <Styles.MenuButton>
              <Styles.ButtonContainer>
                {isCopy && <CopyLink>{text}</CopyLink>}
                {!isCopy && (
                  <Button
                    leftIcon={icon || undefined}
                    variant={variant}
                    size="medium"
                    color={variant === 'filled' ? theme.colors.white : theme.colors.blue}
                    fontVariant="body3"
                    clickHandler={handlePassedAction(action)}
                    shouldTranslate
                  >
                    {text}
                  </Button>
                )}
              </Styles.ButtonContainer>
            </Styles.MenuButton>
          )}
        </Fragment>
      ))}
    </Styles.Menu>
  );
};

export default Menu;
