import { SnackbarProvider } from 'notistack';
import { FC, ReactText, ReactNode } from 'react';

import Notification from '@components/Notification';

const renderNotification = (key: ReactText, message: ReactNode) => <Notification id={key} message={message} />;

const NotificationsProvider: FC = ({ children }) => {
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      maxSnack={2}
      content={(key, message) => renderNotification(key, message)}
    >
      {children}
    </SnackbarProvider>
  );
};

export default NotificationsProvider;
