import { useLocation } from 'react-router-dom';

const useQuery = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const getParam = (param: string) => params.get(param);

  const getAllParams = (param: string) => params.getAll(param);

  return {
    getParam,
    getAllParams,
  };
};

export default useQuery;
