import { useEffect } from 'react';

import { rootElement } from '@utils/app';

type ScrollableElements = 'body' | 'root';

interface Options {
  scrollableElement?: ScrollableElements;
}

const useHideScroll = (
  shouldHideScroll: boolean,
  dependencies: Array<boolean>,
  { scrollableElement = 'body' }: Options = {},
) => {
  const hideScroll = () => {
    if (scrollableElement === 'root') {
      rootElement.style.setProperty('overflow', 'hidden');
      return;
    }

    document.body.style.setProperty('overflow', 'hidden');
  };

  const showScroll = () => {
    if (scrollableElement === 'root') {
      rootElement.removeAttribute('style');
      return;
    }

    document.body.removeAttribute('style');
  };

  useEffect(() => {
    if (shouldHideScroll) {
      hideScroll();
      return;
    }

    showScroll();
  }, [dependencies]);
};

export default useHideScroll;
