import { useState, useEffect, RefObject, ChangeEvent } from 'react';

interface HookProps {
  textAreaRef: RefObject<HTMLTextAreaElement>;
  parentRef: RefObject<HTMLElement>;
}

interface HookReturnType {
  textAreaHeight: string;
  parentHeight: string;
  onChangeHandler: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

const useAutoTextArea = ({ textAreaRef, parentRef }: HookProps): HookReturnType => {
  const [text, setText] = useState('');
  const [textAreaHeight, setTextAreaHeight] = useState<string>('auto');
  const [parentHeight, setParentHeight] = useState<string>('auto');

  useEffect(() => {
    if (parentRef.current === null || textAreaRef.current === null) {
      return;
    }

    setParentHeight(`${parentRef.current.scrollHeight}px`);
    setTextAreaHeight(`${textAreaRef.current.scrollHeight}px`);
  }, [text]);

  const onChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (textAreaRef.current === null) {
      return;
    }

    setTextAreaHeight('auto');
    setParentHeight(`${textAreaRef.current.scrollHeight}px`);
    setText(event.target.value);
  };

  return { textAreaHeight, parentHeight, onChangeHandler };
};

export default useAutoTextArea;
