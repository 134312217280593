import { TFunction } from 'i18next';

interface PrintPluralTextArgs {
  isPlural: boolean;
  plural: string;
  singular: string;
}

export const renderTranslationText = (t: TFunction, text: React.ReactNode | string) => {
  const isChildStringType = typeof text === 'string';
  const translateString = text as string;

  return isChildStringType ? t(translateString) : text;
};

export const printPluralText = (t: TFunction, { isPlural, plural, singular }: PrintPluralTextArgs) =>
  isPlural ? t(plural) : t(singular);
