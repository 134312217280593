import { RefObject, useEffect } from 'react';

const useOnClickOutside = (ref: RefObject<any>, handler: () => void, optionalRef?: RefObject<any>) => {
  useEffect(() => {
    const listener = ({ target }: MouseEvent | TouchEvent) => {
      if (
        ref.current === null ||
        ref.current.contains(target) ||
        (typeof optionalRef !== 'undefined' && optionalRef.current === null) ||
        optionalRef?.current.contains(target)
      ) {
        return;
      }

      handler();
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
