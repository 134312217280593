import { TypographyVariants } from '@theme/typography';

import { ButtonSize } from './Button.types';

export const generateFontVariant = (size: ButtonSize) => {
  const sizes = {
    small: 'body3',
    medium: 'body4',
    big: 'body2',
  };

  return (sizes[size] || sizes.big) as TypographyVariants;
};

export const generateButtonPadding = (size: ButtonSize) => {
  const paddings = {
    small: 2,
    medium: 4,
    big: 8,
  };

  return paddings[size] || paddings.big;
};
