import InstagramLogo from '@assets/icons/instagram.svg';
import LinkedInLogo from '@assets/icons/linkedIn.svg';
import { CONTACT_FORM_URL, PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL } from '@constants/urls';

import * as RouteNames from '@constants/routes';
import { DrawerMenuConfig, DrawerMenuItems, SocialsMenu, SocialsMenuItems, TransitionStyles } from './DrawerMenu.types';

const supportMenuItems: DrawerMenuItems = [
  {
    id: '0-about',
    name: 'common.drawerMenu.about',
    action: null,
    url: RouteNames.ABOUT,
    isExternal: false,
  },
  {
    id: '1-for-providers',
    name: 'common.drawerMenu.forProvider',
    action: null,
    url: RouteNames.FOR_PROVIDER,
    isExternal: false,
  },
  {
    id: '2-contact',
    name: 'common.drawerMenu.contact',
    action: null,
    url: CONTACT_FORM_URL,
    isExternal: true,
  },
];

const legalMenuItems: DrawerMenuItems = [
  {
    id: '2-privacyPolicy',
    name: 'common.drawerMenu.privacyPolicy',
    url: RouteNames.PRIVACY,
    action: null,
    isExternal: true,
  },
  {
    id: '3-termsOfUse',
    name: 'common.drawerMenu.termsOfUse',
    url: RouteNames.TERMS,
    action: null,
    isExternal: true,
  },
];

const socialsMenuItems: SocialsMenuItems = [
  {
    id: '4-instagram',
    icon: InstagramLogo,
    name: 'common.socials.instagram',
    url: 'https://instagram.com/parentup_inc',
  },
  {
    id: '5-linkedIn',
    icon: LinkedInLogo,
    name: 'common.socials.linkedIn',
    url: 'https://www.linkedin.com/company/parentup-inc/',
  },
];

export const socialsConfig: SocialsMenu = {
  headline: 'common.drawerMenu.followUs',
  menuItems: socialsMenuItems,
};

export const menuConfig: DrawerMenuConfig = [
  {
    headline: 'common.drawerMenu.support',
    menuItems: supportMenuItems,
  },
  {
    headline: 'common.drawerMenu.legal',
    menuItems: legalMenuItems,
  },
];

export const getCurrentYear = () => new Date().getFullYear();

export const transitionStyles: TransitionStyles = {
  entering: { transform: 'translateX(0)' },
  entered: { transform: 'translateX(0)' },
  exiting: { transform: 'translateX(-100%)' },
  exited: { transform: 'translateX(-100%)' },
  unmounted: undefined,
};
