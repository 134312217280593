export default {
  common: {
    appName: 'ParentUp',
    header: {
      contactUs: 'Contact Us',
      adminPanel: 'Admin Panel',
      aboutUs: 'About Us',
      forProviders: 'For Providers',
      signUp: 'Sign Up',
      login: 'Log In',
    },
    dashboardHeader: {
      name: 'Hello {{name}}!',
      fallbackName: 'Hello!',
      description: 'Parenting just got easier.',
      findProviders: 'Find friend-approved providers here.',
      searchPlaceholder: 'Search for Providers, Specialties, Activities, or Parents',
      searchPlaceholderNoUser: 'Search for Providers, Specialties, Activities',
      chooseNeighborhoods: 'Choose Neighborhoods',
    },
    fields: {
      noResults: 'No results',
      minSearchLength: 'Type at least {{length}} characters to start searching',
      custom: 'custom field',
    },
    filter: 'Filter',
    clearAll: 'Clear All',
    separateMessage: 'Use a comma to separate',
    cropper: {
      compressing: 'Compressing',
    },
    profilePhoto: {
      edit: 'Edit Provider Photo',
    },
    menu: {
      viewProfile: 'Parent Profile',
      viewProviderProfile: 'Provider Profile',
      editNeighborhoods: 'Edit my Neighborhoods',
      myFriends: 'My Friends',
      myGroups: 'My Groups',
      mySavedProviders: 'My saved Providers',
      myEndorsements: 'My Endorsements',
      becomeProvider: 'Become a provider',
      settings: 'Settings',
      copyLink: 'Share profile link',
      logout: 'Log out',
      logoutMessage: 'You have been successfully logged out',
    },
    readMore: {
      more: 'more...',
      less: 'less...',
    },
    session: {
      expired: 'Your session has expired. Please log in again',
    },
    userCard: {
      mutualCount: '({{count}} mutual)',
      mutualFriend: 'Mutual friend',
      mutualFriends: 'Mutual friends',
      virtual: 'Virtual',
      endorsement: 'Endorsement',
      endorsements: 'Endorsements',
      endorsedBy: 'Endorsed by',
      friend: 'friend',
      friends: 'friends',
      noFriendEndorsements: 'No friend endorsements',
      notLoggedInEndorsements: 'Login to view friend endorsements',
      other: 'other',
      others: 'others',
      decline: 'Decline',
      accept: 'Accept',
    },
    locationSearchPanel: {
      title: 'Location',
      description: 'Changing location below will not impact your default location',
      noNeighborhoods: {
        title: 'We are not in your neighborhood yet',
        description1: 'We may not have enough providers in your area...',
        description2:
          'But you can help us build out the network by creating your profile, connecting with friends, and endorsing providers in your area.',
      },
      emptyNeighborhoods:
        'Search for neighborhoods below to add them. This information will help us show you providers that are local.',
      search: {
        label: 'Add more neighborhoods (NYC only)',
        placeholder: 'Neighborhood or Borough',
      },
    },
    dates: {
      day: 'day',
      days: 'days',
      week: 'week',
      weeks: 'weeks',
      month: 'month',
      months: 'months',
      year: 'year',
      years: 'years',
      old: 'old',
      expecting: 'Expecting',
    },
    connectors: {
      and: 'and',
    },
    cancel: 'Cancel',
    bio: {
      title: 'Bio',
      empty: 'Tell your friends a little about yourself',
    },
    loaders: {
      profile: 'Loading profile...',
      content: 'Loading in progress...',
      fullName: 'Full Name',
    },
    copy: {
      linkCopiedMessage: 'Link has been copied',
      copyLinkError: 'Could not copy connect link',
    },
    responses: {
      invalid: {
        refreshProfile: 'Could not refresh profile data',
      },
    },
    image: {
      title: 'Edit your photo',
      description: 'Move the circle to determine where to crop',
      buttons: {
        save: 'Save',
        cancel: 'Cancel',
      },
    },
    userList: {
      tabs: {
        friends: 'Friends',
        requests: 'Requests',
      },
      empty: {
        friends: 'Get the most out of ParentUp Provider Search by connecting with friends.',
        endorsements: 'Let parents know which providers you would recommend to them.',
      },
    },
    popularCategories: {
      childbirth: 'Childbirth',
      newbornCare: 'Newborn Care',
      healthcare: 'Healthcare',
      birthdays: 'Birthdays',
      specialNeeds: 'Special Needs',
    },
    globalSearch: {
      headings: {
        popularCategories: 'Popular Categories',
        recentSearches: 'Recent Searches',
        parents: 'Parents',
        providers: 'Providers',
      },
      types: {
        category: 'Category',
        providerType: 'Provider Type',
      },
      seeAll: {
        parents: 'See all Parents',
        providers: 'See all Providers',
      },
      noResults: 'No results with',
    },
    errorScreen: {
      subtitle: 'Oops, something went wrong',
      button: 'Go Back',
    },
    drawerMenu: {
      support: 'Support',
      about: 'About ParentUp',
      forProvider: 'For Providers',
      contact: 'Contact us',
      legal: 'Legal',
      privacyPolicy: 'Privacy Policy',
      termsOfUse: 'Terms of Use',
      followUs: 'Follow us',
    },
    socials: {
      instagram: 'Instagram',
      linkedIn: 'LinkedIn',
    },
    footer: {
      copyright: 'Copyright',
    },
    imageCompression: {
      error: 'Failed to compress an image - provided file is not an image',
    },
    noMatches: 'No matches',
  },
};
