import axios from 'axios';

export const API_URL = `${process.env.API_BASE_URL}api/v1/`;

const axiosInstance = axios.create({
  baseURL: API_URL,
});

export const API_URL_NEW = `${process.env.API_BASE_URL}api/`;

const axiosInstanceNew = axios.create({
  baseURL: API_URL_NEW,
});

export { axiosInstanceNew };

export default axiosInstance;
