import { FC } from 'react';

import Icon from '@components/Icon';

import { BackgroundAssetProps } from './BackgroundAsset.types';
import { TEST_ID } from './BackgroundAsset.constants';
import * as Styles from './BackgroundAsset.styles';

const BackgroundAsset: FC<BackgroundAssetProps> = ({
  id,
  asset,
  coordinates,
  degRotate = 0,
  scale = 1,
  stroke,
  fill,
  shadow,
}) => {
  return (
    <Styles.Asset
      data-component-locator={TEST_ID}
      key={id}
      $degRotate={degRotate}
      scale={scale}
      $shadow={shadow}
      left={coordinates.left}
      top={coordinates.top}
      right={coordinates.right}
      bottom={coordinates.bottom}
    >
      <Icon icon={asset} stroke={stroke} fill={fill} />
    </Styles.Asset>
  );
};

export default BackgroundAsset;
