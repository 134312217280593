import { ReactElement, useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { AuthContext } from '@context/Auth/Auth.context';
import FriendService from '@services/Friend/Friend.service';
import { EndorsementsListItems } from '@components/UserList/UserList.types';
import UserList from '@components/UserList';
import useNotification from '@hooks/useNotification';

import { FriendsListProps } from '@components/FriendsList/FriendsList.types';
import { DEFAULT_SIZE } from '@components/FriendsList/FriendsList.constants';
import UserService from '@services/User/User.service';
import useCloseOnRouteChange from '@hooks/useCloseOnRouteChange';
import { DEFAULT_REQUEST_SIZE } from '@constants/api';

const EndorsementsList = ({
  closeHandler,
  isModalOpen,
  title,
  isParentProfile = false,
}: FriendsListProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const { addResponseErrorNotification } = useNotification();
  const { user, shouldRefreshEndorsements, refreshEndorsements } = useContext(AuthContext);
  const { id: parentId } = useParams();
  const [endorsements, setEndorsements] = useState<EndorsementsListItems>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const isInitialLoad = endorsements.length === 0 && currentPage === 0;

  const freshLoadMyEndorsements = () => {
    setIsLoading(true);

    UserService.getMyEndorsements({
      page: isInitialLoad || !hasMore ? 0 : currentPage,
      size: DEFAULT_SIZE,
      sort: 'asc',
    })
      .then(({ data }: any) => {
        if (data) {
          setTotalCount(data.totalElements);
          setEndorsements(data.content);
        }

        setCurrentPage(1);
        setIsLoading(false);
      })
      .catch(({ errorMessage, status }) => {
        addResponseErrorNotification('pages.parent.responses.invalid.getEndorsements', errorMessage, status);
      });
  };

  const loadMyEndorsements = () => {
    setIsLoading(true);

    UserService.getMyEndorsements({
      page: isInitialLoad || !hasMore ? 0 : currentPage,
      size: DEFAULT_SIZE,
      sort: 'asc',
    })
      .then(({ data }) => {
        if (data) {
          setEndorsements((oldEndorsements: any) => {
            setTotalCount(data.totalElements);
            setHasMore(!data.last);

            if (isInitialLoad) {
              return data.content;
            }

            return [...oldEndorsements, ...data.content];
          });
        }

        setCurrentPage((previousState) => (isInitialLoad ? 1 : previousState + 1));
        setIsLoading(false);
      })
      .catch(({ errorMessage, status }) => {
        addResponseErrorNotification('pages.parent.responses.invalid.getEndorsements', errorMessage, status);
      });
  };

  const loadEndorsements = () => {
    if (!parentId || !user.id) {
      return;
    }

    setIsLoading(true);

    FriendService.getFriendEndorsements({
      id: user.id,
      friendId: parentId,
      page: isInitialLoad || !hasMore ? 0 : currentPage,
      size: DEFAULT_SIZE,
    })
      .then(({ data }) => {
        if (data) {
          setEndorsements((oldEndorsements: any) => {
            setTotalCount(data.totalElements);
            setHasMore(!data.last);

            if (isInitialLoad) {
              return data.content;
            }

            return [...oldEndorsements, ...data.content];
          });
        }

        setCurrentPage((previousState) => (isInitialLoad ? 1 : previousState + 1));
        setIsLoading(false);
      })
      .catch(({ errorMessage, status }) => {
        addResponseErrorNotification('pages.parent.responses.invalid.getFriendEndorsements', errorMessage, status);
      });
  };

  useEffect(() => {
    if (!isParentProfile) {
      loadMyEndorsements();
    }
  }, []);

  useEffect(() => {
    if (shouldRefreshEndorsements) {
      freshLoadMyEndorsements();
      refreshEndorsements(false);
    }
  }, [shouldRefreshEndorsements]);

  useEffect(() => {
    if (isParentProfile) {
      loadEndorsements();
    }
  }, [isParentProfile]);

  useCloseOnRouteChange(closeHandler);

  return (
    <UserList
      title={title || 'pages.myEndorsements.title'}
      variant="endorsements"
      items={endorsements}
      isModalOpen={isModalOpen}
      closeHandler={closeHandler}
      handleEndorse={() => {}}
      isLoading={isLoading}
      totalCount={totalCount}
      loadEndorsements={isParentProfile ? loadEndorsements : loadMyEndorsements}
      hasMore={hasMore}
      size={DEFAULT_REQUEST_SIZE}
      hasActionCard={!isParentProfile}
    />
  );
};

export default EndorsementsList;
