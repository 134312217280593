import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Favicon from 'react-favicon';

import faviconConfig from '@config/favicon';
import { ROOT_ELEMENT_ID } from '@constants/app';
import ReactGA from 'react-ga4';

import App from './App';
import './global.css';

import '@config/i18n';

ReactGA.initialize('G-W0E6TFMMND');

ReactDOM.render(
  <>
    <Favicon {...faviconConfig} />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>,
  document.getElementById(ROOT_ELEMENT_ID),
);
