export const USER_LIST_MAX_WIDTH = 544;

const component = 'user-list';
export const WRAPPER_TEST_ID = `${component}-wrapper`;
export const FRIENDS_CONTENT_WRAPPER = `${component}-friends-wrapper`;
export const NO_FRIENDS_CONTENT_WRAPPER = `${component}-no-friends-wrapper`;
export const ENDORSEMENTS_CONTENT_WRAPPER = `${component}-endorsements-wrapper`;
export const NO_ENDORSEMENTS_CONTENT_WRAPPER = `${component}-no-endorsements-wrapper`;
export const DESKTOP_WRAPPER = `${component}-desktop-wrapper`;
export const MOBILE_WRAPPER = `${component}-mobile-wrapper`;
