import theme from '@theme';

import { Variant } from './ActionCard.types';

export const getVariantOptions = (variant: Variant) => {
  const { colors } = theme;

  const variants = {
    orange: {
      background: colors.lightOrange,
      iconBackground: colors.orange,
    },
    blue: {
      background: colors.blueSubtle,
      iconBackground: colors.blue,
    },
  };

  return variants[variant] || variants.orange;
};
