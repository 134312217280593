import i18n from '@config/i18n';
import ProviderAvatar from '@assets/images/provider-avatar.jpeg';
import { Parents, Providers, Results } from '@components/SearchBar/Global/GlobalSearchBar.types';
import { Options } from '@components/Dropdown/Dropdown.types';

import { UserAuthContextProps } from '@context/Auth/Auth.types';
import { RequestProviderFilterSpecification, ResponseFilterProps } from '@services/services.types';
import { SelectedFilterProps } from '@services/Search/Search.types';
import { MATCH_CHARACTER_AFTER_WHITESPACE, MATCH_UPPERCASE_LETTER } from '@constants/regex';
import { NEIGHBORHOODS_FILTER, QUERY_FILTER } from '@pages/Search/Search.constants';

i18n.init();
const { t } = i18n;

export const staticFilters = [
  {
    filterType: 'Place',
    filters: [{ value: 'Virtual' }, { value: 'In Person' }],
  },
  {
    filterType: 'Endorsed By',
    filters: [{ value: 'Friends' }, { value: 'Parents in your area' }],
  },
] as Array<ResponseFilterProps>;

export const mockedFilters = [
  ...staticFilters,
  { filterType: 'Provider Types', filters: [] },
  { filterType: 'Additional Services', filters: [] },
  { filterType: 'Specialties', filters: [] },
  { filterType: 'Languages Spoken', filters: [] },
] as Array<ResponseFilterProps>;

const booleanPlaceKeys = ['virtual', 'inPerson'];
const booleanEndorseByKeys = ['friends', 'parentsInYourArea'];
export const booleanKeys = { place: booleanPlaceKeys, endorsedBy: booleanEndorseByKeys };
export const arrayKeys = ['providerTypes', 'additionalServices', 'specialties', 'languagesSpoken'];
export const keys = [...arrayKeys, ...booleanPlaceKeys, ...booleanEndorseByKeys];

export const mockedParents: Parents = [
  {
    id: 'julia-newman-1',
    avatar: '',
    firstName: 'Julia',
    lastName: 'Newman',
    neighborhoods: [
      {
        neighborhoodId: -2,
        neighborhoodName: 'Fort Greene',
      },
      {
        neighborhoodId: -3,
        neighborhoodName: 'Clinton Hill',
      },
    ],
  },
  {
    id: 'paul-newman-1',
    avatar: '',
    firstName: 'Paul',
    lastName: 'Newman',
    neighborhoods: [
      {
        neighborhoodId: -2,
        neighborhoodName: 'Fort Greene',
      },
      {
        neighborhoodId: -3,
        neighborhoodName: 'Clinton Hill',
      },
    ],
  },
  {
    id: 'stephen-newman-1',
    avatar: '',
    firstName: 'Stephen',
    lastName: 'Newman',
    neighborhoods: [
      {
        neighborhoodId: -2,
        neighborhoodName: 'Fort Greene',
      },
      {
        neighborhoodId: -3,
        neighborhoodName: 'Clinton Hill',
      },
    ],
  },
];

export const mockedProviders: Providers = [
  {
    id: 'provider-1',
    imageUrl: ProviderAvatar,
    name: 'Ayelet Newkaznelson',
    specialties: ['Lactation Consultant'],
  },
  {
    id: 'provider-2',
    imageUrl: ProviderAvatar,
    name: 'Lin Hsiang Chen',
    specialties: ['Postpartum Meal Delivery Service', 'Newborn Care'],
  },
  {
    id: 'provider-3',
    imageUrl: ProviderAvatar,
    name: 'Patricia McGuire',
    specialties: ['Lactation Consultant', 'Newborn Care'],
  },
  {
    id: 'provider-4',
    imageUrl: ProviderAvatar,
    name: 'Stephen Cage',
    specialties: ['Yoga', 'Newborn Care'],
  },
];

export const mockedSearchResults: Results = [
  {
    id: 'newborn-care-1',
    type: t('common.globalSearch.types.category'),
    value: 'Newborn Care',
  },
  {
    id: 'newborn-care-specialist-1',
    type: t('common.globalSearch.types.providerType'),
    value: 'Newborn Care Specialist',
  },
];

export const sortOptions: Options = [
  t('pages.search.sortOptions.friends'),
  t('pages.search.sortOptions.endorsements'),
].map((value, index) => ({
  id: `opt-${index + 1}`,
  value,
}));

export const sortOptionsNoUser: Options = [t('pages.search.sortOptions.endorsements')].map((value, index) => ({
  id: `opt-${index + 1}`,
  value,
}));

export const toNormalCase = (value: string) => {
  return value.replace(MATCH_UPPERCASE_LETTER, ' $1').replace(/^./, (character) => {
    return character.toUpperCase();
  });
};

export const toCamelCase = (value: string) => {
  return value.toLowerCase().replace(MATCH_CHARACTER_AFTER_WHITESPACE, (_, character) => character.toUpperCase());
};

export const getNeighborhoods = (user: UserAuthContextProps) => {
  const { profile } = user;

  if (!profile?.neighborhoods) {
    return [];
  }

  return profile.neighborhoods;
};

export const generateSearchParams = (
  params: Partial<RequestProviderFilterSpecification>,
  prevSearchParams?: URLSearchParams,
) => {
  const searchParams = new URLSearchParams(prevSearchParams || undefined);

  if (params === undefined) {
    return searchParams;
  }

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value) && value.length > 0 && !searchParams.has(key)) {
      searchParams.append(key, value.join(','));
      return;
    }

    if (Array.isArray(value) && searchParams.has(key)) {
      if (value.length > 0) {
        searchParams.set(key, value.join(','));
      } else {
        searchParams.delete(key);
      }

      return;
    }

    if (searchParams.has(key)) {
      if (value.toString() === '') {
        searchParams.delete(key);
        return;
      }

      if (!value) {
        searchParams.delete(key);
        return;
      }

      searchParams.set(key, value.toString());
      return;
    }

    if ((Array.isArray(value) && value.length === 0) || value.toString() === '' || !value) {
      return;
    }

    searchParams.append(key, value.toString());
  });

  return searchParams;
};

export const isFilterSelected = (searchParams: URLSearchParams, filterKey: string, name: string) => {
  if (searchParams.has(filterKey)) {
    return searchParams.get(filterKey)?.split(',').includes(name) ?? false;
  }

  if (searchParams.has(toCamelCase(name))) {
    return true;
  }

  return false;
};

export const isAnyFilterSelected = (searchParams: URLSearchParams) => {
  let isSelected = false;

  searchParams.forEach((value, key) => {
    if (keys.includes(key)) {
      isSelected = true;
    }
  });

  return isSelected;
};

export const parseSearchParam = (searchParams: URLSearchParams, filterKey: string, name: string) => {
  const params = {} as Partial<RequestProviderFilterSpecification>;

  const arrayKey = filterKey as keyof Partial<RequestProviderFilterSpecification>;
  const isArrayKey = arrayKeys.includes(filterKey) && searchParams.has(filterKey);
  const currentArrayValue = searchParams.get(filterKey)?.split(',') || [];

  if (isArrayKey && currentArrayValue.includes(name)) {
    (params[arrayKey] as Array<string>) = currentArrayValue.filter((value) => value !== name);
    return params;
  }

  if (isArrayKey && !currentArrayValue.includes(name)) {
    (params[arrayKey] as Array<string>) = [name, ...currentArrayValue];
    return params;
  }

  if (arrayKeys.includes(filterKey)) {
    (params[arrayKey] as Array<string>) = [name];
    return params;
  }

  const booleanKey = toCamelCase(name) as keyof Partial<RequestProviderFilterSpecification>;

  if (searchParams.has(booleanKey)) {
    (params[booleanKey] as boolean) = false;
    return params;
  }

  (params[booleanKey] as boolean) = true;
  return params;
};

export const clearFilterParams = (searchParams: URLSearchParams) => {
  const clearedSearchParams = searchParams;

  keys.forEach((key) => {
    if (clearedSearchParams.has(key)) {
      clearedSearchParams.delete(key);
    }
  });

  return clearedSearchParams;
};

export const getSelectedFilters = (searchParams: URLSearchParams) => {
  const selectedFilters = [] as Array<SelectedFilterProps>;

  searchParams.forEach((value, key) => {
    if (key === QUERY_FILTER || key === NEIGHBORHOODS_FILTER) {
      return;
    }

    if (arrayKeys.includes(key)) {
      value.split(',').forEach((filterName) => {
        selectedFilters.push({
          filterKey: key,
          filterName,
        });
      });

      return;
    }

    selectedFilters.push({ filterKey: key, filterName: value });
  });

  return selectedFilters;
};

export const removeFilter = (searchParams: URLSearchParams, filterProps: SelectedFilterProps) => {
  const { filterKey, filterName } = filterProps;
  const updatedSearchParams = searchParams;

  if (!arrayKeys.includes(filterKey)) {
    updatedSearchParams.delete(filterKey);
    return updatedSearchParams;
  }

  const arrayValue = updatedSearchParams.get(filterKey);
  const updatedArrayValue = arrayValue?.split(',').filter((filter) => filter !== filterName) || [];

  if (updatedArrayValue.length === 0) {
    updatedSearchParams.delete(filterKey);
    return updatedSearchParams;
  }

  updatedSearchParams.set(filterKey, updatedArrayValue.join(','));

  return updatedSearchParams;
};
