import { styled, css } from '@mui/material/styles';

import { HEADER_HEIGHT } from '@components/Headers/Header/Header.constants';

import { DRAWER_DESKTOP_MAX_WIDTH } from './DrawerMenu.constants';

const resetMenuStyles = css`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const additionalPadding = css`
  padding: ${({ theme }) => `0 ${theme.spacing(5)}`};
`;

const underlineHoverEffect = css`
  ::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid transparent;
  }

  :hover::before {
    border-color: ${({ theme }) => theme.colors.black};
  }
`;

const underlineHoverLinkEffect = css`
  display: inline-block;
  text-decoration: none;
  position: relative;

  ${underlineHoverEffect};
`;

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.pink};
  padding: ${({ theme }) => `0 ${theme.spacing(9)}`};
  box-shadow: ${({ theme }) => theme.customShadows.drawer};
  transition: transform 0.35s cubic-bezier(0, 0, 0.2, 1);

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    max-width: ${DRAWER_DESKTOP_MAX_WIDTH}px;
    transition-duration: 0.4s;
  }
`;

export const Header = styled('header')`
  display: flex;
  align-items: center;
  height: ${HEADER_HEIGHT}px;
`;

export const IconWrapper = styled('div')`
  ${additionalPadding};
  display: flex;
  height: ${HEADER_HEIGHT}px;
  margin-right: ${({ theme }) => theme.spacing(5)};
  cursor: pointer;
`;

export const LogoWrapper = styled('div')`
  display: flex;
  align-items: center;
  height: ${HEADER_HEIGHT}px;
  cursor: pointer;
`;

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 2;
  margin: ${({ theme }) => `${theme.spacing(38)} 0 0`};
  ${additionalPadding};

  ${({ theme }) => theme.breakpoints.up('laptop')} {
    margin: ${({ theme }) => `${theme.spacing(49)} 0 ${theme.spacing(115)}`};
    flex: unset;
  }
`;

export const HeadlineWrapper = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(7)};

  span {
    text-transform: uppercase;
    letter-spacing: 0.04em;
  }
`;

export const MenuSection = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(17)};
`;

export const Menu = styled('menu')`
  ${resetMenuStyles};
`;

export const SocialsMenu = styled('ul')`
  display: flex;
  align-items: center;
  ${resetMenuStyles};
`;

export const MenuItem = styled('li')`
  line-height: 0;
  cursor: pointer;

  > p {
    display: inline-block;
    position: relative;

    ${underlineHoverEffect};
  }

  a {
    ${underlineHoverLinkEffect};
  }

  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(8)};
  }
`;

export const SocialsMenuItem = styled('li')`
  display: flex;

  > div {
    margin-right: ${({ theme }) => theme.spacing(5)};
  }

  :not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(12)};
  }
`;

export const ExternalLink = styled('a')`
  ${underlineHoverLinkEffect};
`;

export const Footer = styled('footer')`
  ${additionalPadding};
  padding-bottom: ${({ theme }) => theme.spacing(20)};

  > div {
    margin-bottom: 0;
  }
`;

export const Copyright = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(12)};
  line-height: 0;

  span {
    margin-right: 0.5ch;
  }
`;
