import { useEffect, useState } from 'react';

import SearchService from '@services/Search/Search.service';
import {
  ResponseContentAdditionalServiceProps,
  RecentSearchesProps,
  ResponseContentCategoryProps,
  ResponseContentParentProps,
  ResponseContentProviderProps,
  ResponseContentProviderTypeProps,
  ResponseContentSpecialtyProps,
} from '@services/Search/Search.types';
import CategoryService from '@services/Provider/Category/Category.service';

import useNotification from './useNotification';

interface SearchHookProps {
  isUserLoggedIn: boolean;
  searchQuery?: string;
}

const useSearch = ({ isUserLoggedIn, searchQuery = '' }: SearchHookProps) => {
  const { addResponseErrorNotification } = useNotification();

  const [isLoading, setIsLoading] = useState(false);

  const [popularCategories, setPopularCategories] = useState<Array<ResponseContentCategoryProps>>([]);
  const [recentSearches, setRecentSearches] = useState<Array<RecentSearchesProps>>([]);

  const [providersSearchResults, setProvidersSearchResults] = useState<Array<ResponseContentProviderProps>>([]);
  const [isProvidersRequestLoading, setIsProvidersRequestLoading] = useState(false);

  const [parentsSearchResults, setParentsSearchResults] = useState<Array<ResponseContentParentProps>>([]);
  const [isParentsRequestLoading, setIsParentsRequestLoading] = useState(false);

  const [categorySearchResults, setCategorySearchResults] = useState<Array<ResponseContentCategoryProps>>([]);
  const [isCategoryRequestLoading, setIsCategoryRequestLoading] = useState(false);

  const [providerTypeSearchResults, setProviderTypeSearchResults] = useState<Array<ResponseContentProviderTypeProps>>(
    [],
  );
  const [isProviderTypeRequestLoading, setIsProviderTypeRequestLoading] = useState(false);

  const [specialtySearchResults, setSpecialtySearchResults] = useState<Array<ResponseContentSpecialtyProps>>([]);
  const [isSpecialtyRequestLoading, setIsSpecialtyRequestLoading] = useState(false);

  const [additionalServiceSearchResults, setAdditionalServiceSearchResults] = useState<
    Array<ResponseContentAdditionalServiceProps>
  >([]);
  const [isAdditionalServiceRequestLoading, setIsAdditionalServiceRequestLoading] = useState(false);
  const getRecentSearches = () => {
    SearchService.getRecentSearches()
      .then(({ data }) => {
        const content = data?.content || [];
        setRecentSearches(content);
      })
      .catch(({ errorMessage, status }) => {
        addResponseErrorNotification('pages.search.responses.invalid.recentSearches', errorMessage, status);
      });
  };

  const getPopularCategories = () => {
    CategoryService.getPopularCategories()
      .then(({ data }) => {
        const content = data?.content || [];
        setPopularCategories(content);
      })
      .catch(({ errorMessage, status }) => {
        addResponseErrorNotification('pages.search.responses.invalid.categoryList', errorMessage, status);
      });
  };

  const getPopularCategoriesNoUser = () => {
    CategoryService.getCategoriesNoUser()
      .then(({ data }) => {
        const content = data?.content || [];
        setPopularCategories(content);
      })
      .catch(({ errorMessage, status }) => {
        addResponseErrorNotification('pages.search.responses.invalid.categoryList', errorMessage, status);
      });
  };

  const searchProviders = () => {
    setIsProvidersRequestLoading(true);
    SearchService.getProviders({ searchQuery })
      .then(({ data }) => {
        const content = data?.content || [];
        setProvidersSearchResults(content);
      })
      .catch(({ errorMessage, status }) => {
        addResponseErrorNotification('pages.search.responses.invalid.providers', errorMessage, status);
      })
      .finally(() => {
        setIsProvidersRequestLoading(false);
      });
  };

  const searchProvidersNoUser = () => {
    setIsProvidersRequestLoading(true);
    SearchService.getProvidersNoUser({ searchQuery })
      .then(({ data }) => {
        const content = data?.content || [];
        setProvidersSearchResults(content);
      })
      .catch(({ errorMessage, status }) => {
        addResponseErrorNotification('pages.search.responses.invalid.providers', errorMessage, status);
      })
      .finally(() => {
        setIsProvidersRequestLoading(false);
      });
  };

  const searchParents = () => {
    setIsParentsRequestLoading(true);
    SearchService.getParents({ searchQuery, role: 'parent' })
      .then(({ data }) => {
        const content = data?.content || [];
        setParentsSearchResults(content);
      })
      .catch(({ errorMessage, status }) => {
        addResponseErrorNotification('pages.search.responses.invalid.parents', errorMessage, status);
      })
      .finally(() => {
        setIsParentsRequestLoading(false);
      });
  };

  const searchParentsNoUser = () => {
    setIsParentsRequestLoading(true);
    SearchService.getParentsNopUser({ searchQuery })
      .then(({ data }) => {
        const content = data?.content || [];
        setParentsSearchResults(content);
      })
      .catch(({ errorMessage, status }) => {
        addResponseErrorNotification('pages.search.responses.invalid.parents', errorMessage, status);
      })
      .finally(() => {
        setIsParentsRequestLoading(false);
      });
  };

  const searchCategory = () => {
    setIsCategoryRequestLoading(true);
    SearchService.getCategories({ searchQuery })
      .then(({ data }) => {
        const content = data?.content || [];
        setCategorySearchResults(content);
      })
      .catch(({ errorMessage, status }) => {
        addResponseErrorNotification('pages.search.responses.invalid.category', errorMessage, status);
      })
      .finally(() => {
        setIsCategoryRequestLoading(false);
      });
  };
  const searchCategoryNoUser = () => {
    setIsCategoryRequestLoading(true);
    SearchService.getCategoriesNoUser({ searchQuery })
      .then(({ data }) => {
        const content = data?.content || [];
        setCategorySearchResults(content);
      })
      .catch(({ errorMessage, status }) => {
        addResponseErrorNotification('pages.search.responses.invalid.category', errorMessage, status);
      })
      .finally(() => {
        setIsCategoryRequestLoading(false);
      });
  };

  const searchProviderType = () => {
    setIsProviderTypeRequestLoading(true);
    SearchService.getProviderTypes({ searchQuery })
      .then(({ data }) => {
        const content = data?.content || [];
        setProviderTypeSearchResults(content);
      })
      .catch(({ errorMessage, status }) => {
        addResponseErrorNotification('pages.search.responses.invalid.providerType', errorMessage, status);
      })
      .finally(() => {
        setIsProviderTypeRequestLoading(false);
      });
  };

  const searchProviderTypeNoUser = () => {
    setIsProviderTypeRequestLoading(true);
    SearchService.getProviderTypesNoUser({ searchQuery })
      .then(({ data }) => {
        const content = data?.content || [];
        setProviderTypeSearchResults(content);
      })
      .catch(({ errorMessage, status }) => {
        addResponseErrorNotification('pages.search.responses.invalid.providerType', errorMessage, status);
      })
      .finally(() => {
        setIsProviderTypeRequestLoading(false);
      });
  };

  const searchSpecialty = () => {
    setIsSpecialtyRequestLoading(true);
    SearchService.getSpecialties({ searchQuery })
      .then(({ data }) => {
        const content = data?.content || [];
        setSpecialtySearchResults(content);
      })
      .catch(({ errorMessage, status }) => {
        addResponseErrorNotification('pages.search.responses.invalid.specialty', errorMessage, status);
      })
      .finally(() => {
        setIsSpecialtyRequestLoading(false);
      });
  };

  const searchSpecialtyNoUser = () => {
    setIsSpecialtyRequestLoading(true);
    SearchService.getSpecialtiesNoUser({ searchQuery })
      .then(({ data }) => {
        const content = data?.content || [];
        setSpecialtySearchResults(content);
      })
      .catch(({ errorMessage, status }) => {
        addResponseErrorNotification('pages.search.responses.invalid.specialty', errorMessage, status);
      })
      .finally(() => {
        setIsSpecialtyRequestLoading(false);
      });
  };

  const searchAdditionalService = () => {
    setIsAdditionalServiceRequestLoading(true);
    SearchService.getAdditionalServices({ searchQuery })
      .then(({ data }) => {
        const content = data?.content || [];
        setAdditionalServiceSearchResults(content);
      })
      .catch(({ errorMessage, status }) => {
        addResponseErrorNotification('pages.search.responses.invalid.additionalService', errorMessage, status);
      })
      .finally(() => {
        setIsAdditionalServiceRequestLoading(false);
      });
  };

  const searchAdditionalServiceNoUser = () => {
    setIsAdditionalServiceRequestLoading(true);
    SearchService.getAdditionalServicesNoUser({ searchQuery })
      .then(({ data }) => {
        const content = data?.content || [];
        setAdditionalServiceSearchResults(content);
      })
      .catch(({ errorMessage, status }) => {
        addResponseErrorNotification('pages.search.responses.invalid.additionalService', errorMessage, status);
      })
      .finally(() => {
        setIsAdditionalServiceRequestLoading(false);
      });
  };

  const validateQuery = () => {
    if (searchQuery.length < 3) {
      setProvidersSearchResults([]);
      setParentsSearchResults([]);
      setCategorySearchResults([]);
      setProviderTypeSearchResults([]);
      setSpecialtySearchResults([]);
      setAdditionalServiceSearchResults([]);
      return;
    }

    setIsLoading(true);
    searchProviders();
    searchParents();
    searchCategory();
    searchProviderType();
    searchSpecialty();
    searchAdditionalService();
  };

  const validateQueryNoUser = () => {
    if (searchQuery.length < 3) {
      setProvidersSearchResults([]);
      setParentsSearchResults([]);
      setCategorySearchResults([]);
      setProviderTypeSearchResults([]);
      setSpecialtySearchResults([]);
      setAdditionalServiceSearchResults([]);
      return;
    }

    setIsLoading(true);
    searchProvidersNoUser();
    // searchParentsNoUser();
    searchCategoryNoUser();
    searchProviderType();
    searchSpecialty();
    searchAdditionalService();
  };

  useEffect(() => {
    if (isUserLoggedIn) {
      getPopularCategories();
      getRecentSearches();
    } else {
      getPopularCategories();
    }
  }, []);

  useEffect(() => {
    if (isUserLoggedIn) {
      validateQuery();
    } else {
      validateQueryNoUser();
    }
  }, [searchQuery]);

  useEffect(() => {
    const isFetchingData =
      isCategoryRequestLoading &&
      isParentsRequestLoading &&
      isProvidersRequestLoading &&
      isCategoryRequestLoading &&
      isProviderTypeRequestLoading &&
      isSpecialtyRequestLoading &&
      isAdditionalServiceRequestLoading;

    if (!isFetchingData) {
      setIsLoading(false);
    }
  }, [
    isCategoryRequestLoading,
    isParentsRequestLoading,
    isProvidersRequestLoading,
    isCategoryRequestLoading,
    isProviderTypeRequestLoading,
    isSpecialtyRequestLoading,
    isAdditionalServiceRequestLoading,
  ]);

  return {
    isLoading,
    popularCategories,
    recentSearches,
    providersSearchResults,
    parentsSearchResults,
    categorySearchResults,
    providerTypeSearchResults,
    specialtySearchResults,
    additionalServiceSearchResults,
  };
};

export default useSearch;
