export const shadows = {
  paper: '0 4px 14px rgba(0, 0, 0, 0.25)',
  pill: '0 4px 8px 0 rgba(0, 0, 0, 0.15)',
  menu: '0 4px 32px rgba(0, 0, 0, 0.12)',
  searchBarDataList: '0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.3)',
  profilePictureBlank: '0 4px 32px rgba(0, 0, 0, 0.12)',
  profilePicture: '0 4px 14px rgba(0, 0, 0, 0.25)',
  circleButton: '0 4px 14px rgba(33, 25, 121, 0.16)',
  scrollingSection: '0 1px 7px rgba(134, 134, 134, 0.25)',
  actionCardCircle: '0 0 4px rgba(0, 0, 0, 0.25)',
  providerAssets: '0 4px 4px rgba(0, 0, 0, 0.25)',
  searchPageDivider: '0 0 6px rgba(0, 0, 0, 0.25)',
  drawer: '0 0 7px rgba(0, 0, 0, 0.25)',
};

export type ShadowType = typeof shadows;
