import { AxiosMyEndorsementsProps } from '@services/Provider/Endorsement/Endorsement.types';
import axios, { axiosInstanceNew } from '@utils/api';

import { authConfig } from '../services.utils';
import { NEW_PARENTS_DEFAULT_NUMBER } from './User.constants';
import {
  RegisterProps,
  ResponseRegisterProps,
  DeleteProps,
  ChangePasswordProps,
  ResetPasswordProps,
  ActivateAccountProps,
  ActivationEmailProps,
  ResponseActivateAccountProps,
  ResponseProfileProps,
  UpdateProfileProps,
  AxiosParentsProps,
  RequestMyEndorsementsProps,
} from './User.types';

class User {
  endpoint: string;

  constructor() {
    this.endpoint = 'users';
  }

  register(data: RegisterProps): Promise<ResponseRegisterProps> {
    return axios.post(this.endpoint, data);
  }

  delete(data: DeleteProps): Promise<void> {
    return axios.delete(this.endpoint, { ...data, ...authConfig });
  }

  changePassword(data: ChangePasswordProps): Promise<void> {
    return axios.put(`${this.endpoint}/password`, data);
  }

  becomeProvider(): Promise<void> {
    return axios.post(`${this.endpoint}/spro/become-provider`, {}, authConfig);
  }

  resetPassword(data: ResetPasswordProps): Promise<void> {
    return axios.post(`${this.endpoint}/password`, data);
  }

  activateAccount(data: ActivateAccountProps): Promise<ResponseActivateAccountProps> {
    return axios.put(`${this.endpoint}/activationCode`, data);
  }

  sendActivationEmail(data: ActivationEmailProps): Promise<void> {
    return axios.post(`${this.endpoint}/activationEmail`, data);
  }

  getProfile(id: string): Promise<ResponseProfileProps> {
    return axios.get(`${this.endpoint}/${id}`, authConfig);
  }

  getProfilePublic(id: string): Promise<ResponseProfileProps> {
    return axios.get(`${this.endpoint}/${id}/public`, authConfig);
  }

  getMyProfile(): Promise<ResponseProfileProps> {
    return axios.get(`${this.endpoint}/me`, authConfig);
  }

  getMyEndorsements({ page, size, sort }: RequestMyEndorsementsProps): Promise<AxiosMyEndorsementsProps> {
    return axios.get(`${this.endpoint}/me/endorsements`, {
      params: {
        page,
        size,
        sort: sort ? `provider.name,${sort}` : undefined,
      },
      ...authConfig,
    });
  }

  updateProfile({ id, ...rest }: UpdateProfileProps): Promise<ResponseProfileProps> {
    return axios.patch(`${this.endpoint}/${id}/profile`, rest, authConfig);
  }

  getParents(): Promise<AxiosParentsProps> {
    return axios.get(`${this.endpoint}`, {
      params: {
        size: NEW_PARENTS_DEFAULT_NUMBER,
        sort: `createdAt,desc`,
      },
      ...authConfig,
    });
  }

  getParentsNoUser(): Promise<AxiosParentsProps> {
    return axios.get(`${this.endpoint}/spro/public`, {
      params: {
        size: NEW_PARENTS_DEFAULT_NUMBER,
        sort: `createdAt,desc`,
      },
    });
  }
}

export default new User();
