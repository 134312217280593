export const SEARCH_MIN_LENGTH = 3;
export const SEARCH_MAX_LENGTH = 100;
export const MAX_VISIBLE_PROVIDERS_COUNT = 3;
export const MAX_VISIBLE_PARENTS_COUNT = 2;
export const MAX_RECENT_SEARCHES_COUNT = 3;
export const PARENT_AVATAR_SIZE = 40;
export const PROVIDER_AVATAR_WIDTH = 35;
export const PROVIDER_AVATAR_HEIGHT = 42;

export const TIMEOUT_DURATION = 1000;

export const PROVIDER_TYPE_TYPE = 'Provider Type';
export const CATEGORY_TYPE = 'Category';
export const SPECIALTY_TYPE = 'Specialty';
export const ADDITIONAL_SERVICE_TYPE = 'Additional Service';
