import { ReactElement, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormikValues } from 'formik';

import useBreakpoint from '@hooks/useBreakpoint';
import Typography from '@components/Typography';
import Input from '@components/Inputs/Input';
import Button from '@components/Buttons/Button';
import Icon from '@components/Icon';
import Modal from '@components/Modal';
import * as CommonStyles from '@components/Common/Common.styles';
import * as ParentStyles from '@components/Common/Parent/Parent.styles';
import BackIcon from '@assets/icons/left-arrow.svg';
import AcceptIcon from '@assets/icons/accept.svg';
import * as Routes from '@constants/routes';
import theme from '@theme';

import { formFields, formInitialValues } from './ContactUs.utils';
import { formSchema } from './ContactUs.validation';
import * as Styles from './ContactUs.styles';
import { ContactUsProps } from './ContactUs.types';

const ContactUs = ({ closeHandler }: ContactUsProps): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobileOnly, isMobile, isDesktop } = useBreakpoint();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const handleConfirmModalClose = () => setIsConfirmModalOpen(false);

  const validationSchema = useMemo(() => formSchema(t), []);

  const goHome = () => {
    closeHandler();
    navigate(Routes.HOME);
  };

  const formSubmitHandler = ({ name, email, subject, message }: FormikValues) => {
    setIsSubmitting(true);
    console.log('Make an api call', name, email, subject, message);
    setIsSubmitting(false);
    setIsConfirmModalOpen(true);
  };

  const renderMobileOnlyActionBar = () => (
    <ParentStyles.ActionBar>
      <ParentStyles.BackIconWrapper onClick={closeHandler} $hasEditMode>
        <Icon icon={BackIcon} />
      </ParentStyles.BackIconWrapper>
    </ParentStyles.ActionBar>
  );

  return (
    <>
      <Styles.Container>
        {isMobileOnly && renderMobileOnlyActionBar()}
        <Typography variant="h3" weight="bold" shouldTranslate>
          pages.contactUs.title
        </Typography>
        <Styles.DescriptionWrapper>
          <Typography color={theme.colors.greyDark1} shouldTranslate>
            pages.contactUs.description
          </Typography>
        </Styles.DescriptionWrapper>
        <CommonStyles.FormContainer
          initialValues={formInitialValues}
          onSubmit={formSubmitHandler}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ values, errors, touched, handleChange, handleBlur }) => {
            const isSendButtonDisabled = Object.keys(touched).length === 0 || Object.keys(errors).length > 0;

            return (
              <CommonStyles.Form>
                <Styles.InputsContainer>
                  {formFields.map(({ name, label, type, isRequired, placeholder }) => (
                    <CommonStyles.InputContainer key={name} mb={12}>
                      <Input
                        type={type}
                        value={values[name]}
                        changeHandler={handleChange}
                        blurHandler={handleBlur}
                        hasError={Boolean(errors[name] && touched[name])}
                        assistiveText={errors[name] && touched[name] ? (errors[name] as string) : ''}
                        name={name}
                        label={label}
                        isRequired={isRequired}
                        placeholder={t(placeholder)}
                        shouldTranslate
                      />
                    </CommonStyles.InputContainer>
                  ))}
                </Styles.InputsContainer>
                <Styles.ButtonsContainer>
                  <CommonStyles.ButtonContainer>
                    <Button
                      width={isDesktop ? '252px' : '100%'}
                      type="submit"
                      isDisabled={isSubmitting || isSendButtonDisabled}
                      shouldTranslate
                    >
                      pages.contactUs.buttons.send
                    </Button>
                  </CommonStyles.ButtonContainer>
                  <CommonStyles.ButtonContainer>
                    <Button
                      width={isDesktop ? '252px' : '100%'}
                      type="button"
                      variant={isMobile ? 'text' : 'outlined'}
                      isDisabled={isSubmitting}
                      clickHandler={closeHandler}
                      shouldTranslate
                    >
                      pages.contactUs.buttons.cancel
                    </Button>
                  </CommonStyles.ButtonContainer>
                </Styles.ButtonsContainer>
              </CommonStyles.Form>
            );
          }}
        </CommonStyles.FormContainer>
      </Styles.Container>
      <Modal isOpen={isConfirmModalOpen} closeHandler={handleConfirmModalClose} noPadding>
        <>
          <Styles.ConfirmModalIconWrapper>
            <Styles.IconWrapper>
              <Icon icon={AcceptIcon} fill={theme.colors.white} transform={4.7} />
            </Styles.IconWrapper>
          </Styles.ConfirmModalIconWrapper>
          <Styles.ConfirmModalWrapper>
            <Typography weight="bold" shouldTranslate>
              pages.contactUs.modal.description
            </Typography>
            <Button type="button" shouldTranslate clickHandler={goHome}>
              pages.contactUs.buttons.home
            </Button>
          </Styles.ConfirmModalWrapper>
        </>
      </Modal>
    </>
  );
};

export default ContactUs;
